import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@chakra-ui/react'

import { BxsAreaChart } from '~assets/icons/BxsAreaChart'
import { BxsHorizontalChart } from '~assets/icons/BxsHorizontalChart'
import { BxsLineChart } from '~assets/icons/BxsLineChart'
import { BxsPieChart } from '~assets/icons/BxsPieChart'
import { BxsVerticalChart } from '~assets/icons/BxsVerticalChart'

import { ChartIcon } from './ChartIcon'

export const SummaryChartOptionsTab = ({
  setType,
}: {
  setType: Dispatch<SetStateAction<string>>
}): JSX.Element | null => {
  const { i18n } = useTranslation()
  const [activeTab, setActiveTab] = useState('pie')

  const handleClick = (chartType: SetStateAction<string>) => {
    setActiveTab(chartType)
    setType(chartType)
  }
  return (
    <Stack
      bg="white"
      pos="sticky"
      top={0}
      px="0.5rem"
      py="1rem"
      borderRight="1px solid"
      borderColor="neutral.300"
      direction="column"
      justifyContent="space-between"
      spacing="0.5rem"
    >
      <Stack spacing="0.5rem">
        <ChartIcon
          label={i18n.t<string>(
            'features.adminForm.responses.components.formResultsNavbar.summaryChartSidebar.pieChart',
          )}
          icon={<BxsPieChart fontSize="1.5rem" />}
          onClick={() => handleClick('pie')}
          isActive={activeTab === 'pie'}
        />

        <ChartIcon
          label={i18n.t<string>(
            'features.adminForm.responses.components.formResultsNavbar.summaryChartSidebar.verticalChart',
          )}
          icon={<BxsHorizontalChart fontSize="1.5rem" />}
          onClick={() => handleClick('vbar')}
          isActive={activeTab === 'vbar'}
        />

        <ChartIcon
          label={i18n.t<string>(
            'features.adminForm.responses.components.formResultsNavbar.summaryChartSidebar.horizontalChart',
          )}
          icon={<BxsVerticalChart fontSize="1.5rem" />}
          onClick={() => handleClick('hbar')}
          isActive={activeTab === 'hbar'}
        />

        <ChartIcon
          label={i18n.t<string>(
            'features.adminForm.responses.components.formResultsNavbar.summaryChartSidebar.lineChart',
          )}
          icon={<BxsLineChart fontSize="1.5rem" />}
          onClick={() => handleClick('line')}
          isActive={activeTab === 'line'}
        />

        <ChartIcon
          label={i18n.t<string>(
            'features.adminForm.responses.components.formResultsNavbar.summaryChartSidebar.areaChart',
          )}
          icon={<BxsAreaChart fontSize="1.5rem" />}
          onClick={() => handleClick('area')}
          isActive={activeTab === 'area'}
        />
      </Stack>
    </Stack>
  )
}
