import {
  DateSelectedValidation,
  FormLanguage,
  LogicConditionState,
  RatingShape,
  TextSelectedValidation,
  YesNo,
} from '~shared/types'

import i18n from '~/i18n/i18n'

import { FilterOption } from '~features/workspace/types'

import { ComboboxItem } from '../types'

export const getNumCharLimitTranslation = (item: ComboboxItem): ComboboxItem =>
  ({
    [TextSelectedValidation.Maximum]: i18n.t(
      'components.Dropdown.components.SelectMenu.TextSelectedValidation.maximum',
    ),
    [TextSelectedValidation.Minimum]: i18n.t(
      'components.Dropdown.components.SelectMenu.TextSelectedValidation.minimum',
    ),
    [TextSelectedValidation.Exact]: i18n.t(
      'components.Dropdown.components.SelectMenu.TextSelectedValidation.exact',
    ),
  }[item])

export const getDateValidationTranslation = (
  item: ComboboxItem,
): ComboboxItem =>
  ({
    [DateSelectedValidation.NoPast]: i18n.t(
      'components.Dropdown.components.SelectMenu.DateSelectedValidation.noPast',
    ),
    [DateSelectedValidation.NoFuture]: i18n.t(
      'components.Dropdown.components.SelectMenu.DateSelectedValidation.noFuture',
    ),
    [DateSelectedValidation.Custom]: i18n.t(
      'components.Dropdown.components.SelectMenu.DateSelectedValidation.custom',
    ),
  }[item])

export const getRatingShapeTranslation = (item: ComboboxItem): ComboboxItem =>
  ({
    [RatingShape.Heart]: i18n.t(
      'components.Dropdown.components.SelectMenu.RatingShape.heart',
    ),
    [RatingShape.Star]: i18n.t(
      'components.Dropdown.components.SelectMenu.RatingShape.star',
    ),
  }[item])

export const getYesNoTranslation = (item: ComboboxItem): ComboboxItem =>
  ({
    [YesNo.Yes]: i18n.t('components.Dropdown.components.SelectMenu.YesNo.yes'),
    [YesNo.No]: i18n.t('components.Dropdown.components.SelectMenu.YesNo.no'),
  }[item])

export const getLogicConditionStateTranslation = (
  item: string | null,
): string =>
  ({
    [LogicConditionState.Equal]: i18n.t(
      'shared.types.form.formLogic.LogicConditionState.equal',
    ),
    [LogicConditionState.Lte]: i18n.t(
      'shared.types.form.formLogic.LogicConditionState.lte',
    ),
    [LogicConditionState.Gte]: i18n.t(
      'shared.types.form.formLogic.LogicConditionState.gte',
    ),
    [LogicConditionState.Either]: i18n.t(
      'shared.types.form.formLogic.LogicConditionState.either',
    ),
  }[item])

export const getFormFilterTranslation = (item: string | null): string =>
  ({
    [FilterOption.AllForms]: i18n.t('features.workspace.types.allForms'),
    [FilterOption.OpenForms]: i18n.t('features.workspace.types.openForms'),
    [FilterOption.ClosedForms]: i18n.t('features.workspace.types.closedForms'),
  }[item])

export const getFormLanguageTranslation = (item: ComboboxItem): string =>
  ({
    [FormLanguage.English]: i18n.t(
      'features.adminForm.settings.components.FormLanguageSection.english',
    ),
    [FormLanguage.Khmer]: i18n.t(
      'features.adminForm.settings.components.FormLanguageSection.khmer',
    ),
  }[item])
