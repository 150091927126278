import './time-picker.css'

import type { TimePickerProps as ReactTimePickerProps } from 'react-time-picker'
import { TimePicker as ReactTimePicker } from 'react-time-picker'

export type TimePickerProps = ReactTimePickerProps

export const TimePicker = (props: TimePickerProps): JSX.Element => {
  return (
    <ReactTimePicker
      clearIcon={null}
      clockIcon={null}
      hourPlaceholder={'hh'}
      minutePlaceholder={'mm'}
      {...props}
    />
  )
}
