export const BxsKhmer = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <g clipPath="url(#clip0_362_13188)">
        <g clipPath="url(#clip1_362_13188)">
          <rect width="22" height="16" fill="#F93939" rx="2"></rect>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M8.381 5.333h.524L9.429 6.4H8.38V5.333zM9.429 6.4h1.047v1.067H9.43V6.4zM8.38 7.467h1.048v1.066H8.38V7.467zM7.333 8.533h1.048V9.6H7.333V8.533zm0 1.067h1.048v1.067H7.333V9.6zm-.523 0h.523v1.067H6.286L6.81 9.6zm2.619-1.067h2.095v2.134H9.429V8.533zm4.19-3.2h-.524L12.571 6.4h1.048V5.333zM12.572 6.4h-1.048v1.067h1.048V6.4zm1.047 1.067h-1.047v1.066h1.047V7.467zm1.048 1.066h-1.048V9.6h1.048V8.533zm0 1.067h-1.048v1.067h1.048V9.6zm.524 0h-.524v1.067h1.047L15.19 9.6zm-2.62-1.067h-1.047v2.134h1.048V8.533z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.429 7.467h1.047v1.066H9.43V7.467zM8.38 8.533h1.048v2.134H8.38V8.533zm0-2.133h1.048v1.067H8.38V6.4zm-.524 1.067h.524v1.066H7.333l.524-1.066zm4.715 0h-1.048v1.066h1.048V7.467zm1.047 1.066h-1.047v2.134h1.047V8.533zm0-2.133h-1.047v1.067h1.047V6.4zm.524 1.067h-.524v1.066h1.048l-.524-1.066zM11.523 6.4h-1.047v2.133h1.048V6.4zM10.476 5.333h1.048V6.4h-1.048V5.333z"
            clipRule="evenodd"
            opacity="0.7"
          ></path>
          <path
            fill="#232C80"
            fillRule="evenodd"
            d="M0 0h22v4H0V0zm0 12h22v4H0v-4z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_362_13188">
          <path fill="#fff" d="M0 0H22V16H0z"></path>
        </clipPath>
        <clipPath id="clip1_362_13188">
          <rect width="22" height="16" fill="#fff" rx="2"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
