import { useMemo, useState } from 'react'
import { Box, Stack } from '@chakra-ui/react'

import { BasicField, SummaryResponseBase } from '~shared/types'

import { EmptySummarizableResponses } from './common/EmptySummarizableResponses'
import { BaseChart } from './components/Charts/BaseChart'
import { ChartSection } from './components/ChartSection'
import { useAdminFormSummary } from './queries'
import { SummaryChartOptionsTab } from './SummaryChartOptionsTab'
import { SummaryPageSkeleton } from './SummaryPageSkeleton'

export const UnlockedSummary = (): JSX.Element => {
  const { data: questionSummaries, isLoading } = useAdminFormSummary()

  const filteredQuestionSummaries = questionSummaries?.filter(
    (element, idx, currentQuestionSummaries) => {
      const currentQuestionSummary = element
      const nextQuestionSummary = currentQuestionSummaries[idx + 1]

      // handle fields that are Sections one after another
      if (
        currentQuestionSummary.fieldType === 'section' &&
        nextQuestionSummary &&
        nextQuestionSummary.fieldType === 'section'
      ) {
        return false
      }

      // handle form where its last field is a Section
      if (
        currentQuestionSummary.fieldType === 'section' &&
        !nextQuestionSummary
      ) {
        return false
      }

      return true
    },
  )

  const [chartType, setChartType] = useState<string>('pie')

  const formSummary = useMemo(
    () =>
      filteredQuestionSummaries?.map((questionSummary: SummaryResponseBase) => {
        if (questionSummary) {
          switch (questionSummary.fieldType) {
            case BasicField.Radio:
            case BasicField.Dropdown:
            case BasicField.Checkbox:
            case BasicField.YesNo:
            case BasicField.Rating:
              return (
                <BaseChart
                  key={questionSummary._id}
                  type={chartType}
                  data={questionSummary}
                />
              )
            case BasicField.Section:
              return (
                <ChartSection
                  key={questionSummary._id}
                  data={questionSummary}
                />
              )
            default:
              return null
          }
        }

        return null
      }),
    [chartType, filteredQuestionSummaries],
  )

  if (isLoading) return <SummaryPageSkeleton />

  return (
    <Stack
      h="100%"
      w="100%"
      overflow="auto !important"
      bg="neutral.200"
      direction="row"
    >
      <SummaryChartOptionsTab setType={setChartType} />
      {filteredQuestionSummaries && filteredQuestionSummaries.length !== 0 ? (
        <Box margin={'auto !important'} pb={'50px'}>
          {formSummary}
        </Box>
      ) : (
        <Box margin={'auto !important'} pb={'50px'} py={'50px'}>
          <EmptySummarizableResponses />
        </Box>
      )}
    </Stack>
  )
}
