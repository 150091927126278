import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonGroup,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react'

import Button from '~components/Button'
import { ModalCloseButton } from '~components/Modal'

import { BASICFIELD_TO_DRAWER_META } from '../../constants'
import { useAdminFormLogic } from '../../logic/hooks/useAdminFormLogic'
import { useBuilderAndDesignContext } from '../BuilderAndDesignContext'
import { useDeleteFormField } from '../mutations/useDeleteFormField'
import {
  FieldBuilderState,
  stateDataSelector,
  useFieldBuilderStore,
} from '../useFieldBuilderStore'

export const DeleteFieldModal = (): JSX.Element => {
  const { i18n } = useTranslation()

  const stateData = useFieldBuilderStore(stateDataSelector)

  const {
    deleteFieldModalDisclosure: { onClose },
  } = useBuilderAndDesignContext()
  const { mapIdToField, logicedFieldIdsSet } = useAdminFormLogic()

  const { fieldIsInLogic, fieldIcon, fieldLabel } = useMemo(() => {
    if (stateData.state !== FieldBuilderState.EditingField) return {}
    const questionNumber = mapIdToField?.[stateData.field._id].questionNumber
    const fieldTitle =
      stateData.field.title !== 'Statement'
        ? stateData.field.title
        : i18n.t(
            'features.adminForm.create.builderAndDesign.DeleteFieldModal.statement',
          )

    return {
      fieldIsInLogic: logicedFieldIdsSet?.has(stateData.field._id),
      fieldIcon: BASICFIELD_TO_DRAWER_META[stateData.field.fieldType].icon,
      fieldLabel: questionNumber
        ? `${questionNumber}. ${fieldTitle}`
        : fieldTitle,
    }
  }, [mapIdToField, stateData, logicedFieldIdsSet, i18n])

  const { deleteFieldMutation } = useDeleteFormField()

  const handleDeleteConfirmation = useCallback(() => {
    if (stateData.state === FieldBuilderState.EditingField) {
      deleteFieldMutation.mutate(stateData.field._id, {
        onSuccess: onClose,
      })
    }
  }, [deleteFieldMutation, onClose, stateData])

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {i18n.t<string>(
            'features.adminForm.create.builderAndDesign.DeleteFieldModal.deleteTitle',
          )}
        </ModalHeader>
        <ModalBody>
          <Text color="secondary.500">
            {fieldIsInLogic
              ? i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.DeleteFieldModal.useInLogic',
                )
              : i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.DeleteFieldModal.deletePrompt',
                )}
          </Text>
          <UnorderedList
            spacing="0.5rem"
            listStyleType="none"
            ml="1.75rem"
            mt="1rem"
          >
            <ListItem
              display="flex"
              alignItems="flex-start"
              wordBreak="break-word"
            >
              <Icon
                as={fieldIcon}
                fontSize="1.25rem"
                h="1.5rem"
                ml="-1.75rem"
                mr="0.5rem"
              />
              {fieldLabel}
            </ListItem>
          </UnorderedList>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              {i18n.t<string>(
                'features.adminForm.create.builderAndDesign.DeleteFieldModal.cancel',
              )}
            </Button>
            <Button
              colorScheme="danger"
              onClick={handleDeleteConfirmation}
              isLoading={deleteFieldMutation.isLoading}
            >
              {i18n.t<string>(
                'features.adminForm.create.builderAndDesign.DeleteFieldModal.deleteField',
              )}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
