import { useMemo } from 'react'

import { FormColorTheme, FormStartPage } from '~shared/types'

import { ThemeColorScheme } from '~theme/foundations/colours'

import {
  publicI18nSelector,
  usePublicLanguageStore,
} from '~features/public-form/usePublicLanguageStore'

interface UseFormHeaderProps {
  startPage?: FormStartPage
  hover?: boolean
  title?: string
}

export const getTitleBg = (colorTheme?: FormColorTheme, hover?: boolean) =>
  colorTheme ? `theme-${colorTheme}.${hover ? 6 : 5}00` : `neutral.200`

export const useFormHeader = ({
  startPage,
  hover,
  title,
}: UseFormHeaderProps) => {
  const publicI18n = usePublicLanguageStore(
    useMemo(() => publicI18nSelector, []),
  )

  const titleColor = useMemo(() => {
    if (startPage?.colorTheme === FormColorTheme.Orange) {
      return 'secondary.700'
    }
    return 'white'
  }, [startPage?.colorTheme])

  const titleBg = useMemo(
    () => getTitleBg(startPage?.colorTheme, hover),
    [hover, startPage?.colorTheme],
  )
  const estTimeString = useMemo(() => {
    if (!startPage?.estTimeTaken) return ''
    return publicI18n(
      'features.publicForm.components.FormStartPage.estimatedTimeToComplete',
      {
        estTimeTake: startPage.estTimeTaken,
      },
    )
  }, [startPage, publicI18n])

  const colorScheme: ThemeColorScheme | undefined = useMemo(() => {
    if (!startPage?.colorTheme) return
    return `theme-${startPage.colorTheme}` as const
  }, [startPage?.colorTheme])

  return {
    titleColor,
    titleBg,
    estTimeString,
    colorScheme,
  }
}
