import { Step } from 'react-joyride'

interface FeatureTourStepAttributes {
  id: string
  title: string
  content: string
}

export const FEATURE_TOUR: FeatureTourStepAttributes[] = [
  {
    id: 'feature_tour_first_step',
    title: 'features.adminForm.create.featureTour.constants.addFields.title',
    content:
      'features.adminForm.create.featureTour.constants.addFields.content',
  },
  {
    id: 'feature_tour_second_step',
    title:
      'features.adminForm.create.featureTour.constants.editHeaderAndInstructions.title',
    content:
      'features.adminForm.create.featureTour.constants.editHeaderAndInstructions.content',
  },
  {
    id: 'feature_tour_third_step',
    title: 'features.adminForm.create.featureTour.constants.addLogic.title',
    content: 'features.adminForm.create.featureTour.constants.addLogic.content',
  },
  {
    id: 'feature_tour_fourth_step',
    title:
      'features.adminForm.create.featureTour.constants.editThankYouPage.title',
    content:
      'features.adminForm.create.featureTour.constants.editThankYouPage.content',
  },
]

export const FEATURE_STEPS: Step[] = FEATURE_TOUR.map(
  ({ id, title, content }) => {
    return {
      target: `#${id}`,
      title: title,
      content: content,
      disableBeacon: true,
    }
  },
)
