import { useMemo } from 'react'
import { Text } from '@chakra-ui/layout'

import FormLabel from '~components/FormControl/FormLabel'

import {
  publicI18nSelector,
  usePublicLanguageStore,
} from '../../../features/public-form/usePublicLanguageStore'

interface ColumnHeaderProps {
  title: string
  isRequired: boolean
  id: string
}

export const ColumnHeader = ({
  title,
  isRequired,
  id,
}: ColumnHeaderProps): JSX.Element => {
  const publicI18n = usePublicLanguageStore(
    useMemo(() => publicI18nSelector, []),
  )

  return (
    <>
      <Text as="label" id={id}>
        {title}
      </Text>
      <FormLabel.OptionalIndicator
        isRequired={isRequired}
        title={title}
        optionalLabel={publicI18n('components.FormControl.FormLabel.optional')}
      />
    </>
  )
}
