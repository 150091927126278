import { useTranslation } from 'react-i18next'
import { Divider, Flex, Text } from '@chakra-ui/react'

export const EditConditionBlockDivider = (): JSX.Element => {
  const { i18n } = useTranslation()

  return (
    <Flex w="100%" my="2rem">
      <Divider
        alignSelf="center"
        ml={{ base: '-1.5rem', md: '-2rem' }}
        pr={{ base: '1.5rem', md: '2rem' }}
      />
      <Text p="0.625rem" textStyle="subhead-3" color="secondary.500">
        {i18n.t<string>(
          'features.adminForm.create.logic.components.logicContent.EditLogicBlock.EditCondition.EditionConditionBlockDivider.and',
        )}
      </Text>
      <Divider
        alignSelf="center"
        mr={{ base: '-1.5rem', md: '-2rem' }}
        pl={{ base: '1.5rem', md: '2rem' }}
      />
    </Flex>
  )
}
