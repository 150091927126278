export const BxsVerticalChart = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#014F99"
        d="M2.625 2.55c0-.33.114-.607.342-.834.228-.227.507-.341.837-.341.33 0 .608.114.833.34.225.228.338.506.338.835v18.9c0 .327-.114.609-.342.845a1.118 1.118 0 01-.837.355c-.33 0-.609-.118-.834-.355a1.184 1.184 0 01-.337-.845V2.55zM6.85 4.038c0-.468.163-.863.489-1.183a1.63 1.63 0 011.186-.48h3.425c.472 0 .874.162 1.204.487.33.324.496.718.496 1.182 0 .464-.165.86-.496 1.188-.33.329-.732.493-1.204.493H8.525a1.61 1.61 0 01-1.186-.492 1.632 1.632 0 01-.489-1.195zm0 5.284c0-.466.163-.861.489-1.186a1.619 1.619 0 011.186-.486h8.425c.472 0 .874.163 1.204.49.33.326.496.722.496 1.188 0 .466-.165.861-.496 1.186-.33.324-.732.486-1.204.486H8.525c-.465 0-.86-.163-1.186-.49a1.62 1.62 0 01-.489-1.188zm0 5.3c0-.466.163-.861.489-1.185a1.619 1.619 0 011.186-.487h5.425c.472 0 .874.164 1.204.492.33.328.496.726.496 1.195 0 .468-.165.867-.496 1.195-.33.329-.732.493-1.204.493H8.525c-.465 0-.86-.166-1.186-.497a1.656 1.656 0 01-.489-1.206zm0 5.325c0-.466.163-.861.489-1.185a1.619 1.619 0 011.186-.487H19.95c.472 0 .874.164 1.204.492.33.328.496.726.496 1.195 0 .468-.165.867-.496 1.195-.33.329-.732.493-1.204.493H8.525c-.465 0-.86-.166-1.186-.497a1.656 1.656 0 01-.489-1.206z"
      ></path>
    </svg>
  )
}
