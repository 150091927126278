export enum FormLogoState {
  Default = 'DEFAULT',
  None = 'NONE',
  CustomLogo = 'CUSTOM LOGO',
  CustomLogoBanner = 'CUSTOM LOGO BANNER',
}

export interface FormLogoBase {
  state: FormLogoState
}

export interface CustomFormLogo extends FormLogoBase {
  state: FormLogoState.CustomLogo
  fileId: string
  fileName: string
  fileSizeInBytes: number
}

export interface CustomFormLogoBanner
  extends FormLogoBase,
    Pick<CustomFormLogo, 'fileId' | 'fileName' | 'fileSizeInBytes'> {
  state: FormLogoState.CustomLogoBanner
}

export interface NoFormLogo extends FormLogoBase {
  state: FormLogoState.None
}

export interface DefaultFormLogo extends FormLogoBase {
  state: FormLogoState.Default
}

export type FormLogo =
  | CustomFormLogo
  | CustomFormLogoBanner
  | NoFormLogo
  | DefaultFormLogo
