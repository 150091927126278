export const BxsEnglish = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <g clipPath="url(#clip0_362_13171)">
        <g clipPath="url(#clip1_362_13171)">
          <rect width="22" height="16" fill="#1A47B8" rx="2"></rect>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M2.34 0H0v2.667L19.647 16H22v-2.667L2.34 0z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#F93939"
            d="M.78 0L22 14.438V16h-.762L0 1.547V0h.78z"
          ></path>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M19.905 0H22v2.667S8.391 11.55 2.095 16H0v-2.667L19.905 0z"
            clipRule="evenodd"
          ></path>
          <path fill="#F93939" d="M22 0h-.71L0 14.45V16h.78L22 1.559V0z"></path>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M8 0h6.018v4.935H22v6.127h-7.982V16H8v-4.938H0V4.935h8V0z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M9.263 0h3.474v6.154H22v3.692h-9.263V16H9.263V9.846H0V6.154h9.263V0z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_362_13171">
          <path fill="#fff" d="M0 0H22V16H0z"></path>
        </clipPath>
        <clipPath id="clip1_362_13171">
          <rect width="22" height="16" fill="#fff" rx="2"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
