export const getVariationOfAOrAn = function (
  value: string,
  capitalize: boolean,
) {
  const letters = ['a', 'e', 'i', 'o', 'u', 'h']
  const firstLetter = value.substring(0, 1).toLowerCase()
  let correctWordForm = ''
  if (
    letters.find(function (l) {
      return firstLetter === l
    })
  ) {
    correctWordForm = capitalize ? 'An' : 'an'
  } else {
    correctWordForm = capitalize ? 'A' : 'a'
  }

  return `${correctWordForm} ${value}`
}
