// TODO #4279: Remove after React rollout is complete
import { useTranslation } from 'react-i18next'
import { BiMessage } from 'react-icons/bi'
import { Flex, Portal, useDisclosure } from '@chakra-ui/react'

import i18n from '~/i18n/i18n'

import { noPrintCss } from '~utils/noPrintCss'
import IconButton from '~components/IconButton'
import Tooltip from '~components/Tooltip'

import { AdminFeedbackModal } from './AdminFeedbackModal'

export const AdminFeedbackIcon = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { i18n } = useTranslation()
  return (
    <Portal>
      <Flex
        position="fixed"
        bottom="2.75rem"
        right="2.75rem"
        cursor="pointer"
        sx={noPrintCss}
      >
        <AdminFeedbackModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Portal>
  )
}
