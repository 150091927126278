import { useTranslation } from 'react-i18next'

import { FormResponseMode } from '~shared/types/form'

import { useToast } from '~hooks/useToast'

import { useAdminForm } from '~features/admin-form/common/queries'

import { EmailResponsesTab } from './email'
import { ResponsesPageSkeleton } from './ResponsesPageSkeleton'
import { StorageResponsesTab } from './storage'

export const ResponsesPage = (): JSX.Element => {
  const { data: form, isLoading } = useAdminForm()
  const i18n = useTranslation()
  const toast = useToast({ status: 'danger' })

  if (isLoading) return <ResponsesPageSkeleton />

  if (!form) {
    toast({
      description: i18n.t<string>('responsePage.errorRetrieve'),
    })
    return <ResponsesPageSkeleton />
  }

  if (form.responseMode === FormResponseMode.Encrypt) {
    return <StorageResponsesTab />
  }

  return <EmailResponsesTab />
}
