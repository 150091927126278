const myInfoNationalities = [
  'AFGHAN',
  'ALBANIAN',
  'ALGERIAN',
  'AMERICAN',
  'ANDORRAN',
  'ANGOLAN',
  'ANTIGUAN',
  'ARGENTINIAN',
  'ARMENIAN',
  'AUSTRALIAN',
  'AUSTRIAN',
  'AZERBAIJANI',
  'BAHAMIAN',
  'BAHRAINI',
  'BANGLADESHI',
  'BARBADOS',
  'BELARUSSIAN',
  'BELGIAN',
  'BELIZEAN',
  'BENINESE',
  'BHUTANESE',
  'BOLIVIAN',
  'BOSNIAN',
  'BOTSWANA',
  'BRAZILIAN',
  'BRITISH',
  'BRITISH NATIONAL OVERSEAS',
  'BRITISH OVERSEAS CITIZEN',
  'BRITISH OVERSEAS TERRITORIES CITIZEN',
  'BRITISH PROTECTED PERSON',
  'BRITISH SUBJECT',
  'BRUNEIAN',
  'BULGARIAN',
  'BURKINABE',
  'BURUNDIAN',
  'CAMBODIAN',
  'CAMEROONIAN',
  'CANADIAN',
  'CAPE VERDEAN',
  'CENTRAL AFRICAN REPUBLIC',
  'CHADIAN',
  'CHILEAN',
  'CHINESE',
  'COLOMBIAN',
  'COMORAN',
  'CONGOLESE',
  'COSTA RICAN',
  'CROATIAN',
  'CUBAN',
  'CYPRIOT',
  'CZECH',
  'DANISH',
  'DEMOCRATIC REPUBLIC OF THE CONGO',
  'DJIBOUTIAN',
  'DOMINICAN',
  'DOMINICAN (REPUBLIC)',
  'EAST TIMORESE',
  'ECUADORIAN',
  'EGYPTIAN',
  'EQUATORIAL GUINEA',
  'ERITREAN',
  'ESTONIAN',
  'ETHIOPIAN',
  'FIJIAN',
  'FILIPINO',
  'FINNISH',
  'FRENCH',
  'GABON',
  'GAMBIAN',
  'GEORGIAN',
  'GERMAN',
  'GHANAIAN',
  'GREEK',
  'GRENADIAN',
  'GUATEMALAN',
  'GUINEAN',
  'GUINEAN (BISSAU)',
  'GUYANESE',
  'HAITIAN',
  'HONDURAN',
  'HONG KONG',
  'HUNGARIAN',
  'ICELANDER',
  'INDIAN',
  'INDONESIAN',
  'IRANIAN',
  'IRAQI',
  'IRISH',
  'ISRAELI',
  'ITALIAN',
  'IVORY COAST',
  'JAMAICAN',
  'JAPANESE',
  'JORDANIAN',
  'KAZAKHSTANI',
  'KENYAN',
  'KIRIBATI',
  'KITTIAN & NEVISIAN',
  'KOREAN, NORTH',
  'KOREAN, SOUTH',
  'KUWAITI',
  'KYRGYZSTAN',
  'LAOTIAN',
  'LATVIAN',
  'LEBANESE',
  'LESOTHO',
  'LIBERIAN',
  'LIBYAN',
  'LIECHTENSTEINER',
  'LITHUANIAN',
  'LUXEMBOURGER',
  'MACAO',
  'MACEDONIAN',
  'MADAGASY',
  'MALAWIAN',
  'MALAYSIAN',
  'MALDIVIAN',
  'MALIAN',
  'MALTESE',
  'MARSHALLESE',
  'MAURITANEAN',
  'MAURITIAN',
  'MEXICAN',
  'MICRONESIAN',
  'MOLDAVIAN',
  'MONACAN',
  'MONGOLIAN',
  'MONTENEGRIN',
  'MOROCCAN',
  'MOZAMBICAN',
  'MYANMAR',
  'NAMIBIAN',
  'NAURUAN',
  'NEPALESE',
  'NETHERLANDS',
  'NEW ZEALANDER',
  'NI-VANUATU',
  'NICARAGUAN',
  'NIGER',
  'NIGERIAN',
  'NORWEGIAN',
  'OMANI',
  'PAKISTANI',
  'PALAUAN',
  'PALESTINIAN',
  'PANAMANIAN',
  'PAPUA NEW GUINEAN',
  'PARAGUAYAN',
  'PERUVIAN',
  'POLISH',
  'PORTUGUESE',
  'QATARI',
  'REFUGEE (OTHER THAN XXB)',
  'REFUGEE (XXB)',
  'ROMANIAN',
  'RUSSIAN',
  'RWANDAN',
  'SALVADORAN',
  'SAMMARINESE',
  'SAMOAN',
  'SAO TOMEAN',
  'SAUDI ARABIAN',
  'SENEGALESE',
  'SERBIAN',
  'SEYCHELLOIS',
  'SIERRA LEONE',
  'SINGAPORE CITIZEN',
  'SLOVAK',
  'SLOVENIAN',
  'SOLOMON ISLANDER',
  'SOMALI',
  'SOUTH AFRICAN',
  'SPANISH',
  'SRI LANKAN',
  'ST. LUCIA',
  'ST. VINCENTIAN',
  'STATELESS',
  'SUDANESE',
  'SURINAMER',
  'SWAZI',
  'SWEDISH',
  'SWISS',
  'SYRIAN',
  'TAIWANESE',
  'TAJIKISTANI',
  'TANZANIAN',
  'THAI',
  'TOGOLESE',
  'TONGAN',
  'TRINIDADIAN & TOBAGONIAN',
  'TUNISIAN',
  'TURK',
  'TURKMEN',
  'TUVALU',
  'UGANDAN',
  'UKRAINIAN',
  'UNITED ARAB EMIRATES',
  'UNKNOWN',
  'URUGUAYAN',
  'UZBEKISTAN',
  'VATICAN CITY STATE (HOLY SEE)',
  'VENEZUELAN',
  'VIETNAMESE',
  'YEMENI',
  'ZAMBIAN',
  'ZIMBABWEAN',
]

export default myInfoNationalities
