import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'

import { EmptySummarizableResponsesSvgr } from './EmptySummarizableResponsesSvgr'

export const EmptySummarizableResponses = (): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <Flex
      justify="center"
      flexDir="column"
      align="center"
      px="2rem"
      py="4rem"
      pt={'0px'}
    >
      <Text as="h2" textStyle="h2" color="primary.500" mb="1rem">
        {i18n.t<string>(
          'features.adminForm.responses.SummaryPage.common.EmptySummarizableResponses.yourFormDoesntHaveAnyFieldThatCanBeSummarized',
        )}
      </Text>
      <Text textStyle="body-1" color="secondary.500">
        {i18n.t<string>(
          'features.adminForm.responses.SummaryPage.common.EmptySummarizableResponses.tryAddingNewFieldsThatCanBeSummarized',
        )}{' '}
        {i18n.t<string>(
          'features.adminForm.responses.SummaryPage.common.EmptySummarizableResponses.summarizableFields',
        )}
      </Text>
      <EmptySummarizableResponsesSvgr mt="1.5rem" w="280px" maxW="100%" />
    </Flex>
  )
}
