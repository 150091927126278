export const SPECIAL_FORM_IDS = ['641d18f584a3c30046680e24']

export const FORM_FIELD_MAPPING = {
  name: {
    globalId: 'YGP9C66vdWg5dB7rqFeqHnIcH5BrIk1rV2FWN8cDALC',
    value: 'គោត្តនាម-នាម',
  },
  name_latin: {
    globalId: 'ioXxiqBxFa73dCW3vhlBONqIJgLgRhVssPAxafTTgy5',
    value: 'គោត្តនាម-នាម (ជាអក្សរឡាតាំង)',
  },
  gender: {
    globalId: 'nnqDUUhoSlOp3Rapymbc1pjTeO2QObkGt1aB5AtT7L5',
    value: 'ភេទ',
  },
  dob: {
    globalId: '4zqisUDCPJhZsRUaO7DgJ7EoUsQzJr0uK7KXQv4ooqe',
    value: 'ថ្ងៃខែឆ្នាំកំណើត',
  },
  ethnicity: {
    globalId: 'cBZafmZjB5iix07LtiLV78bIJbGdOGUh0CwqodWQzYc',
    value: 'ជនជាតិ',
  },
  nationality: {
    globalId: 'bRX0T2uzAIHvV5PbbzPiOvIm2FM8tuS1CApn1E3fcwb',
    value: 'សញ្ជាតិ',
  },
  id_card_passport: {
    globalId: 'Q9oPBMlCYEcs7pgHNw9dus457cSCNt1g2JUAKCNH1gE',
    value: 'លេខអត្តសញ្ញាណបណ្ណ ឬ លេខលិខិតឆ្លងដែន',
  },
  phone: {
    globalId: 'Ph9aSWakMRt4D9Jipor2CnJe7D1Dy8yhLUWssWmulDy',
    value: 'លេខទូរសព្ទ',
  },
  email: {
    globalId: 'GwmUyHddOUuM5QxU62JfczwJXIXwQc4rw14mtWVxBGx',
    value: 'អ៉ីមែល',
  },
  apply_as: {
    globalId: '5x11YmpAN402GfpWkoX8IduNmnR2u1KzyZhIum0lirk',
    value: 'បេក្ខជនស្នើសុំប្រឡងក្នុងនាមជា',
  },
  gov_employee_id: {
    globalId: 'kM8VhsMLJtF5KjquO41TfWRUgLQbaBAGChKYRx9gmDo',
    value: 'អត្តលេខមន្ត្រី',
  },
  current_status: {
    globalId: '4UuFSpqofBOK2H6aup3834DnjhqQJwcZ07mS9xeLNLf',
    value: 'ស្ថានភាព',
  },
  birth_village: {
    globalId: 'aJdjgCQlT8YDe2tOYGHdqZ6c9J2s4aPzxD6cTiBnGiF',
    value: 'ភូមិ',
  },
  birth_commune: {
    globalId: 'PrkQpIC3xDhHXBfixITlnbqto1XnFXUiRt9s7XdOsPB',
    value: 'ឃុំ/សង្កាត់',
  },
  birth_district: {
    globalId: '7KjFe9swOTBMNgHJbOefWnVYThZ1xBXxsA2MpypNKyL',
    value: 'ក្រុង/ស្រុក/ខណ្ឌ',
  },
  birth_province: {
    globalId: 'xRT6yJJ2KlJw9DaQUUd8WAqreFFseF6ET2gCKz4gVzp',
    value: 'រាជធានី/ខេត្ត',
  },
  current_house_no: {
    globalId: 'igj4jtVjoJnniQV1oOH6vkguYQBNsL18GL7ILdViXYq',
    value: 'ផ្ទះលេខ',
  },
  current_street: {
    globalId: 'QU0N2H032ehLT4h3DUo7fEgcfARrErx4K8GPpmI18yr',
    value: 'ផ្លូវ',
  },
  current_village: {
    globalId: 'DFkLcydSkJTNvtIXudZFb0xNdFuHIKDAZBz491W88OE',
    value: 'ភូមិ',
  },
  current_commune: {
    globalId: 'H7IePZ3E8mkC5hkmaKPCFY5BK0WBNlFIpPLuECWCSBi',
    value: 'ឃុំ/សង្កាត់',
  },
  current_district: {
    globalId: 'vuSwFmCCtCQjrAcPlXD6TOPXVliNdtXPkTxB5K5G6Fk',
    value: 'ក្រុង/ស្រុក/ខណ្ឌ',
  },
  current_province: {
    globalId: '7LI3sdxT9qLXC74mu32CcOvWWCE7CnBznZOJITy10BO',
    value: 'រាជធានី/ខេត្ត',
  },
  married_status: {
    globalId: 'xVzzi7kzkswkn2kBtmQtwKTFOiDcxmnHxvaLgmq0kt3',
    value: 'ស្ថានភាពគ្រួសារ',
  },
  father_name: {
    globalId: 'GfnOp5LmgUdqRWcXlPLN265Oem86LoQJAijs2J2Niyz',
    value: 'ឈ្មោះឪពុក',
  },
  father_age: {
    globalId: 'VB5TMPxRGZzSfa0jd8MKBLv4at8N5C1gavqkiscnAS8',
    value: 'អាយុឪពុក',
  },
  father_nationality: {
    globalId: '352JNwuPk2LUonHbNMyYhwG1SFCZaXjbTLBecedIHoD',
    value: 'ជនជាតិឪពុក',
  },
  father_status: {
    globalId: '1M40FMBXhFdQKYgeOFSuxTEoMdGOsO7sJnOk840agdm',
    value: 'ស្ថានភាពឪពុក',
  },
  father_occupation: {
    globalId: '4I1UaTT6lsfc4DRXju3YVmdBfMMxemrE6YgoMRnZI3J',
    value: 'មុខរបរឪពុក',
  },
  mother_name: {
    globalId: 'ogubzoGZywVs9LxZRBmgOHlDOmm9KI5oNIQfWrUeXYQ',
    value: 'ឈ្មោះម្តាយ',
  },
  mother_age: {
    globalId: 'IArYJkX0bNHUz0f6ZPqFIGjJtUdcAwXHaNg3NHBILCO',
    value: 'អាយុម្ដាយ',
  },
  mother_nationality: {
    globalId: 'N65nAKdn8YBK3BfJ5vwP8JvFLKcN6APina9jrhTRC8g',
    value: 'ជនជាតិម្ដាយ',
  },
  mother_status: {
    globalId: '1EpJ1NeZyoMTahDkotjA0BvYsmCcRp3H88dJviuM3ds',
    value: 'ស្ថានភាពម្ដាយ',
  },
  mother_occupation: {
    globalId: 'EOlaq8TP0D5xiz3ZjUVPvIVAddIWOX9QeNqvIRyluTO',
    value: 'មុខរបរម្ដាយ',
  },
  patner_name: {
    globalId: 'GtqM5UmhY1WhRst8Z5zmGaY7CdbNudnJGz7LrnCYkbg',
    value: 'ឈ្មោះប្ដី ឬប្រពន្ធ',
  },
  patner_status: {
    globalId: 'g77vJlG3oE67p8vLI35hLwkxjNL9PXsa7NXjew0QktX',
    value: 'ស្ថានភាពប្ដី ឬប្រពន្ធ',
  },
  patner_dob: {
    globalId: 'foPSPnDV3MG5jbWdShxUvJP5wbxEEk5n0voEjFs3Lsk',
    value: 'ថ្ងៃខែឆ្នាំកំណើតប្ដី ឬប្រពន្ធ',
  },
  patner_occupation: {
    globalId: 'IgYUBcOqUR6uXu51gVDTYyLjH73dDav03ZP3Ajn6Zr4',
    value: 'មុខរបរប្ដី ឬប្រពន្ធ',
  },
  children: {
    globalId: 'cfH87WGbtyHJpmGf0DxTwJrDWu6u7oCQiY3yIqV32Th',
    value: 'ចំនួនកូន',
  },
  languages: {
    globalId: 'mpFHi9Ix9Atp818zTTk6DMvU0C7HBWnD4PK0vRTTGsu',
    value: 'កម្រិតភាសាបរទេស',
  },
  educations: {
    globalId: 'uAO1TtC9UUHNiu67PuAU9vEWedBDJHAImkARXMqkBCB',
    value: 'កម្រិតវប្បធម៌',
  },
  work_histories: {
    globalId: 'LinV6Mh7dmJo1Mo178iJpCObj6FRx6UvZd8DZK7r9bb',
    value: 'ប្រវត្តិការងារ',
  },
  exam_position: {
    globalId: 'Cen1rDZbyXerKocXyYWfOjceaNeL3xqqquQfpZRYc15',
    value: 'ប្រលងចូលក្របខណ្ឌ ក ជំនាញ',
  },
  profile_photo_file: {
    globalId: 'FrOFRrrtpOxJGbvoWWTjYmssRF6vP4d9UWj4FT2Txu6',
    value: 'រូបថតថ្មី (៤X៦)',
  },
  approval_letter_file: {
    globalId: 'XLUlJCYNE3qtJiA5hbuszZOokgSoJkSRhxbytmFjYTz',
    value: 'លិខិតអនុញ្ញាតពីប្រធានស្ថាប័ន',
  },
  degree_certificate_file: {
    globalId: 'FL9ttIqKECHDx6p1axrTBpOisVLNg3DrIO1I6eyM2qM',
    value: 'សញ្ញាបត្រជំនាញ',
  },
  dob_certificate_file: {
    globalId: 'rd6gSTJD42keKcdDNkglHRvnP2SLUHKfJlIHCNflMif',
    value: 'សេចក្តីចម្លងសំបុត្របញ្ជាក់កំណើត',
  },
  id_card_passport_file: {
    globalId: 'om5wexWLToeQEXJ21hdy8GxygZ4RySl1XhcY3S7KG8S',
    value: 'អត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬលិខិតឆ្លងដែន',
  },
  family_book_file: {
    globalId: 'wmV3fRB5lPcxqgA0jQqVCmSWUAk2ZDnqVOffVivi6Su',
    value: 'សៀវភៅគ្រួសារ ឬសៀវភៅស្នាក់នៅ',
  },
}
