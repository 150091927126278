import React from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import {
  Box,
  chakra,
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  useDisclosure,
  VisuallyHidden,
} from '@chakra-ui/react'

import { BxsBank } from '~assets/icons/BxsBank'
import { BxsLockAlt } from '~assets/icons/BxsLockAlt'
import { useIsMobile } from '~hooks/useIsMobile'
import Link from '~components/Link'

export interface GovtMastheadProps {
  defaultIsOpen?: boolean
}

interface GovtMastheadChildrenProps {
  isOpen: boolean
  isMobile: boolean
  onToggle: () => void
  children: React.ReactNode
}

interface HeaderBarProps extends GovtMastheadChildrenProps {
  /**
   * ID of the expandable section for accessibility.
   */
  ariaControlId: string
}

const HeaderBar = ({
  isMobile,
  children,
  onToggle,
  isOpen,
  ariaControlId,
}: HeaderBarProps): JSX.Element => {
  const styleProps = {
    bg: 'neutral.200',
    py: { base: '0.5rem', md: '0.375rem' },
    px: { base: '1.5rem', md: '1.75rem', lg: '2rem' },
    textStyle: { base: 'legal', md: 'caption-2' },
    display: 'flex',
    width: '100%',
  }

  // Mobile
  if (isMobile) {
    return (
      <chakra.button
        aria-controls={ariaControlId}
        aria-describedby="masthead-aria"
        aria-expanded={isOpen}
        _focus={{
          boxShadow: '0 0 0 2px inset var(--chakra-colors-primary-500)',
        }}
        {...styleProps}
        onClick={onToggle}
      >
        <VisuallyHidden id="masthead-aria">
          {isOpen
            ? 'Collapse masthead'
            : 'Expand masthead to find out how to identify an official government website'}
        </VisuallyHidden>
        {children}
      </chakra.button>
    )
  }

  // Non-mobile
  return <Flex {...styleProps}>{children}</Flex>
}

const HowToIdentify = ({
  isOpen,
  isMobile,
  children,
  onToggle,
}: GovtMastheadChildrenProps): JSX.Element => {
  // Mobile
  if (isMobile) {
    return (
      <Text
        aria-hidden
        as="span"
        color="primary.500"
        textDecorationLine="underline"
        display="flex"
        alignItems="center"
      >
        How to identify {children}
      </Text>
    )
  }

  // Non-mobile
  return (
    <Link
      as="button"
      tabIndex={0}
      aria-label={
        isOpen
          ? 'Collapse masthead'
          : 'Expand masthead to find out how to identify an official government website'
      }
      onClick={onToggle}
    >
      How to identify {children}
    </Link>
  )
}

export const GovtMasthead = ({
  defaultIsOpen,
}: GovtMastheadProps): JSX.Element => {
  const { onToggle, isOpen } = useDisclosure({ defaultIsOpen })
  const isMobile = useIsMobile()

  const ariaControlId = 'govt-masthead-expandable'

  return <Box></Box>
}
