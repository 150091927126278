import { useCallback, useMemo } from 'react'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormControl, Skeleton, Stack } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import isEmail from 'validator/lib/isEmail'

import { FormPermission } from '~shared/types'

import { useIsMobile } from '~hooks/useIsMobile'
import Button from '~components/Button'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'

import { useMutateCollaborators } from '~features/admin-form/common/mutations'

import { useAdminFormCollaborators } from '../../../queries'
import { useCollaboratorWizard } from '../CollaboratorWizardContext'
import { DropdownRole } from '../constants'
import { roleToPermission } from '../utils'

import { PermissionDropdown } from './PermissionDropdown'

export type AddCollaboratorInputs = {
  email: string
  role: DropdownRole
}

const useAddCollaboratorInput = () => {
  const { handleForwardToTransferOwnership, formId } = useCollaboratorWizard()
  const { isLoading, collaborators, form, isFormAdmin } =
    useAdminFormCollaborators(formId)
  const { mutateAddCollaborator } = useMutateCollaborators()

  const formMethods = useForm<AddCollaboratorInputs>({
    defaultValues: {
      email: '',
      role: DropdownRole.Editor,
    },
  })

  const { watch, handleSubmit, reset } = formMethods

  const roleValue = watch('role')

  const handleAddCollaborator = useCallback(
    (inputs: AddCollaboratorInputs) => {
      if (!form?.permissionList) return

      const newPermission: FormPermission = {
        ...roleToPermission(inputs.role),
        email: inputs.email,
      }
      return mutateAddCollaborator.mutate(
        {
          newPermission,
          currentPermissions: form.permissionList,
        },
        {
          onSuccess: () => reset(),
        },
      )
    },
    [form?.permissionList, mutateAddCollaborator, reset],
  )

  const handleInputSubmission = handleSubmit((inputs) => {
    // Handle transfer form ownership instead of granting admin rights.
    if (inputs.role === DropdownRole.Owner) {
      return handleForwardToTransferOwnership(inputs.email)
    }

    return handleAddCollaborator(inputs)
  })
  const i18n = useTranslation()
  const validationRules: RegisterOptions = useMemo(() => {
    return {
      required: i18n.t<string>(
        'features.adminForm.common.components.collaboratorModal.collabEmailRequired',
      ),
      validate: {
        validEmail: (value: string) =>
          !value ||
          isEmail(value) ||
          i18n.t<string>(
            'features.adminForm.common.components.collaboratorModal.validEmail',
          ),
        duplicateEmail: (value: string) =>
          !value ||
          !collaborators?.find(
            (c) => c.email.toLowerCase() === value.toLowerCase(),
          ) ||
          i18n.t<string>(
            'features.adminForm.common.components.collaboratorModal.existingCollab',
          ),
        ownerEmail: (value: string) =>
          !value ||
          form?.admin.email?.toLowerCase() !== value.toLowerCase() ||
          i18n.t<string>(
            'features.adminForm.common.components.collaboratorModal.cannotAddOwner',
          ),
      },
    }
  }, [collaborators, form?.admin.email, i18n])

  const isMobile = useIsMobile()

  const isTransferOwnershipSelected = useMemo(
    () => roleValue === DropdownRole.Owner,
    [roleValue],
  )

  return {
    isQueryLoading: isLoading,
    isMutationLoading: mutateAddCollaborator.isLoading,
    isTransferOwnershipSelected,
    isFormAdmin,
    formMethods,
    isFullWidth: isMobile,
    validationRules,
    handleInputSubmission,
  }
}

export const AddCollaboratorInput = (): JSX.Element => {
  const {
    formMethods: {
      control,
      register,
      formState: { errors },
    },
    isFullWidth,
    isQueryLoading,
    isTransferOwnershipSelected,
    isFormAdmin,
    isMutationLoading,
    validationRules,
    handleInputSubmission,
  } = useAddCollaboratorInput()
  const { i18n } = useTranslation()
  return (
    <form noValidate onSubmit={handleInputSubmission}>
      <FormControl isInvalid={!isEmpty(errors)} isReadOnly={isMutationLoading}>
        <FormLabel
          isRequired
          description={i18n.t<string>(
            'features.adminForm.common.components.collaboratorModal.share',
          )}
        >
          {i18n.t<string>(
            'features.adminForm.common.components.collaboratorModal.addCollab',
          )}
        </FormLabel>
        <Skeleton isLoaded={!isQueryLoading}>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <Input
              isDisabled={isQueryLoading}
              type="email"
              {...register('email', validationRules)}
              placeholder="me@example.com"
            />
            <Controller
              name="role"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PermissionDropdown
                  allowTransferOwnership={isFormAdmin}
                  isLoading={isQueryLoading || isMutationLoading}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Skeleton>
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        colorScheme={isTransferOwnershipSelected ? 'danger' : 'primary'}
        isDisabled={isQueryLoading}
        isLoading={isMutationLoading}
        isFullWidth={isFullWidth}
        mt="1rem"
        type="submit"
      >
        {isTransferOwnershipSelected
          ? i18n.t<string>(
              'features.adminForm.common.components.collaboratorModal.transfer',
            )
          : i18n.t<string>(
              'features.adminForm.common.components.collaboratorModal.add',
            )}
      </Button>
    </form>
  )
}
