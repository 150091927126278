import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { UnsavedChangesModal } from './UnsavedChangesModal'
import { useNavigationPrompt } from './useNavigationPrompt'

interface NavigationPromptProps {
  /** Whether modal should appear. */
  when: boolean
  /** Modal header title. Defaults to `"You have unsaved changes"` */
  title?: string
  /**
   * Modal body content.
   * Defaults to `"Are you sure you want to leave? Your changes will be lost."`
   */
  description?: string
  /** Text to display for the confirmation button. Defaults to `"Yes, discard changes"` */
  confirmButtonText?: string
  /** Text to display for the cancel button. Defaults to `"No, stay on page"` */
  cancelButtonText?: string
}
export const NavigationPrompt = memo(({ when }: NavigationPromptProps) => {
  const { isPromptShown, onCancel, onConfirm } = useNavigationPrompt(when)
  const { i18n } = useTranslation()
  return (
    <UnsavedChangesModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      isOpen={isPromptShown}
      onClose={onCancel}
      confirmButtonText={i18n.t<string>(
        'templates.navigationPrompt.unsavedChangesModal.confirmButtonText',
      )}
      cancelButtonText={i18n.t<string>(
        'templates.navigationPrompt.unsavedChangesModal.cancelButtonText',
      )}
      title={i18n.t<string>(
        'templates.navigationPrompt.unsavedChangesModal.title',
      )}
      description={i18n.t<string>(
        'templates.navigationPrompt.unsavedChangesModal.description',
      )}
    />
  )
})
