import { useMemo } from 'react'
import { Box, Stack } from '@chakra-ui/react'

import { FormColorTheme, FormFieldWithId } from '~shared/types'

import Button from '~components/Button'
import { FieldContainer } from '~templates/Field/FieldContainer'

import { VerifiableFieldBase } from '~features/verifiable-fields/types'

import {
  publicI18nSelector,
  usePublicLanguageStore,
} from '../../../../../public-form/usePublicLanguageStore'

type VerifiableFieldBuilderContainerProps = {
  schema: FormFieldWithId<VerifiableFieldBase>
  colorTheme?: FormColorTheme
  children: React.ReactNode
}

export const VerifiableFieldBuilderContainer = ({
  schema,
  colorTheme = FormColorTheme.Blue,
  children,
}: VerifiableFieldBuilderContainerProps): JSX.Element => {
  const publicI18n = usePublicLanguageStore(
    useMemo(() => publicI18nSelector, []),
  )

  return (
    <FieldContainer schema={schema}>
      <Stack>
        {children}
        <Box>
          <Button isDisabled colorScheme={`theme-${colorTheme}`} w="full">
            {publicI18n(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.verify',
            )}
          </Button>
        </Box>
      </Stack>
    </FieldContainer>
  )
}
