import { BxEmail } from '~assets/icons/BxEmail'
import { BxlFacebook } from '~assets/icons/BxlFacebook'
import { BxlInstagram } from '~assets/icons/BxlInstagram'
import { BxLocation } from '~assets/icons/BxLocation'
import { BxlTelegram } from '~assets/icons/BxlTelegram'
import { BxlTwitter } from '~assets/icons/BxlTwitter'
import { BxlYoutube } from '~assets/icons/BxlYoutube'
import { BrandFooter } from '~assets/svgrs/brand/BrandFooter'
import { OgpLogoFull, OgpLogoFull2 } from '~assets/svgrs/brand/OgpFullLogo'

import {
  FooterIconEmail,
  FooterIconLocation,
  FooterLinkWithIcon,
  FooterLinkWithIcon2,
  FooterLinkWithIcon3,
} from './types'

export const DEFAULT_FOOTER_ICON_LINK: FooterLinkWithIcon = {
  href: 'https://dgc.gov.kh/',
  label: '',
  Icon: OgpLogoFull,
}

export const DEFAULT_FOOTER_ICON_LINK2: FooterLinkWithIcon2 = {
  href: 'https://mptc.gov.kh/en/',
  label: '',
  Icon: OgpLogoFull2,
}

export const DEFAULT_FOOTER_ICON_LINK3: FooterLinkWithIcon3 = {
  href: 'https://form.gov.kh/',
  label: '',
  Icon: BrandFooter,
}
export const DEFAULT_ICON_EMAIL: FooterIconEmail = {
  href: '',
  label: '',
  Icon: BxEmail,
}

export const DEFAULT_ICON_LOCATION: FooterIconLocation = {
  href: '',
  label: '',
  Icon: BxLocation,
}

export const DEFAULT_SOCIAL_MEDIA_LINKS: FooterLinkWithIcon[] = [
  {
    href: 'https://www.facebook.com/www.mptc.gov.kh',
    Icon: BxlFacebook,
    label: 'Go to our Facebook page',
  },
  {
    href: 'https://t.me/mptcgovkh',
    Icon: BxlTelegram,
    label: 'Go to our Telegram channel',
  },
  {
    href: 'https://www.youtube.com/c/MPTCMedia',
    Icon: BxlYoutube,
    label: 'Go to our Youtube channel',
  },
  {
    href: 'https://www.instagram.com/mptc.gov.kh',
    Icon: BxlInstagram,
    label: 'Go to our Instagram',
  },
  {
    href: 'https://twitter.com/mptcgovkh',
    Icon: BxlTwitter,
    label: 'Go to our Twitter',
  },
]
