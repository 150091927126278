import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text, VisuallyHidden } from '@chakra-ui/react'
import { format } from 'date-fns'
import { enGB, km } from 'date-fns/locale'

import { FormColorTheme, FormDto } from '~shared/types/form'

import { Languages } from '~/i18n/constants'

import { useMdComponents } from '~hooks/useMdComponents'
import Button from '~components/Button'
import { MarkdownText } from '~components/MarkdownText'

import { SubmissionData } from '~features/public-form/PublicFormContext'

export interface EndPageBlockProps {
  formTitle: FormDto['title'] | undefined
  endPage: FormDto['endPage']
  submissionData: SubmissionData
  colorTheme?: FormColorTheme
  focusOnMount?: boolean
}

export const EndPageBlock = ({
  formTitle,
  endPage,
  submissionData,
  colorTheme = FormColorTheme.Blue,
  focusOnMount,
}: EndPageBlockProps): JSX.Element => {
  const { i18n } = useTranslation()
  const focusRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (focusOnMount) {
      focusRef.current?.focus()
    }
  }, [focusOnMount])

  const mdComponents = useMdComponents({
    styles: {
      text: {
        textStyle: 'subhead-1',
        color: 'secondary.500',
      },
    },
  })

  const submissionTimestamp = useMemo(
    () =>
      format(new Date(submissionData.timestamp), 'dd MMM yyyy, HH:mm:ss z', {
        locale: i18n.language === Languages.English ? enGB : km,
      }),
    [i18n, submissionData.timestamp],
  )

  const submittedAriaText = useMemo(() => {
    if (formTitle) {
      return `${i18n.t<string>(
        'features.publicForm.components.FormEndPage.components.EndPageBlock.successfullySubmitFor',
      )} ${formTitle}`
    }
    return i18n.t<string>(
      'features.publicForm.components.FormEndPage.components.EndPageBlock.successfullySubmit',
    )
  }, [formTitle, i18n])

  return (
    <Flex flexDir="column">
      <Box ref={focusRef}>
        <VisuallyHidden aria-live="assertive">
          {submittedAriaText}
        </VisuallyHidden>
        <Text as="h2" textStyle="h2" textColor="secondary.500">
          {i18n.language === Languages.English
            ? endPage.title
            : endPage.titleKhmer}
        </Text>
        {endPage.paragraph ? (
          <Box mt="0.75rem">
            <MarkdownText components={mdComponents}>
              {endPage.paragraph}
            </MarkdownText>
          </Box>
        ) : null}
      </Box>
      <Box mt="2rem">
        <Text textColor="secondary.300" textStyle="caption-2">
          {i18n.t<string>(
            'features.publicForm.components.FormEndPage.components.EndPageBlock.responseID',
          )}{' '}
          {submissionData.id}
        </Text>
        <Text mt="0.25rem" textColor="secondary.300" textStyle="caption-2">
          {submissionTimestamp}
        </Text>
      </Box>
      <Box mt="2.25rem">
        <Button
          as="a"
          href={endPage.buttonLink || window.location.href}
          variant="solid"
          colorScheme={`theme-${colorTheme}`}
        >
          {i18n.language === Languages.English
            ? endPage.buttonText
            : endPage.buttonTextKhmer ||
              i18n.t<string>(
                'features.adminForm.create.endPageDrawer.reSubmit',
              )}
        </Button>
      </Box>
    </Flex>
  )
}
