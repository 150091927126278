import { useTranslation } from 'react-i18next'
import { format, parse } from 'date-fns'
import { enGB, km } from 'date-fns/locale'

import { Languages } from '~/i18n/constants'

interface IndividualResponseTimeProps {
  value: string
}

export const IndividualResponseTime = ({
  value,
}: IndividualResponseTimeProps): JSX.Element => {
  const { i18n } = useTranslation()

  const submissionTimeFormat = 'EEE, dd MMM yyyy, hh:mm:ss aa'

  const submissionTimeObject = parse(value, submissionTimeFormat, new Date())

  return (
    <>
      {format(submissionTimeObject, 'EEEE, dd MMM yyyy, hh:mm:ss aa', {
        locale: i18n.resolvedLanguage === Languages.English ? enGB : km,
      })}
    </>
  )
}
