import { Box, Flex } from '@chakra-ui/react'

import { SummaryResponseBase } from '~shared/types'

interface ChartSectionProps {
  data: SummaryResponseBase
}
export const ChartSection = ({ data }: ChartSectionProps) => {
  return (
    <Flex
      key={data._id}
      pt={'60px'}
      pb={'5px'}
      borderBottom="2px solid"
      borderRadius={'2px'}
      borderBottomColor={'primary.300'}
    >
      <Box w={'9px'} height={'9px'} m={2} bg={'secondary.700'}></Box>
      <Box textStyle={'h3'} color={'secondary.700'} pl={1}>
        {data.question}
      </Box>
    </Flex>
  )
}
