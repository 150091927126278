import { useQuery, UseQueryResult } from 'react-query'
import { useParams } from 'react-router-dom'

import { SummaryResponseDto } from '~shared/types'

import { ApiService } from '~services/ApiService'

import { adminFormKeys } from '~features/admin-form/common/queries'
import { ADMIN_FORM_ENDPOINT } from '~features/workspace/WorkspaceService'

import { useStorageResponsesContext } from '../ResponsesPage/storage'

export const adminFormSummaryKeys = {
  base: [...adminFormKeys.base, 'summary'] as const,
  id: (id: string) => [...adminFormSummaryKeys.base, id] as const,
}

const getFormSummaryById = async ({
  formId,
  secretKey,
}: {
  formId: string
  secretKey: string | undefined
}) => {
  const queryUrl = `${ADMIN_FORM_ENDPOINT}/${formId}/summary`

  return ApiService.get(queryUrl, {
    headers: { secretKey: secretKey },
  }).then(({ data }) => {
    return data
  })
}

/**
 * @precondition Must be wrapped in a Router as `useParam` is used.
 */
export const useAdminFormSummary = (): UseQueryResult<SummaryResponseDto> => {
  const { formId } = useParams()
  const { secretKey } = useStorageResponsesContext()

  if (!formId) throw new Error('No formId provided')

  return useQuery(
    adminFormSummaryKeys.id(formId),
    () => getFormSummaryById({ formId, secretKey }),
    {
      staleTime: 10 * 1000,
    },
  )
}
