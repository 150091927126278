import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { SmsCountsDto } from '~shared/types/form'

import { ADMINFORM_ROUTE, ADMINFORM_SETTINGS_SUBROUTE } from '~constants/routes'
import { useIsMobile } from '~hooks/useIsMobile'
import Badge from '~components/Badge'
import Button from '~components/Button'
import Link from '~components/Link'
import Spinner from '~components/Spinner'

import { formatSmsCounts } from './utils'

type SmsCountsModalProps = {
  freeSmsCount?: SmsCountsDto
  isOpen: boolean
  onClose: () => void
}
export const SmsCountsModal = ({
  freeSmsCount,
  isOpen,
  onClose,
}: SmsCountsModalProps) => {
  const { formId } = useParams()
  const { i18n } = useTranslation()

  const modalSize = useBreakpointValue({
    base: 'mobile',
    xs: 'mobile',
    md: 'md',
  })

  const isMobile = useIsMobile()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {i18n.t<string>(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.verifySMS',
          )}
        </ModalHeader>
        <ModalBody>
          {freeSmsCount === undefined ? (
            <Spinner fontSize="2rem" />
          ) : (
            <>
              <Text textStyle="body-2">
                {i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.formProvides',
                )}
                {`${freeSmsCount.quota.toLocaleString()}`}{' '}
                {i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.freeOTP',
                )}
              </Text>
              <Text textStyle="body-2" mt="1.5rem">
                {i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.ensureEnoughVerify',
                )}{' '}
                {`${freeSmsCount.quota.toLocaleString()}`}{' '}
                {i18n.t<string>(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.verifications',
                )}{' '}
                <Link
                  href={`${ADMINFORM_ROUTE}/${formId}/${ADMINFORM_SETTINGS_SUBROUTE}`}
                >
                  {i18n.t<string>(
                    'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.twilioCredentials',
                  )}
                </Link>
              </Text>
              <Badge
                colorScheme="primary"
                variant="subtle"
                color="secondary.500"
                mt="2rem"
              >
                {formatSmsCounts(freeSmsCount)}
              </Badge>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button isFullWidth={isMobile} onClick={onClose}>
            {i18n.t<string>(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.SMSCountsModal.understand',
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
