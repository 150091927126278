import { Box, Container } from '@chakra-ui/react'

import { EmptyResponses } from '../ResponsesPage/common/EmptyResponses'
import {
  SecretKeyVerification,
  useStorageResponsesContext,
} from '../ResponsesPage/storage'

import { UnlockedSummary } from './UnlockedSummary'

export const SummaryTab = () => {
  const { totalResponsesCount, secretKey } = useStorageResponsesContext()

  if (totalResponsesCount === 0) {
    return (
      <Box overflowY="auto">
        <Container
          px={{ base: '1.5rem', md: '1.25rem' }}
          py={{ base: '1.5rem', md: '3rem' }}
          maxW="69.5rem"
          flex={1}
          display="flex"
          flexDir="column"
          color="secondary.500"
        >
          <EmptyResponses />
        </Container>
      </Box>
    )
  }

  return secretKey ? <UnlockedSummary /> : <SecretKeyVerification />
}
