import { Outlet } from 'react-router-dom'
import { Container } from '@chakra-ui/react'

export const SummaryLayout = (): JSX.Element => {
  return (
    <Container
      padding={'0px'}
      overflowY="auto"
      maxW="100%"
      flex={1}
      display="flex"
      flexDir="column"
    >
      <Outlet />
    </Container>
  )
}
