import { Container, HStack, Skeleton, Stack } from '@chakra-ui/react'

export const SummaryPageSkeleton = (): JSX.Element => (
  <Container p={4} maxW="42.5rem">
    <Stack spacing="2rem">
      <Skeleton h="1rem" w="144px" />
      <HStack w="100%">
        <Skeleton w="50%" h="3xs" />
        <Skeleton w="40%" h="3xs" />
      </HStack>
      <Skeleton h="1rem" w="144px" />
      <HStack w="100%">
        <Skeleton w="50%" h="3xs" />
        <Skeleton w="40%" h="3xs" />
      </HStack>
    </Stack>
  </Container>
)
