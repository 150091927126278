import { Context } from 'chartjs-plugin-datalabels'

const SHOW_PERCENTAGE_THRESHOLD = 8

export const formatChartDataLabels = (value: number, ctx: Context) => {
  const dataArr = ctx.dataset.data
  const sum = dataArr.reduce((total, dataPoint) => {
    if (!dataPoint) {
      return total
    }

    return Number(total) + Number(dataPoint)
  }, 0)

  const percentage = `${((value * 100) / Number(sum)).toFixed(2)}%`

  return dataArr.filter((value) => value !== 0).length <
    SHOW_PERCENTAGE_THRESHOLD
    ? `${value} (${percentage})`
    : `${value}`
}
