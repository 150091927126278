import { FormPermission } from '~shared/types/form/form'

import i18n from '~/i18n/i18n'

import { DropdownRole } from './constants'

export const permissionsToRole = (permission: FormPermission): DropdownRole => {
  return permission.write ? DropdownRole.Editor : DropdownRole.Viewer
}

export const roleToPermission = (
  role: DropdownRole,
): Omit<FormPermission, 'email'> => {
  switch (role) {
    case DropdownRole.Owner:
    case DropdownRole.Editor:
      return { write: true }
    case DropdownRole.Viewer:
      return { write: false }
  }
}

export const translateRole = (role: string): string =>
  ({
    [DropdownRole.Owner]: i18n.t<string>('ownership.owner'),
    [DropdownRole.Editor]: i18n.t<string>('ownership.editor'),
    [DropdownRole.Viewer]: i18n.t<string>('ownership.viewer'),
  }[role])
