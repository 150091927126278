import 'dayjs/locale/km'
import 'dayjs/locale/en'

import { useTranslation } from 'react-i18next'
import {
  Flex,
  Icon,
  Menu as MenuLanguage,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'

import { BxsEnglish } from '~assets/icons/BxsEnglish'
import { BxsKhmer } from '~assets/icons/BxsKhmer'

import { Languages } from './constants'

export const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  return (
    <Flex>
      <MenuLanguage>
        <MenuButton
          p={'13px 14px'}
          border={'1px solid'}
          borderColor="neutral.300"
          borderRadius={'6px'}
        >
          {' '}
          <Icon
            ml="-0.25rem"
            as={i18n.language === Languages.Khmer ? BxsKhmer : BxsEnglish}
          />
        </MenuButton>
        <MenuList borderRadius={'6px'} bg={'white'}>
          <MenuItem
            onClick={() => {
              i18n.changeLanguage(Languages.Khmer)
              window.location.reload()
            }}
          >
            {' '}
            <Icon ml="-0.25rem" as={BxsKhmer} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              i18n.changeLanguage(Languages.English)
              window.location.reload()
            }}
          >
            {' '}
            <Icon ml="-0.25rem" as={BxsEnglish} />
          </MenuItem>
        </MenuList>
      </MenuLanguage>
    </Flex>
  )
}
