import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@chakra-ui/react'
import { intlFormat } from 'date-fns'

import { Languages } from '~/i18n/constants'

export interface DateCellProps {
  value: number
}

export const DateCell = ({ value }: DateCellProps): JSX.Element => {
  const { i18n } = useTranslation()

  const localeOptions = useMemo(() => {
    return {
      locale: i18n.language === Languages.English ? 'en-GB' : 'km-KH',
    }
  }, [i18n])

  const shortLocaleDateString = useMemo(() => {
    return intlFormat(
      new Date(value),
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      localeOptions,
    )
  }, [value, localeOptions])

  const fullLocaleDateString = useMemo(
    () =>
      intlFormat(
        new Date(value),
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZoneName: 'short',
        },
        localeOptions,
      ),
    [value, localeOptions],
  )

  // Render timestamp as locale-aware date
  return <Text title={fullLocaleDateString}>{shortLocaleDateString}</Text>
}
