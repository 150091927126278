import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Skeleton, Stack, Text, useDisclosure } from '@chakra-ui/react'

import {
  FormAuthType,
  FormResponseMode,
  FormStatus,
} from '~shared/types/form/form'

import InlineMessage from '~components/InlineMessage'
import { Switch } from '~components/Toggle/Switch'

import { useMutateFormSettings } from '../mutations'
import { useAdminFormSettings } from '../queries'

import { SecretKeyActivationModal } from './SecretKeyActivationModal'

export const FormStatusToggle = (): JSX.Element => {
  const { i18n } = useTranslation()

  const { data: formSettings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  const { status, responseMode, authType, esrvcId } = formSettings ?? {}

  const storageModalProps = useDisclosure()
  const { onOpen: onOpenActivationModal } = storageModalProps

  const isFormPublic = useMemo(() => status === FormStatus.Public, [status])
  const isPreventActivation = useMemo(
    () =>
      // Prevent switch from being activated if form has authType but no esrvcId.
      // But only if form is not already public
      // (so admin can toggle to private mode when that happens somehow).
      status === FormStatus.Private &&
      authType &&
      [FormAuthType.CP, FormAuthType.SP, FormAuthType.MyInfo].includes(
        authType,
      ) &&
      !esrvcId,
    [authType, esrvcId, status],
  )

  const { mutateFormStatus } = useMutateFormSettings()

  const handleToggleStatus = useCallback(() => {
    if (!status || isLoadingSettings || mutateFormStatus.isLoading) return

    const nextStatus =
      status === FormStatus.Public ? FormStatus.Private : FormStatus.Public

    if (
      nextStatus === FormStatus.Public &&
      responseMode === FormResponseMode.Encrypt
    ) {
      return onOpenActivationModal()
    }

    return mutateFormStatus.mutate(nextStatus)
  }, [
    isLoadingSettings,
    mutateFormStatus,
    onOpenActivationModal,
    responseMode,
    status,
  ])

  return (
    <Skeleton isLoaded={!isLoadingSettings && !!status}>
      <Stack>
        {formSettings?.responseMode === FormResponseMode.Encrypt && (
          <SecretKeyActivationModal
            {...storageModalProps}
            mode="formStatusActivation"
            publicKey={formSettings.publicKey}
          />
        )}
        <Flex
          bg={isFormPublic ? 'success.100' : 'danger.200'}
          padding="1rem"
          px="0.5rem"
          justify="space-between"
        >
          <Text textStyle="subhead-1" id="form-status" display={'ruby'}>
            {i18n.t<string>(
              'features.adminForm.settings.components.formStatusToggle.yourForm',
            )}
            <b>
              {isFormPublic
                ? i18n.t<string>(
                    'features.adminForm.settings.components.formStatusToggle.open',
                  )
                : i18n.t<string>(
                    'features.adminForm.settings.components.formStatusToggle.closed',
                  )}
            </b>
            {i18n.t<string>(
              'features.adminForm.settings.components.formStatusToggle.newRes',
            )}
          </Text>
          <Switch
            isDisabled={formSettings?.schedule.isEnable}
            aria-label="Toggle form status"
            aria-describedby="form-status"
            isLoading={mutateFormStatus.isLoading}
            isChecked={isFormPublic}
            onChange={handleToggleStatus}
          />
        </Flex>
        {formSettings?.schedule.isEnable && (
          <InlineMessage>
            {i18n.t<string>(
              'features.adminForm.settings.components.formStatusToggle.inlineMessage',
            )}
          </InlineMessage>
        )}
        {isPreventActivation ? (
          <InlineMessage variant="warning">
            {i18n.t(
              'features.adminForm.settings.components.formStatusToggle.thisFormCannotBeActivatedUntilAValidEServiceIDIsEnteredEtc',
            )}
          </InlineMessage>
        ) : null}
      </Stack>
    </Skeleton>
  )
}
