import Translation from './types'

export const kh: Translation = {
  translation: {
    utils: {
      fieldValidation: {
        verifyMobile: 'សូមផ្ទៀងផ្ទាត់លេខទូរសព្ទរបស់អ្នក',
        verifyEmail: 'សូមផ្ទៀងផ្ទាត់អ៊ីម៉ែលរបស់អ្នក',
        selectExactly: 'សូមជ្រើសរើសត្រឹម',
        selectAtLeast: 'សូមជ្រើសរើសតិចបំផុត',
        selectAtMost: 'សូមជ្រើសរើសច្រើនបំផុត',
        options: 'ជម្រើស',
        enterValidDate: 'សូមវាយបញ្ចូលកាលបរិច្ឆេទដែលត្រឹមត្រូវ',
        todayOrBefore: 'អនុញ្ញាតតែកាលបរិច្ឆេទថ្ងៃនេះ ​ឬមុនថ្ងៃនេះប៉ុណ្ណោះ',
        todayOrAfter: 'អនុញ្ញាតតែកាលបរិច្ឆេទថ្ងៃនេះ ​ឬក្រោយថ្ងៃនេះប៉ុណ្ណោះ',
        notWithinRange:
          'កាលបរិច្ឆេទមិនស្ថិតក្នុងចន្លោះកាលបរិច្ឆេទដែលបានអនុញ្ញាត',
        validNRIC: 'សូមវាយបញ្ចូលអត្តសញ្ញាណប័ណ្ណដែលត្រឹមត្រូវ',
        validUEN: 'សូមវាយបញ្ចូល UEN ដែលត្រឹមត្រូវ',
        enter: 'សូមវាយបញ្ចូល',
        enterAtLeast: 'សូមវាយបញ្ចូលតិចបំផុត',
        enterAtMost: 'សូមវាយបញ្ចូលច្រើនបំផុត',
        character: 'តួអក្សរ',
        validDecimal: 'សូមវាយបញ្ចូលចំនួនទសភាគដែលត្រឹមត្រូវ',
        validDecimalNoZero:
          'សូមវាយបញ្ចូលចំនួនទសភាគដែលត្រឹមត្រូវ​ ដោយពុំមានលេខសូន្យពីមុខ',
        decimalBetween: 'សូមវាយបញ្ចូលចំនួនទសភាគចន្លោះ',
        decimalGreaterOrEqual: 'សូមវាយបញ្ចូលចំនួនទសភាគធំជាង ​ឬស្មើ',
        decimalLessOrEqual: 'សូមវាយបញ្ចូលចំនួនទសភាគតូចជាង ​ឬស្មើ',
        and: 'និង',
        inclusive: '(រួមបញ្ចូលទាំងអស់)',
        digit: 'តួ',
        validLandline: 'សូមវាយបញ្ចូលលេខទូរសព្ទដែលត្រឹមត្រូវ',
      },
    },
    dialog: {
      areYouSureTitle: 'តើ​អ្នក​ប្រាកដ​ឬ​ទេ?',
      areYouSureBody: 'តើអ្នកប្រាកដថាចង់អនុវត្តសកម្មភាពនេះទេ?',
      areYouSureApproveBody:
        'តើ​អ្នក​ប្រាកដ​ជា​ចង់​អនុម័ត​ការ​ដាក់​ស្នើ​ទម្រង់​នេះ?',
      areYouSureRejectBody: 'តើអ្នកប្រាកដថាចង់បដិសេធការបញ្ជូនទម្រង់នេះទេ?',
      yes: 'បាទ/ចាស',
      no: 'ទេ',
    },
    activateSecretKey: {
      activate: 'បើកដំណើរការទម្រង់បែបបទរបស់អ្នក',
      enterOrUpload: 'បញ្ចូល ឬបង្ហោះសោសម្ងាត់',
      loseKey:
        'ប្រសិនបើខ្ញុំបាត់សោសម្ងាត់ ខ្ញុំនឹងមិនអាចដំណើរការទម្រង់បែបបទរបស់ខ្ញុំបានទេ ហើយការឆ្លើយតបទាំងអស់របស់ខ្ញុំនឹងត្រូវបាត់បង់ជាអចិន្ត្រៃយ៍',
      activateForm: 'បើកដំណើរការទម្រង់បែបបទ',
      secretKeyProvided: 'សោសម្ងាត់ដែលបានផ្តល់ឱ្យគឺមិនត្រឹមត្រូវទេ',
      selectedFile: 'ឯកសារដែលបានជ្រើសរើសហាក់ដូចជាមិនត្រឹមត្រូវ',
      invalid: 'សោសម្ងាត់ដែលបានផ្តល់គឺមិនត្រឹមត្រូវទេ',
      enterKey: 'សូមបញ្ចូលសោសម្ងាត់នៃទម្រង់បែបបទ',
      enterKeyToContinue: 'បញ្ចូល ឬបង្ហោះសោសម្ងាត់របស់អ្នកដើម្បីបន្ត',
    },
    settingMutations: {
      formOpen: 'ទម្រង់បែបបទរបស់អ្នកឥឡូវនេះត្រូវបានបើក',
      storeSecretKey:
        'សូមទុកសោសម្ងាត់របស់អ្នកនៅកន្លែងដែលមានសុវត្ថិភាព ប្រសិនបើអ្នកបាត់បង់សោសម្ងាត់ ការឆ្លើយតបរបស់អ្នកទាំងអស់នឹងត្រូវបាត់បង់់',
      autoArchive: 'រក្សាទុកប្រអប់សាររបស់អ្នកដោយស្វ័យប្រវត្តិ',
      largeResponse: 'ប្រសិនបើអ្នករំពឹងថានឹងមានការឆ្លើយតបច្រើន​ ',
      avoidLost: ' ដើម្បីកុំឱ្យបាត់បង់ការឆ្លើយតប',
      formClose: 'ទម្រង់បែបបទរបស់អ្នកត្រូវបានបិទចំពោះការឆ្លើយតបថ្មី',
      autoFormClose:
        'ឥឡូវនេះទម្រង់បែបបទរបស់អ្នកនឹងបិទដោយស្វ័យប្រវត្តិនៅពេលទទួលបានការឆ្លើយតបទី { formLimit }',
      submissionLimit:
        'ការកំណត់ចំនួនឆ្លើយតបនៅលើទម្រង់បែបបទរបស់អ្នកត្រូវបានដកចេញ',
      recaptcha: 'reCAPTCHA ត្រូវបាន',
      enable: 'បើក',
      disable: 'បិទ',
      onYourForm: 'ក្នុងទម្រង់បែបបទរបស់អ្នក',
      titleUpdate: 'ចំណងជើងទម្រង់បែបបទរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាព',
      inactive: 'សារពេលអសកម្មនៃទម្រង់បែបបទរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាព',
      email: 'ធ្វើបច្ចុប្បន្នភាពអ៊ីម៉ែលដោយជោគជ័យ',
      eservice: 'លេខសម្គាល់​ E-service ID ត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ',
      formAuth:
        'ការផ្ទៀងផ្ទាត់របស់ទម្រង់បែបបទត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ',
      webhook: 'Webhook URL ',
      update: 'បានធ្វើបច្ចុប្បន្នមានដោយជោគជ័យ',
      remove: 'បានលុបដោយជោគជ័យ',
      webhookRetry: 'ការព្យាយាមឡើងវិញនៃ webhook ត្រូវបាន',
      enableWithDot: 'បើក',
      disableWithDot: 'បិទ',
      twilio: 'បានធ្វើបច្ចុប្បន្នភាពអត្តសញ្ញាណ Twilio',
      deleteTwilio: 'បានលុបអត្តសញ្ញាណ Twilio',
    },
    responsePage: {
      responseID: 'លេខសម្គាល់ការឆ្លើយតប៖ ',
      timestamp: 'ពេលវេលា៖ ',
      status: 'ស្ថានភាព',
      attachment: 'ឯកសារ​ភ្ជាប់៖ ',
      backtolist: 'ត្រលប់ទៅបញ្ជីវិញ',
      respondent: 'អ្នកឆ្លើយតប',
      download: 'ទាញយក',
      csv: 'ទាញយកតែ CSV',
      csvAttachment: 'ទាញយក CSV ជាមួយឯកសារ​ភ្ជាប់',
      noResponse: 'ពុំមានការឆ្លើយតបសម្រាប់ទាញយក',
      response: 'ការឆ្លើយតប',
      s: '',
      was: '',
      were: '',
      decrypted: 'ត្រូវបានឌិគ្រីប',
      responseProcess:
        '​ ការឆ្លើយតបកំពុងដំណើរការ​ ចាកចេញពីទំព័រនេះនឹងបញ្ឈប់ការទាញយក',
      stopDownload: 'តើអ្នកចង់បញ្ឈប់ការទាញយក?',
      stopDownloadDescript:
        'អ្នកកំពុងទាញយកការឆ្លើយតប​ ការទាញយកនឹងត្រូវបញ្ឈប់ប្រសិនបើអ្នកចាកចេញពីទំព័រនេះ',
      leavePage: 'បាទ/ចាស ចាកចេញពីទំព័រនេះ',
      downloadCancel: 'ការទាញយកទម្រង់បែបបទត្រូវបានបោះបង់',
      downloadOption: 'ជម្រើសនៃការទាញយក',
      downloadFail: 'មិនអាចចាប់ផ្ដើមទាញយក​ សូមព្យាយាមម្ដងទៀតនៅពេលក្រោយ',
      downloadResponse: 'ទាញយកការឆ្លើយតប និងឯកសារ​ភ្ជាប់',
      beta: 'beta',
      separateZip: 'ឯកសារជាប្រភេទ zip នឹងត្រូវទាញយកដោយ ',
      eachResponse: 'ឯកសារមួយសម្រាប់ការឆ្លើយតបមួយ។',
      adjust: 'អ្នកអាចកែសម្រួលចន្លោះកាលបរិច្ឆេទមុនពេលចាប់ផ្ដើម។',
      numberOfResponse: 'ចំនួនការឆ្លើយតប​ និងឯកសារ​ភ្ជាប់:',
      estimate: 'រយៈពេលប៉ាន់ស្មាន',
      perResponse: '30-50 នាទីសម្រាប់ការឆ្លើយតប 1,000',
      intensive:
        'ការទាញយកឯកសារ​ភ្ជាប់ច្រើនអាចជាប្រតិបត្តិការដែលប្រើប្រាស់ធនធានខ្លាំង',
      IE: 'សូមកុំប្រើប្រាស់ Internet Explorer',
      networkConnect: 'ត្រូវប្រាកដថាអ៊ីនធឺណិតរបស់អ្នកមានល្បឿនគ្រប់គ្រាន់',
      sufficient:
        'ត្រូវប្រាកដថាឧបករណ៍របស់អ្នកមានអង្គចងចាំគ្រប់គ្រាន់សម្រាប់ការទាញយក',
      cancel: 'បោះបង់',
      startDownload: 'ចាប់ផ្ដើមទាញយក',
      dateRange:
        'ចន្លោះកាលបរិច្ឆេទដែលអ្នកជ្រើសរើសពុំមានការឆ្លើយតបឡើយ​ សូមជ្រើសរើសចន្លោះកាលបរិច្ឆេទណាដែលមានការឆ្លើយតបរួចព្យាយាមម្ដងទៀត​',
      errorRetrieve:
        'មានបញ្ហាក្នុងការទាញយកទម្រង់បែបបទរបស់អ្នក សូម​ព្យាយាម​ម្តង​ទៀត​នៅ​ពេល​ក្រោយ',
      allRes: 'ការឆ្លើយតបទាំងអស់',
      andAttachment: 'រួមទាំងឯកសារ​ភ្ជាប់',
      downloadSuccess: 'ត្រូវបានទាញយកដោយជោគជ័យ',
      downloadComplete: 'ទាញយករួចរាល់',
      backToResponse: 'ត្រលប់ទៅការឆ្លើយតប',
      filename: 'ឈ្មោះឯកសារ៖ ',
      responseAndAttachment:
        'ការឆ្លើយតប​ និងឯកសារ​ភ្ជាប់ត្រូវបានទាញយកដោយជោគជ័យ សូមមើលឯកសារ​​ CSV ដែលបានទាញយករួចសម្រាប់ព័ត៌មានលម្អិត',
      responseNoAttachment:
        'ការឆ្លើយតប​ត្រូវបានទាញយកដោយជោគជ័យ សូមមើលឯកសារ​​ CSV ដែលបានទាញយករួចសម្រាប់ព័ត៌មានលម្អិត',
      responseAndAttachmentCannotDownload:
        'ការឆ្លើយតប​ និងឯកសារ​ភ្ជាប់ពុំអាចទាញយកបាន',
      responseNoAttachmentCannotDownload: 'ការឆ្លើយតប​ពុំអាចទាញយកបាន',
      partialSuccess: 'ជោគជ័យមួយផ្នែក​ ',
      decryptedRes: 'ការឆ្លើយតបត្រូវបានឌិគ្រីប',
      failed: 'បរាជ័យ ​',
      success: 'ជោគជ័យ​',
      downloading: 'កំពុងទាញយក...',
      stopDownloading: 'បញ្ឈប់ការទាញយក',
      completed: 'រួចរាល់',
      upto: 'រហូតដល់ ',
      navigating: 'ឯកសារកំពុងត្រូវបានទាញយក ចាកចេញពីទំព័រនេះនឹងបញ្ឈប់ការទាញយក',
    },
    ownership: {
      owner: 'ម្ចាស់កម្មសិទ្ធិ',
      editor: 'អ្នកកែសម្រួល',
      viewer: 'អ្នកមើល',
    },
    banner: {
      header: 'ផ្នែកខាងលើ',
      image: 'រូបភាព',
      paragraph: 'កថាខណ្ឌ',
      field: 'ចន្លោះ',
      delete: 'ត្រូវបានលុប',
      thefield: '',
      create: 'ត្រូវបានបង្កើត',
      duplicate: 'ត្រូវបានចម្លង',
      update: 'ត្រូវបានធ្វើបច្ចុប្បន្នភាព',
      cannotDelete:
        'មានបញ្ហាកើតឡើងនៅពេលកំពុងលុបចន្លោះរបស់អ្នក សូមព្យាយាមម្ដងទៀត',
      cannotCreate:
        'មានបញ្ហាកើតឡើងនៅពេលកំពុងបង្កើតចន្លោះរបស់អ្នក សូមព្យាយាមម្ដងទៀត',
      cannotDuplicate:
        'មានបញ្ហាកើតឡើងនៅពេលកំពុងចម្លងចន្លោះរបស់អ្នក សូមព្យាយាមម្ដងទៀត',
      cannotUpdate:
        'មានបញ្ហាកើតឡើងនៅពេលកំពុងកែសម្រួលចន្លោះរបស់អ្នក សូមព្យាយាមម្ដងទៀត',
    },
    validate: {
      fieldRequired: 'ចន្លោះនេះចាំបាច់ត្រូវបំពេញ',
      invalidEmail: 'សូមបញ្ចូលអ៊ីម៉ែលឱ្យបានត្រឹមត្រូវ',
      invalidDomain: 'អ៊ីម៉ែលរបស់អ្នក​មិនស្ថិតក្នុងដែនអ៊ីម៉ែលដែលត្រូវបានអនុញាត',
      invalidDropDown:
        'តម្លៃដែលអ្នកបញ្ចូលមិនអាចប្រើជាជម្រើសនៅក្នុងប្រអប់ជម្រើសឡើយ',
      invalidNumber: 'សូមបញ្ចូលលេខទូរសព្ទឱ្យបានត្រឹមត្រូវ',
      invalidSecretKey: 'សោសម្ងាត់ដែលបានបញ្ចូលមិនត្រឹមត្រូវទេ',
      enterSecretKey: 'សូមបញ្ចូលសោសម្ងាត់របស់ទម្រង់បែបបទ',
      pleaseEnterNumberOfCharacters: 'សូមបញ្ចូលចំនួននៃតួអក្សរ',
      pleaseEnterNumberOfNumberOfCharacters: 'សូមបញ្ចូលចំនួនខ្ទង់',
    },
    feedback: {
      shareFeedback: 'ចែករំលែកមតិកែលម្អរបស់អ្នក',
      exp: 'តើបទពិសោធន៍ប្រើប្រាស់ FormKH របស់អ្នកដូចម្ដេចដែរ?',
      rating: '1: ខ្សោយ, 5: ល្អប្រសើរ',
      anyFeedback: 'តើ​អ្នក​មាន​មតិ​កែលម្អ​ផ្សេង​ទៀត​សម្រាប់​ពួកយើង​ទេ?',
      submit: 'បញ្ជូនមតិកែលម្អ',
      cancel: 'ចាកចេញ',
      export: 'បញ្ជូនចេញ',
      feedbackDownload: 'ការទាញយកមតិយោបល់របស់ទម្រង់បែបបទត្រូវបានចាប់ផ្ដើម',
    },
    footer: {
      builtBy: 'បង្កើតដោយ',
      dgc: 'គណៈកម្មាធិការរដ្ឋាភិបាល​ឌីជីថល',
      contact: 'ទំនាក់ទំនង',
      email: 'info@mptc.gov.kh',
      location:
        'អគារលេខ១៣ មហាវិថីព្រះមុនីវង្ស សង្កាត់ស្រះចក ខណ្ឌដូនពេញ រាជធានីភ្នំពេញ ព្រះរាជាណាចក្រកម្ពុជា 120210',
      privacy: 'Privacy',
      termsOfUse: 'Terms of use',
    },
    landingPage: {
      headTitle: 'បង្កើតទម្រង់បែបបទ​ប្រកបទៅដោយ ​',
      headTitleColor: 'សុវត្ថិភាព​ រហ័ស ងាយស្រួល ឥតគិតថ្លៃ !​',
      instant:
        'ទម្រង់បែបបទ ដែលអាចប្រើប្រាស់​សម្រាប់​​​ ​ប្រមូលទិន្នន័យ​ដោយសុវត្ថិភាព​​ ប្រើបានភ្លាមៗ​ ដោយមិនបាច់ត្រូវការ​​​​អភិវឌ្ឍន៍​កូដ និងឥតគិតថ្លៃ។',
      startBuilding: 'ចាប់ផ្តើមបង្កើតទម្រង់បែបបទរបស់អ្នកឥឡូវនេះ',
      helpCenterHeader: 'ម​គ្គុ​ទេស​ក៍​​អ្នក​ប្រើប្រាស់',
      helpCenterDescription:
        'FormKH ជាកម្មវិធីបង្កើតទម្រង់បែបបទ (form) អនឡាញដែលអនុញ្ញាតឱ្យអ្នកប្រើប្រាស់បង្កើតទម្រង់បែបបទផ្ទាល់ខ្លួនសម្រាប់គោលបំណងផ្សេងៗ ដូចជាការស្ទង់មតិ ការចុះឈ្មោះ ការប្រមូលមតិកែលម្អ និងច្រើនទៀត។',

      helpCenter: 'ស្វែងយល់បន្ថែមអំពី FormKH',
      formAndData:
        'លក្ខណៈពិសេស នៃការបង្កើតទម្រង់បែបបទ និងការប្រមូលទិន្នន័យរបស់អ្នក',
      dragAndDrop: 'បង្កើតទម្រង់បែបបទដោយ​គ្រាន់តែ drag & drop',
      dragAndDropDescript:
        'ដាក់ឱ្យដំណើរការទម្រង់បែបបទក្នុងរយៈពេលប៉ុន្មាននាទី​ ជាមួយការបង្កើតទម្រង់ដោយគ្រាន់តែ​ drag & drop ជាមួយនឹងប្រភេទសំណួរជាង ២០មុខ។',
      conditionalLogic: 'លក្ខខណ្ឌក្នុងទម្រង់បែបបទ',
      conditionalLogicDescript:
        'បង្កើតទម្រង់បែបបទដែលអាចបង្ហាញ ឬលាក់សំណួរ​ដោយផ្អែកលើការឆ្លើយតបពីមុន។​',
      emailConfirmation: 'ការបញ្ជាក់តាមអ៊ីម៉ែល',
      emailConfirmationDescript:
        'ផ្ញើអ៊ីម៉ែលបញ្ជាក់ទៅកាន់អ្នកឆ្លើយតប រួមជាមួយនឹងច្បាប់ចម្លងនៃការឆ្លើយតបរបស់ពួកគេ។',
      prefill: 'បំពេញជាមុន',
      prefillDescript:
        'ធ្វើឱ្យការបំពេញទម្រង់បែបបទកាន់តែលឿនសម្រាប់អ្នកឆ្លើយតបដោយបំពេញចន្លោះជាមុនសម្រាប់ពួកគេ។',
      webhooks: 'Webhooks',
      webhooksDescript:
        '​អាចទទួលការឆ្លើយតបពីអ្នកប្រើប្រាស់នៅក្នុងកម្មវិធីទី៣​ របស់អ្នកភ្លាមៗ។',
      automaticScheduler: 'កំណត់ពេលវេលាដោយស្វ័យប្រវត្តិ',
      automaticSchedulerDescription:
        'កំណត់ពេលវេលាដើម្បីបើកនិងបិទទម្រង់បែបបទរបស់អ្នកដោយស្វ័យប្រវត្តិ។',
      usedBy: 'ប្រើប្រាស់ដោយភ្នាក់ងាររដ្ឋាភិបាលជាច្រើន',
      secureCollect: 'ការប្រមូលការឆ្លើយតបប្រកបដោយសុវត្ថិភាព',
      encrypted:
        'រាល់ការឆ្លើយតបទម្រង់បែបបទទាំងអស់ត្រូវបានបម្លែង (encrypt end-to-end)។ នេះមានន័យថាភាគីទីបី រួមទាំង FormKH នឹងមិនអាចចូលប្រើ ឬមើលទិន្នន័យទម្រង់បែបបទរបស់អ្នកបានទេ។',
      storageMode: 'STORAGE MODE',
      sensitive: 'Sensitive (Normal)',
      howItWorks: 'របៀបដំណើរការ',
      viewResponse:
        'មើលការឆ្លើយតបរបស់អ្នកនៅក្នុង FormKH ។ ទិន្នន័យទាំងអស់ត្រូវបាន​ encrypt end-to-end ដែលមានន័យថាភាគីទីបី រួមទាំង FormKH នឹងមិនអាចចូលប្រើ ឬមើលទិន្នន័យទម្រង់បែបបទរបស់អ្នកបានទេ។',
      step1: 'ចូលទៅ FormKH តាមរយៈអ៊ីនធឺណិត',
      step2:
        'បង្កើតទម្រង់បែបបទ​ Storage mode ថ្មី​ និងរក្សាទុកសោសម្ងាត់ដោយមានសុវត្ថិភាព',
      step3: 'បញ្ចូលសោសម្ងាត់ រួចមើលការឆ្លើយតបរបស់អ្នក',
      step4: 'ទាញយកការឆ្លើយតបរបស់អ្នកជា CSV',
      step1Header: 'ចូលគណនី',
      step2Header: 'បង្កើតទម្រង់បែបបទ',
      step3Header: 'បញ្ចូលសោសម្ងាត់',
      step4Header: 'ទាញយក',

      startBuildingForm: 'ចាប់ផ្តើមបង្កើតទម្រង់បែបបទរបស់អ្នកឥឡូវនេះ',
      getStarted: 'ចាប់ផ្តើមឥឡូវនេះ',
    },
    app: {
      adminNavBar: {
        adminNavBar: {
          billing: 'វិក័យប័ត្រ',
          emergencyContact: 'លេខ​ទំនាក់ទំនង​បន្ទាន់',
          logout: 'ចាកចេញ',
        },
      },
      mixed: {
        others: 'ផ្សេងៗ / Others',
        submit: 'បញ្ជូន / Submit now',
      },
    },

    NotFoundError: {
      NotFoundErrorPage: {
        notFoundErrorPage: 'ទំព័រដែលអ្នកកំពុងស្វែងរកមិនត្រូវបានរកឃើញទេ។',
        back: 'ត្រឡប់',
        goToDashboard: 'ផ្ទាំងគ្រប់គ្រង',
      },
    },

    templates: {
      Field: {
        checkbox: {
          checkboxField: {
            specifyValue: 'សូមបញ្ជាក់ពីតម្លៃនៃជម្រើស"ផ្សេងទៀត"',
          },
        },
        radio: {
          radioField: {
            specifyValue: 'សូមបញ្ជាក់ពីតម្លៃនៃជម្រើស"ផ្សេងទៀត"',
          },
        },
        image: {
          imageField: {
            imageNotProvided: 'មិនទាន់មានរូបភាព',
            cannotDisplay: 'រូបភាពនេះមិនអាចបង្ហាញបានទេ',
          },
        },
        table: {
          AddRowFooter: {
            row: '{ currentRows } ជួរដេក',
            currentRowsOutOfMaxRow:
              '{ currentRows } នៃចំនួនជួរដេកអតិប្បរមា { maxRows }',
          },
        },
      },
      navigationPrompt: {
        unsavedChangesModal: {
          title: 'អ្នកមានការផ្លាស់ប្ដូរដែលមិនទាន់រក្សាទុក',
          description:
            'តើអ្នកពិតជាចង់ចាកចេញមែនទេ? ការផ្លាស់ប្តូររបស់អ្នកនឹងបាត់បង់។',
          confirmButtonText: 'បាទ/ចាស បោះបង់ការផ្លាស់ប្តូរ',
          cancelButtonText: 'ទេ បន្តនៅលើទំព័រដដែល',
        },
      },
      resendOtpButton: {
        resendOTP: 'ផ្ញើពាក្យសម្ងាត់ឡើងវិញ',
        in: 'ក្នុងរយៈពេល',
      },
    },
    features: {
      user: {
        mutations: {
          emergencyContact: 'លេខ​ទំនាក់ទំនង​បន្ទាន់ត្រូវបានដាក់បញ្ជូល',
        },
      },
      rolloutAnnouncement: {
        newFeatureTag: {
          newFeat: 'មុខងារថ្មី',
        },
        rolloutAnnouncementModal: {
          cancel: 'បោះបង់',
          done: 'រួចរាល់',
          next: 'បន្ទាប់',
        },
      },
      verifiableFields: {
        components: {
          Checkbox: {
            others: 'ផ្សេងទៀត',
          },
          VerificationBox: {
            constants: {
              verifyMobile: 'ផ្ទៀងផ្ទាត់លេខទូរសព្ទរបស់អ្នក',
              mobileCode:
                'សារមានភ្ជាប់នូវពាក្យសម្ងាត់៦ខ្ទង់ត្រូវបានផ្ញើទៅអ្នក។​ វាមានសុពលភាព៣០នាទី។',
              verifyEmail: 'ផ្ទៀងផ្ទាត់អ៊ីម៉ែលរបស់អ្នក',
              emailCode:
                'អ៊ីម៉ែលមានភ្ជាប់នូវពាក្យសម្ងាត់៦ខ្ទង់ត្រូវបានផ្ញើទៅអ្នក។​ វាមានសុពលភាព៣០នាទី។',
            },
            verificationBox: {
              otpIsRequired: 'ត្រូវមានពាក្យសម្ងាត់',
              onlyNumber: 'អនុញាតតែលេខប៉ុណ្ណោះ',
              otp: 'សូមបញ្ចូលពាក្យសម្ងាត់៦ខ្ទង់',
              submitOTP: 'បញ្ជូនពាក្យសម្ងាត់ដើម្បីធ្វើការផ្ទៀងផ្ទាត់',
              submit: 'បញ្ជូន',
            },
          },
          VerifiableFieldContainer: {
            verifiableFieldContainer: {
              givenEmailVerified:
                'អាសយដ្ឋានអ៊ីម៉ែលដែលបានផ្ដល់ឱ្យ​ ត្រូវបានផ្ទៀងផ្ទាត់',
              givenMobileVerified:
                'លេខទូរសព្ទដែលបានផ្ដល់ឱ្យ​ ត្រូវបានផ្ទៀងផ្ទាត់',
              verifyEmail: 'ផ្ទៀងផ្ទាត់អាសយដ្ឋានអ៊ីម៉ែល',
              verifyMobile: 'ផ្ទៀងផ្ទាត់លេខទូរសព្ទ',
              verify: 'ផ្ទៀងផ្ទាត់',
              verified: 'ផ្ទៀងផ្ទាត់រួចរាល់',
            },
          },
        },
        VerifiableFieldProvider: {
          pleaseFillInTheFieldBeforeAttemptingVerification:
            'សូមបញ្ចូលចន្លោះមុននឹងព្យាយាមធ្វើការបញ្ជាក់',
        },
      },
      publicForm: {
        components: {
          FormFields: {
            submissionDisabled: 'ការដាក់ស្នើត្រូវបានបិទ',
            submit: 'បញ្ជូន',
            submitting: 'កំពុងបញ្ជូន',
            theHighlightedFieldsInThisFormHaveBeenPrefilledEtc:
              'ចន្លោះដែលមានពណ៌ក្នុងទម្រង់បែបបទនេះត្រូវបានបំពេញជាមុនដោយយោងទៅតាមលីងដែលអ្នកបានចុច។ សូមពិនិត្យមើលតម្លៃទាំងនោះឲ្យបានចម្បាស់ស្របទៅតាមបំណងរបស់អ្នក និងកែប្រែប្រសិនបើត្រូវការ។',
            FormFieldsContainer: {
              thisFormIsJustATemplateYouAreNotAbleToSubmitThisForm:
                'ទម្រង់បែបបទនេះជាគំរូតែប៉ុណ្ណោះ។ លោកអ្នកពុំអាចបញ្ជូនទម្រង់បែបបទនេះបានឡើយ។',
            },
            FormSectionsContext: {
              instructions: 'សេចក្តីណែនាំ',
            },
          },
          FormInstructions: {
            instructions: 'សេចក្តីណែនាំ',
          },
          FormEndPage: {
            formExp:
              'តើបទពិសោធន៍នៃការបំពេញទម្រង់បែបបទរបស់អ្នកថ្ងៃនេះយ៉ាងណាដែរ?',
            tellUs: 'ប្រាប់យើងបន្ថែមអំពីបទពិសោធន៍របស់អ្នក',
            submitFeedback: 'បញ្ជូនមតិកែលម្អ',
            rating: 'សូមជ្រើសរើសការវាយតម្លៃ',
            components: {
              EndPageBlock: {
                responseID: 'លេខសម្គាល់ការឆ្លើយតប៖',
                successfullySubmitFor:
                  'អ្នកបានបញ្ជូនការឆ្លើយតបដោយជោគជ័យសម្រាប់ទម្រង់បែបបទ',
                successfullySubmit: 'អ្នកបានបញ្ជូនការឆ្លើយតបដោយជោគជ័យ',
              },
            },
            FormEndPageContainer: {
              thankYouForSubmittingPreview:
                'សូមអរគុណសម្រាប់ការផ្ដល់មតិកែលម្អ! ប៉ុន្ដែដោយសារអ្នកស្ថិតក្នុងមុខងារមើលទម្រង់បែបបទជាមុន មតិកែលម្អមិនត្រូវបានរក្សាទុកទេ។',
              thankYouForSubmitting: 'សូមអរគុណសម្រាប់ការផ្ដល់មតិកែលម្អ!',
            },
          },
          FormLogo: {
            FormBannerLogo: {
              imageNotProvided: 'រូបភាពមិនត្រូវបានផ្តល់',
              imageNotFound: 'រូបភាពមិនត្រូវបានរកឃើញ',
            },
          },
          FormNotFound: {
            FormNotFound: {
              formNotFound: 'ទម្រង់បែបបទនេះមិនបានដាក់អោយប្រើប្រាស់ទេ។',
              FormNotFoundDescription:
                'ប្រសិនបើអ្នកគិតថានេះកើតឡើងដោយកំហុសឆ្គងណាមួយ​ សូមទំនាក់ទំនង​ភ្នាក់ងារដែលផ្ដល់ទម្រង់បែបបទនេះដល់អ្នក។',
            },
          },
          FormStartPage: {
            estimatedTimeToComplete:
              'រយៈពេលប៉ាន់ស្មានដើម្បីបញ្ចប់៖ { estTimeTake } នាទី',
          },
          FormInstruction: {
            instruction: 'សេចក្តីណែនាំ',
          },
          SectionSidebar: {
            skipToSection: 'រំលងទៅតាមផ្នែក',
          },
        },
      },
      AppPublicHeader: {
        help: 'ជំនួយ',
        login: 'ចូលគណនី',
      },
      env: {
        adminFeedbackIcon: 'មតិកែលម្អ',
      },
      formTemplates: {
        FormTemplatesContent: {
          FormTemplatesHeader: {
            allFormTemplatesTitle: 'គំរូទម្រង់បែបបទទាំងអស់',
          },
          FormTemplatesRow: {
            FormTemplatesRowsFilterNone: {
              noTemplateFormFound: 'រកមិនឃើញទម្រង់បែបបទគំរូនេះទេ',
              tryAnotherSearchOrRemoveFilters:
                'សាកល្បងស្វែងរកតម្រងឬពាក្យគន្លឹះផ្សេងទៀត ឬដកចេញតម្រងឬពាក្យគន្លឹះទាំងនោះ',
            },
          },
        },
      },
      adminForm: {
        share: {
          shareFormModal: {
            formClosed:
              'បច្ចុប្បន្នទម្រង់បែបបទនេះត្រូវបានបិទចំពោះការឆ្លើយតបថ្មី។ បើកទម្រង់បែបបទរបស់អ្នកវិញនៅក្នុង',
            settings: 'ការកំណត់',
            allowResp: 'ដើម្បីអនុញ្ញាតឱ្យមានការឆ្លើយតបថ្មី ឬចែករំលែកវាជាគំរូ។',
            shareLink: 'ចែករំលែកតំណ',
            copyRespLink: 'ចម្លងតំណទម្រង់បែបបទរបស់ការឆ្លើយតប',
            shareTemplate: 'ចែករំលែកគំរូ',
            copyLink: 'ចម្លងតំណដើម្បីប្រើទម្រង់បែបបទនេះជាគំរូ',
            embedHTML: 'បង្កប់ HTML',
            copyHTML: 'ចម្លងកូដ HTML សម្រាប់បង្កប់ក្នុងទម្រង់បែបបទនេះ',
            createLink:
              'បង្កើតតំណខ្លីផ្លូវការ និងកូដ QR ជាមួយ [go.gov.kh](https://form.gov.kh) ហើយចែករំលែកវាតាមអ៊ីនធឺណិត',
            shareForm: 'ចែករំលែកទម្រង់បែបបទ',
          },
        },
        common: {
          mutation: {
            updateThank: 'ទំព័រអរគុណត្រូវបានធ្វើបច្ចុប្បន្នភាព',
            noFormIDProvided: 'No formId provided to useMutateCollaborators',
            collaborator:
              'មិនអាចបន្ថែម​ឬកែសម្រួលអ្នកសហការ សូមព្យយាមម្ដងទៀតឬផ្ទុកទំព័រឡើងវិញ',
            errorOccur: 'មានបញ្ហាកើតឡើង​ សូមផ្ទុកទំព័រឡើងវិញរួចព្យាយាមម្ដងទៀត',
            errorAdd: 'មានបញ្ហាក្នុងការបន្ថែមអ្នកសហការ',
            errorUpdate: 'មានបញ្ហាក្នុងការធ្វើបច្ចុប្បន្នភាពអ្នកសហការ',
            errorRemove: 'មានបញ្ហាក្នុងការដកចេញអ្នកសហការ',
            errorRemovingSelf: 'មានបញ្ហាដកអ្នកចេញពីទម្រង់បែបបទ',
            errorTransfer: 'មានបញ្ហាផ្ទេរកម្មសិទ្ធិទម្រង់បែបបទ',
            error: 'មានបញ្ហា',
            whitelisted: ' មិនស្ថិតក្នុងអ៊ីម៉ែលដែលអនុញ្ញាត',
            error422: 'មានបញ្ហា​ 422 កើតឡើង',
            updateNotExist: 'មិនមានអ្នកសហការ សូមផ្ទុកឡើងវិញរួចសាកល្បងម្ដងទៀត',
            updated:
              '{ permissionToUpdateEmail } ត្រូវបានធ្វើបច្ចុប្បន្នភាពទៅជា { newPermission }',
            role: '',
            added:
              '{ newPermissionEmail } ត្រូវបានបន្ថែមទៅជា { permissionRole }',
            remove: 'ត្រួវបានដកចេញពីអ្នកសហការ',
            owner: ' ក្លាយជាកម្មសិទ្ធិរបស់ទម្រង់បែបបទ',
            removeSelf: 'អ្នកបានដកខ្លួនឯងចេញពីអ្នកសហការ',
            formID: 'មិនមានលេខសម្គាល់ទម្រង់បែបបទ',
            editHeaderAndInstructions:
              'ផ្នែកខាងលើនិងការណែនាំរបស់ទម្រង់បែបបទត្រូវបានធ្វើបច្ចុប្បន្នភាព',
          },
          components: {
            PreviewFormBanner: {
              editForm: 'កែប្រែទម្រង់បែបបទ',
              formPreview: 'មើលទម្រង់បែបបទជាមុន',
              templatePreview: 'មើលគំរូជាមុន',
              back: 'ត្រលប់ក្រោយ',
              useThisTemplates: 'ប្រើប្រាស់ទម្រង់បែបបទនេះ',
            },
            AdminFormNavBar: {
              AdminFormNavBar: {
                create: 'បង្កើត',
                settings: 'ការកំណត់',
                results: 'លទ្ធផល',
                previewForm: 'មើលទម្រង់បែបបទ',
                shareFormLink: 'ចែករំលែកតំណភ្ជាប់',
                manageCollaborators: 'គ្រប់គ្រងអ្នកសហការ',
                saveAt: 'បានរក្សាទុកនៅ',
              },
              AdminFormNavbarBreadcrumbs: {
                allForms: 'ទម្រង់បែបបទទាំងអស់',
              },
            },
            DirtyModal: {
              noReturnToEditing: 'ទេ ត្រឡប់ទៅទំព័រកែសម្រួលវិញ',
            },
            collaboratorModal: {
              addCollab: 'បន្ថែមអ្នកសហការ ឬផ្ទេរកម្មសិទ្ធិទម្រង់បែបបទ',
              share:
                'ចែករំលែកសោសម្ងាត់របស់អ្នកជាមួយអ្នកប្រើប្រាស់ដែលត្រូវការចូលប្រើទិន្នន័យឆ្លើយតប',
              add: 'បន្ថែមអ្នកសហការ',
              transfer: 'ផ្ទេរកម្មសិទ្ធិទម្រង់បែបបទ',
              manageCollab: 'គ្រប់គ្រងអ្នកសហការ',
              collab: 'អ្នកសហការ',
              collabEmailRequired: 'ចាំបាច់ត្រូវមានអ៊ីម៉ែលរបស់អ្នកសហការ',
              validEmail: 'សូមបញ្ចូលអ៊ីម៉ែលដែលត្រឹមត្រូវ',
              existingCollab:
                'អ្នកប្រើប្រាស់នេះគឺជាអ្នកសហការដែលមានស្រាប់ សូមកែសម្រួលតួនាទីខាងក្រោម',
              cannotAddOwner:
                'អ្នកមិនអាចបន្ថែមម្ចាស់ទម្រង់បែបបទជាអ្នកសហការបានទេ',
              RemoveMyselfScreen: {
                removeMyselfAsCollaborator: 'ការដកខ្លួនក្នុងនាមជាអ្នកសហការ',
                youAreRemovingYourselfAsACollaboratorAndWillLoseAllAccessEtc:
                  'អ្នកកំពុងដកខ្លួនចេញពីអ្នកសហការម្នាក់ហើយនឹងបាត់បង់មធ្យោបាយគ្រប់គ្រងទម្រង់បែបបទនេះទាំងអស់។ សកម្មភាពនេះមិនអាចបកក្រោយបានទេ។',
                yesRemoveMyself: 'បាទ/ចាស, ដកខ្លួនចេញ',
                cancel: 'បោះបង់',
              },
              TransferOwnershipScreen: {
                transferFormOwnership: 'ការផ្ទេរកម្មសិទ្ធទម្រង់បែបបទ',
                youAreTransferringThisFormTo:
                  'អ្នកកំពុងផ្ទេរទម្រង់បែបបទនេះទៅកាន់',
                youWillLoseFormOwnershipAndTheRightToDeleteEtc:
                  '។ អ្នកនឹងបាត់បង់សិទ្ធជាម្ចាស់ និងសិទ្ធក្នុងការលុបទម្រង់បែបបទនេះ ប៉ុន្តែអ្នកនឹងនៅជាអ្នកកែសម្រួល។',
                yesTransferForm: 'បាទ/ចាស, ផ្ទេរទម្រង់បែបបទនេះ',
                cancel: 'បោះបង់',
              },
            },
          },
        },
        create: {
          builderAndDesign: {
            GetMutationMessage: {
              field: 'ចន្លោះ',
            },
            DeleteFieldModal: {
              useInLogic:
                'ចន្លោះនេះត្រូវបានប្រើនៅក្នុងលក្ខខណ្ឌទម្រង់បែបបទរបស់អ្នក ដូច្នេះការលុបវាអាចបណ្តាលឱ្យលក្ខខណ្ឌរបស់អ្នកឈប់ដំណើរការ។ តើអ្នកប្រាកដថាចង់លុបចន្លោះនេះ?',
              deletePrompt:
                'តើអ្នកប្រាកដថាចង់លុបចន្លោះនេះ? សកម្មភាពនេះមិនអាចត្រឡប់វិញបានទេ។',
              cancel: 'បោះបង់',
              deleteField: 'បាទ/ចាស​​ លុបចន្លោះនេះ',
              deleteTitle: 'លុបចន្លោះ',
              statement: 'កថាខណ្ឌ',
            },
            BuilderAndDesignContent: {
              webhooksAreEnabledOnThisFormEtc:
                'ទម្រង់បែបបទនេះបានបើកឲ្យដំណើរការ Webhooks ដូច្នេះសូមប្រាកដថា webhook server អាចធ្វើការប្រតិបត្តិការនៅពេលមានការផ្លាស់ប្តូរសំណួរណាមួយ។',
              BuilderAndDesignPlaceholder: {
                EmptyFormPlaceholder: {
                  tapHereToAddAField: 'ចុចទីនេះដើម្បីបន្ថែមចន្លោះ',
                  dragAFieldFromTheBuilderOnTheLeftToStart:
                    'អូសចន្លោះបំពេញមួយពី Builder នៅផ្នែកខាងឆ្វេងដើម្បីចាប់ផ្តើម',
                  dropYourFieldHere: 'ដាក់ចន្លោះបំពេញរបស់អ្នកនៅទីនេះ',
                },
              },
              FieldRow: {
                FieldRowContainer: {
                  theFieldTitleCouldNotBeDuplicatedEtc:
                    'សំណួរនេះ "{ fieldTitle }" មិនអាចចម្លងបានទេ ព្រោះទំហំឯកសារភ្ជាប់ **{ thisAttachmentSize } MB** លើសទំហំនៅសល់របស់ទម្រង់បែបបទនេះដែលមានត្រឺមតែ **{ remainingAvailableSize } MB**។',
                  thisFieldMayBeHiddenByYourFormLogic:
                    'សំណួរ​នេះ​ប្រហែល​ជា​ត្រូវ​បាន​លាក់​ទុក​ដោយ​លក្ខខណ្ឌ​នៃ​ទម្រង់​បែបបទ​របស់​អ្នក​',
                },
              },
              FormBuilder: {
                customiseThankYouPage: 'កែប្រែទំព័រ សូមអរគុណ',
              },
            },
            BuilderAndDesignDrawer: {
              EditFieldDrawer: {
                editFieldType: {
                  common: {
                    createField: 'បង្កើតចន្លោះបំពេញ',
                    saveField: 'រក្សាចន្លោះបំពេញទុក',
                    edit: 'កែប្រែ',
                    pleaseProvideAtLeast1Option: 'សូមបញូលជម្រើស១យ៉ាងតិច',
                    pleaseRemoveDuplicateOptions: 'សូមដកចេញនូវជម្រើសដែលស្ទួន',
                    pleaseRemoveDuplicateOthersOptions:
                      'សូមដកចេញនូវជម្រើសផ្សេងទៀតដែលស្ទួន',
                  },
                  EditAttachment: {
                    youHaveExceededYourFormsAttachmentSizeLimitOfMB:
                      'ទំហំឯកសារ​ភ្ជាប់នៃទម្រង់បែបបទរបស់អ្នកបានលើសចំនួនកំណត់ { maxTotalSizeMb } MB',
                    AttachmentStackedbar: {
                      used: 'បានប្រើ',
                    },
                  },
                  EditCheckbox: {
                    enterOneOptionPerLine:
                      'បញ្ចូលជម្រើសចំនួនមួយក្នុងមួយបន្ទាត់',
                    customMinValidationOptions: {
                      minimumCannotBeLargerThanMaximum:
                        'ចំនួនអប្បបរមាមិនអាចធំជាងចំនួនអតិបរមា​',
                      cannotBeMoreThanNumberOfOptions:
                        'មិនអាចច្រើនជាងចំនួននៃជម្រើស',
                      pleaseEnterSelectionLimits:
                        'សូមបញ្ចូលចំនួនកំណត់ក្នុងការជ្រើសរើស',
                      cannotBeLessThan1: 'មិនអាចតិចជាង 1',
                      cannotBeMoreThan10000: 'មិនអាចច្រើនជាង 10,000',
                    },
                  },
                  EditDate: {
                    customMinValidationOptions: {
                      youMustSpecifyAtLeastOneDate:
                        'អ្នកត្រូវតែកំណត់កាលបរិច្ឆេទមួយយ៉ាងតិច',
                      pleaseEnterAValidDate:
                        'សូមបញ្ចូលកាលបរិច្ឆេទឲ្យបានត្រឹមត្រូវ',
                      maxDateCannotBeLessThanMinDate:
                        'កាលបរិច្ឆេទចុងក្រោយមិនអាចតិចជាងកាលបរិច្ឆេទដំបូងឡើយ',
                    },
                  },
                  EditDecimal: {
                    maximumValueCannotBeSmallerThanTheMinimumValue:
                      'តម្លៃអតិបរមាមិនអាចតូចជាងតម្លៃអប្បបរមា',
                  },
                  EditDropdown: {
                    enterOneOptionPerLine:
                      'បញ្ចូលជម្រើសចំនួនមួយក្នុងមួយបន្ទាត់',
                  },
                  EditEmail: {
                    pleaseRemoveDuplicateEmailDomains:
                      'សូមដកចេញនូវដែនអ៊ីម៉ែលដែលស្ទួន',
                    pleaseEnterOnlyValidEmailDomainsStartWithAt:
                      'សូមបញ្ចូលដែនអ៊ីម៉ែលដែលផ្តើមដោយ @ ឲ្យបានត្រឹមត្រូវ',
                  },
                  EditImage: {
                    pleaseUploadAnImage: 'សូមបញ្ចូលរូបភាពមួយ',
                  },
                  EditLongText: {
                    pleaseEnterAValidNumber: 'សូមបញ្ចូលលេខដែលត្រឹមត្រូវ',
                    cannotBeLessThan1: 'មិនអាចតិចជាង 1',
                    cannotBeMoreThan10000: 'មិនអាចលើសពី 10000',
                  },
                  EditNumber: {
                    customMinValidationOptions: {
                      cannotBeLessThan1: 'មិនអាចតិចជាង 1',
                      cannotBeMoreThan10000: 'មិនអាចលើសពី 10000',
                    },
                  },
                  EditRadio: {
                    enterOneOptionPerLine:
                      'បញ្ចូលជម្រើសចំនួនមួយក្នុងមួយបន្ទាត់',
                  },
                  EditTable: {
                    minimumRowsMustBeGreaterThan0:
                      'ចំនួនជួរអប្បបរមាត្រូវតែច្រើនជាង 0',
                    maximumRowsMustBeGreaterThanMinimumRows:
                      'ចំនួនជួរអតិបរមាត្រូវតែថ្រើនជាងចំនួនជួរអប្បបរមា',
                  },
                  EditTableDropdown: {
                    options: 'ជម្រើស',
                    pleaseRemoveDuplicateOptions: 'សូមដកចេញនូវជម្រើសដែលស្ទួន',
                  },
                  EditTableDropdownInput: {
                    enterOneOptionPerLine:
                      'បញ្ចូលជម្រើសចំនួនមួយក្នុងមួយបន្ទាត់',
                  },
                  EditShortText: {
                    question: 'សំនួរ',
                    description: 'បរិយាយ',
                    required: 'ចាំបាច់',
                    numberOfCharacters: 'ចំនួនតួអក្សរ',
                    numberOfCharactersAllowed: 'ចំនួនតួអក្សរដែលអនុញ្ញាត',
                    enablePrefill: 'បើក pre-fill',
                    enablePrefillDescription:
                      'ប្រើលេខសម្គាល់ចន្លោះបំពេញ ដើម្បីបញ្ចូលចន្លោះនេះជាមុនសម្រាប់អ្នកប្រើប្រាស់តាមរយៈ URL [រៀនពីវា]',
                    fieldIDWillBeGeneratedAfterThisFieldIsSaved:
                      'លេខសម្គាល់របស់ចន្លោះបំពេញនឹងត្រូវបានបង្កើតបន្ទាប់ពីចន្លោះនេះត្រូវបានរក្សាទុក',
                    copyFieldIDValue: 'ចម្លងលេខសម្គាល់របស់ចន្លោះបំពេញ',
                    pleaseEnterAValidNumber: 'សូមបញ្ចូលលេខដែលត្រឹមត្រូវ',
                    cannotBeLessThan1: 'មិនអាចតិចជាង 1',
                    cannotBeMoreThan10000: 'មិនអាចលើសពី 10000',
                  },
                  EditFieldType: {
                    editAttachment: {
                      viewOurList: 'មើលអំពី[បញ្ជីពេញលេញរបស់យើង]',
                      acceptedFileType:
                        'អំពីប្រភេទឯកសារដែលអាចទទួលយកបាន។ ហើយសូមអាន[សំណួរដែលសួរញឹកញាប់]',
                      unacceptedFile: 'អំពីប្រភេទឯកសារដែលមិនអាចទទួលយកបាន។',
                    },
                    description: 'បរិយាយ',
                    question: 'សំនួរ',
                    options: 'ជម្រើស',
                    required: 'ចាំបាច់',
                    numValid: 'ផ្ទៀងផ្ទាត់ចំនួន',
                    minVal: 'តម្លៃអប្បបរមា',
                    maxVal: 'តម្លៃអតិបរមា',
                    numOfCharAllowed: 'ចំនួនតួអក្សរដែលអនុញ្ញាត',
                    numOfChar: 'ចំនួនតួអក្សរ',
                    numberOfDigits: 'ចំនួនខ្ទង់',
                    others: 'ផ្សេងទៀត',
                    selectLimit: 'កំណត់ការជ្រើសរើស',
                    customNum:
                      'ប្ដូរចំនួនជម្រើសដែលអ្នកប្រើប្រាស់ត្រូវអនុញ្ញាតឱ្យជ្រើសរើស',
                    min: 'អប្បបរមា',
                    max: 'អតិបរមា',
                    sectHeading: 'ផ្នែកខាងលើ',
                    paragraph: 'កថាខណ្ឌ',
                    numOfSteps: 'ចំនួនជំហាន',
                    shape: 'រូបរាង',
                    otpVerify: 'ការផ្ទៀងផ្ទាត់ OTP',
                    otpDescript:
                      'អ្នកឆ្លើយតបត្រូវធ្វើការផ្ទៀងផ្ទាត់ដោយបញ្ចូលលេខកូដដែលបានផ្ញើទៅពួកគេ',
                    restrictEmail: 'ដាក់កម្រិតអ៊ីម៉ែល domains',
                    restrictDescript: 'ធ្វើការផ្ទៀងផ្ទាត់លេខកូដ OTP ជាមុនសិន',
                    domainAllowed: 'Domains ត្រូវបានអនុញ្ញាត',
                    emailConfirm: 'ការបញ្ជាក់អ៊ីម៉ែល',
                    emailDescript:
                      'កែប្រែ​ការ​ទទួល​ស្គាល់​តាម​អ៊ីម៉ែល​ទៅ​អ្នក​ឆ្លើយ​តប',
                    subject: 'ប្រធានបទ',
                    senderName: 'ឈ្មោះអ្នកផ្ញើ',
                    content: 'មាតិការ',
                    includePDF: 'បញ្ចូលការឆ្លើយតបជា PDF',
                    allowInternationNum: 'អនុញ្ញាតឱ្យមានលេខអន្តរជាតិ',
                    otpDescription:
                      'អ្នកឆ្លើយតបត្រូវធ្វើការផ្ទៀងផ្ទាត់ដោយបញ្ចូលលេខកូដដែលបានផ្ញើទៅពួកគេ។ ប្រសិនបើអ្នកបានបន្ថែមអត្តសញ្ញាណប័ណ្ណ Twilio សូមសាកល្បងមុខងារផ្ទៀងផ្ទាត់ OTP នេះ ដើម្បីប្រាកដថាលិខិតសម្គាល់របស់អ្នកមានភាពត្រឹមត្',
                    dateValidate: 'សុពលភាពកាលបរិច្ឆេទ',
                    uploadImg: 'បានបង្ហោះរូបភាព',
                    minimumRows: 'ចំនួនជួរដេកអប្បបរមា',
                    allowToAddRows: 'អនុញ្ញាតឱ្យអ្នកឆ្លើយតបដាក់ជួរបន្ថែម',
                    maximumRowsAllowed: 'អនុញ្ញាតជួរដេកជាអតិបរមា',
                    maxSize: 'ទំហំអតិបរមានៃឯកសារភ្ជាប់នីមួយៗ',
                    optional: '(ប្រសិនបើមាន)',
                    defaultSender:
                      'ឈ្មោះលំនាំដើមរបស់អ្នកផ្ញើគឺជាឈ្មោះភ្នាក់ងាររបស់អ្នក',
                    defaultEmail: 'តួអ៊ីម៉ែលលំនាំដើម',
                    defaultEmailSubject: 'ប្រធានបទអ៊ីម៉ែលលំនាំដើម',
                    selectOption: 'សូមជ្រើសរើសជម្រើសណាមួយ',
                    pdfDescript:
                      'សម្រាប់ហេតុផលសុវត្ថិភាព ការឆ្លើយតបជា PDF មិនត្រូវបានរួមបញ្ចូលក្នុងការបញ្ជាក់អ៊ីម៉ែលសម្រាប់ទម្រង់ storage mode ទេ',
                    addColumn: 'បន្ថែមជួរឈរ',
                    addAnotherRow: 'បន្ថែមជួរដេកមួយទៀត',
                    col: 'ជួរឈរ ',
                    maxFileSize: 'ទំហំឯកសារអតិបរមា:',
                    chooseFile: 'ជ្រើសរើសឯកសារ',
                    dragDrop: 'ឬទាញឯកសារដាក់ទីនេះ',
                    dropFileHere: 'ទម្លាក់ឯកសារនៅទីនេះ',
                    delField: 'លុបចន្លោះ',
                    dupField: 'ចម្លងចន្លោះ',
                    yes: 'បាទ/ចាស',
                    no: 'ទេ',
                    verify: 'ផ្ទៀង​ផ្ទាត់',
                  },
                  SMSCountsModal: {
                    formProvides: 'ទម្រង់បែបបទផ្តល់ជូន',
                    freeOTP:
                      'ការផ្ទៀងផ្ទាត់ SMS OTP ដោយឥតគិតថ្លៃក្នុងមួយគណនី។ នៅពេលដែលឈានដល់ដែនកំណត់នេះ ការផ្ទៀងផ្ទាត់ SMS OTP នឹងត្រូវបានបិទដោយស្វ័យប្រវត្តិសម្រាប់ទម្រង់បែបបទទាំងអស់។ ទម្រង់បែបបទជាមួយ Twilio ដែលបានដំឡើងរួចហើយនឹងមិនរងផលប៉ះពាល់ទេ។',
                    ensureEnoughVerify:
                      'ប្រសិនបើអ្នកជាអ្នកសហការ សូមប្រាកដថាម្ចាស់ទម្រង់បែបបទមានការផ្ទៀងផ្ទាត់ឥតគិតថ្លៃគ្រប់គ្រាន់។ ប្រសិនបើអ្នកត្រូវការច្រើនជាង',
                    verifications: 'សូមផ្ទៀងផ្ទាត់',
                    twilioCredentials: 'សូមបញ្ចូលអត្តសញ្ញាណ Twilio របស់អ្នក',
                    understand: 'ខ្ញុំ​យល់​ហើយ',
                    verifySMS: 'ការផ្ទៀងផ្ទាត់​ SMS',
                  },
                },
              },

              DesignDrawer: {
                logo: 'ឡូហ្គោ',
                rDefault: 'លំនាំដើម',
                rNone: 'គ្មាន',
                uploadCustomLogo:
                  'បង្ហោះឡូហ្គោផ្ទាល់ខ្លួន (ជា jpg, png, ឬ gif)',
                uploadCustomLogoBanner:
                  'បង្ហោះផ្ទាំងបដាផ្ទាល់ខ្លួន (ជា jpg, png, ឬ gif)',
                uploadCustomLogoOrBanner:
                  'បង្ហោះឡូហ្គោឬផ្ទាំងបដាផ្ទាល់ខ្លួន (ជា jpg, png, ឬ gif)',
                themeColor: 'ពណ៌គោល',
                timeToComplete: 'រយៈពេលដែលត្រូវបំពេញទម្រង់បែបបទ (នាទី)',
                formInstruct: 'ការណែនាំរបស់ទម្រង់បែបបទ',
                saveDesign: 'រក្សាគំរូទុក',
                cancel: 'បោះបង់',
                editHeader: 'កែសម្រួលផ្នែកខាងលើ និងការណែនាំ',
                cannotBeLessThan1: 'មិនអាចតិចជាង 1',
                cannotBeMoreThan1000: 'មិនអាចលើសពី 1000',
                pleaseUploadABanner: 'សូមបញ្ចូលផ្ទាំងបដា',
                pleaseUploadALogo: 'សូមបញ្ចូលឡូហ្គោ',
                theBannerSizeMustBePxPx:
                  'ផ្ទាំងបដាត្រូវតែមានទំហំ 1200px x 300px',
              },

              FieldListDrawer: {
                fields: 'ចន្លោះបំពេញ',
                basic: 'មូលដ្ឋាន ',
                myInfo: 'MyInfo',
                myInfoFieldsAreNotAvailableInStorageModeForms:
                  'ចន្លោះ MyInfo មិនមាននៅក្នុងទម្រង់បែបបទជា Storage mode ទេ។',
                enableMyInfoInTheSettingsTabToAccessTheseFields:
                  'បើក MyInfo នៅក្នុងផ្ទាំងការកំណត់ ដើម្បីចូលទៅប្រើប្រាស់ចន្លោះទាំងនេះ។',
                learnMore: 'ស្វែង​យល់​បន្ថែម',
                personal: 'ផ្ទាល់ខ្លួន',
                contact: 'ទំនាក់ទំនង',
                particulars: 'ពិសេស',
                family: 'គ្រួសារ (អាពាហ៍ពិពាហ៍)',
              },
            },
          },
          common: {
            CreatePageSidebar: {
              addFields: 'បន្ថែមចន្លោះ',
              editHeaderAndInstructions: 'កែសម្រួលផ្នែកខាងលើ និងការណែនាំ',
              addLogic: 'បន្ថែមលក្ខខណ្ឌ',
              editThankYouPage: 'កែប្រែទំព័រ សូមអរគុណ',
              help: 'ជំនួយ',
            },
          },
          constants: {
            BasicFieldToDrawerMeta: {
              Image: 'រូបភាព',
              Paragraph: 'កថាខណ្ឌ',
              Heading: 'ផ្នែកខាងលើ',
              Attachment: 'ឯកសារ​ភ្ជាប់',
              Checkbox: 'ប្រអប់គ្រីស',
              Date: 'កាលបរិច្ឆេទ',
              Decimal: 'ចំនួនទសភាគ',
              Dropdown: 'ប្រអប់ជម្រើស',
              Email: 'អ៊ីម៉ែល',
              'Home number': 'លេខទូរសព្ទនៅផ្ទះ',
              'Long answer': 'ចម្លើយវែង',
              'Mobile number': 'លេខ​ទូរ​សព្ទ​',
              NRIC: 'អត្តសញ្ញាណប័ណ្ណ',
              Number: 'លេខ',
              Radio: 'ជម្រើស',
              Rating: 'ការវាយតម្លៃ',
              'Short answer': 'ចម្លើយខ្លី',
              Table: 'តារាង',
              UEN: 'UEN',
              'Yes/No': 'បាទ/ចាស ទេ ',
            },
            MyInfoAttribute: {
              Name: 'ឈ្មោះ',
              Gender: 'ភេទ',
              'Date of Birth': 'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
              Race: 'ជនជាតិ',
              Nationality: 'សញ្ជាតិ',
              'Birth Country': 'ប្រទេសកំណើត',
              'Residential Status': 'ស្ថានភាព​លំនៅ​ដ្ឋាន',
              Dialect: 'គ្រាមភាសា',
              'Housing Type': 'ប្រភេទលំនៅដ្ឋាន',
              'HDB Type': 'ប្រភេទ HDB',
              'Passport Number': 'លេខ​លិខិតឆ្លងដែន',
              'Passport Expiry Date': 'កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន',
              'Vehicle Number': 'លេខយានយន្ត',
              'Registered Address': 'អាសយដ្ឋានដែលបានចុះឈ្មោះ',
              'Mobile Number': 'លេខ​ទូរ​សព្ទ​',
              Occupation: 'មុខរបរ',
              'Name of Employer': 'ឈ្មោះនិយោជក',
              'Workpass Status': 'ស្ថានភាពការងារ',
              'Workpass Expiry Date': 'កាលបរិច្ឆេទផុតកំណត់នៃការងារ',
              'Marital Status': 'ស្ថានភាពអាពាហ៍ពិពាហ៍',
              'Country of Marriage': 'ប្រទេសដែលរៀបអាពាហ៍ពិពាហ៍',
              'Marriage Certificate Number': 'លេខលិខិតអាពាហ៍ពិពាហ៍',
              'Marriage Date': 'កាលបរិច្ឆេទរៀបការ',
              'Divorce Date': 'កាលបរិច្ឆេទលែងលះ',
            },
            constantsts: {
              yesNo: 'បាទ/ចាស ទេ',
            },
          },
          featureTour: {
            title: 'គន្លឹះជំនួយ',
            constants: {
              addFields: {
                title: 'បន្ថែមចន្លោះបំពេញ',
                content:
                  'បន្ថែមចន្លោះបំពេញនៅក្នុងទម្រង់បែបបទរបស់អ្នកដោយធ្វើការអូសវាដាក់',
              },
              editHeaderAndInstructions: {
                title: 'កែសម្រួលផ្នែកខាងលើ និងការណែនាំ',
                content:
                  'ប្ដូរឡូហ្គោ ពណ៌គោល ប្លង់ និងបន្ថែមការណែនាំលើទម្រង់បែបបទរបស់អ្នក។',
              },
              addLogic: {
                title: 'បន្ថែមលក្ខខណ្ឌ',
                content:
                  'សម្រេចថាតើចន្លោះ ឬផ្នែកណាមួយដែលអ្នកប្រើប្រាស់មើលឃើញដោយផ្អែកលើការបញ្ចូលរបស់ពួកគេ។',
              },
              editThankYouPage: {
                title: 'កែប្រែទំព័រ សូមអរគុណ',
                content:
                  'កែប្រែសារអរគុណ និងបន្ថែមការណែនាំបន្តទៅកាន់អ្នកប្រើប្រាស់បន្ទាប់ពីពួកគេដាក់ស្នើទម្រង់បែបបទរួច។',
              },
            },
            buttons: {
              next: 'បន្ទាប់',
              done: 'រួចរាល់',
            },
          },
          logic: {
            mutations: {
              updateLogic: 'លក្ខខណ្ឌត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ',
              deleteLogic: 'លក្ខខណ្ឌត្រូវបានលុបដោយជោគជ័យ',
              createLogic: 'លក្ខខណ្ឌត្រូវបានបង្កើតដោយជោគជ័យ',
            },
            components: {
              emptyLogic: {
                startLogic: 'ចាប់ផ្តើមបង្កើតលក្ខខណ្ឌឱ្យទម្រង់បែបបទរបស់អ្នក',
                showHide:
                  'បង្ហាញ ឬបិទចន្លោះបំពេញអាស្រ័យលើការបញ្ចូលរបស់អ្នកប្រើ ឬបិទមិនឱ្យដាក់ស្នើរទម្រង់បែបបទសម្រាប់ចម្លើយមិនត្រឹមត្រូវ។',
                learnLogic: ' រៀនធ្វើការជាមួយលក្ខខណ្ឌ',
                addLogic: 'បន្ថែមលក្ខខណ្ឌ',
                allowedField: 'បានអនុញ្ញាតចន្លោះបំពេញ',
              },
              logicContent: {
                logic: 'លក្ខខណ្ឌ',
                testForm:
                  'សូមធ្វើតេស្តទម្រង់បែបបទរបស់អ្នកឱ្យបានហ្មត់ចត់ ដើម្បីធានាថាលក្ខខណ្ឌដំណើរការដូចការរំពឹងទុក។',
                allowedField: 'បានអនុញ្ញាតចន្លោះបំពេញ',
                cancel: 'បោះបង់',
                thereAreErrorsInYourFormsLogicPleaseFixThemBeforeSharingYourForm:
                  'លក្ខខណ្ឌ​នៃ​ទម្រង់​បែបបទ​របស់​អ្នក​មាន​បញ្ហា​ ដូច្នេះ​សូម​ធ្វើ​ការ​កែ​សម្រួល​ទម្រង់​បែបបទ​របស់​អ្នក​មុន​ចែក​រំលែក​',
                EditLogicBlock: {
                  EditCondition: {
                    EditionConditionBlockDivider: {
                      and: 'និង',
                    },
                  },
                },
              },
              addConditionDivider: {
                addCond: 'បន្ថែមលក្ខខណ្ឌ',
              },
              editConditionBlock: {
                fieldIsInvalidOrUnableToAcceptLogic:
                  'សំណួរមិនត្រឹមត្រូវឬមិនអាចដាក់លក្ខខណ្ឌបានទេ',
                if: 'ប្រសិនបើ',
                is: 'គឺ',
                logicCriteria: '​លក្ខណៈ​វិនិច្ឆ័យនៃតក្ក',
                others: 'ផ្សេងទៀត',
                pleaseEnterAValidDecimal: 'សូមបញ្ចូលចំនួនទសភាគដែលត្រឹមត្រូវ',
                pleaseEnterLogicCriteria: 'សូម​បញ្ចូល​លក្ខណៈ​វិនិច្ឆ័យនៃតក្ក​',
                pleaseSelectACondition: 'សូមជ្រើសរើស​លក្ខខណ្ឌ​មួយ​',
                pleaseSelectAQuestion: 'សូមជ្រើសរើសសំណួរចំនួនមួយ',
                removeLogicBlock: 'ដកប្លុកលក្ខខណ្ឌចេញ',
                selectAQuestion: 'ជ្រើសរើសសំណួរ',
                thisFieldWasDeletedPleaseSelectAnotherField:
                  'សូម​ជ្រើសរើស​សំណួរ​ផ្សេងព្រោះ​សំណួរ​នេះ​ត្រូវ​បាន​លុប​ចេញ​',
              },
              ThenShowBlock: {
                allFieldsWereDeletedPleaseSelectAtLeastOneField:
                  'សូម​ជ្រើសរើស​សំណួរ​យ៉ាង​តិច​មួយ​ព្រោះ​សំណួរ​ទាំង​អស់​ត្រូវ​បាន​លុប​ចេញ​',
                then: ' បន្ទាប់មក',
                beenRemoved: 'ត្រូវបានដកចេញពីលក្ខខណ្ឌរបស់អ្នក។',
                customMessage:
                  'កែសារដែលត្រូវបង្ហាញនៅពេលដែលការដាក់ស្នើត្រូវបានរារាំង',
                show: 'បង្ហាញ',
                showField: 'បង្ហាញចន្លោះបំពេញ',
                disableSubmission: 'បិទការដាក់ស្នើ',
                selectType: 'ជ្រើសរើសប្រភេទលទ្ធផល',
                logicType: 'សូមជ្រើសរើសប្រភេទលក្ខខណ្ឌ',
                pleaseEnterAMessageToDisplayWhenSubmissionIsPrevented:
                  'សូម​បញ្ចូល​សារ​ដើម្បី​បង្ហាញ​នៅ​ពេល​ការ​ដាក់​ស្នើ​ត្រូវ​បាន​រារាំង​',
                pleaseSelectFieldsToShowIfLogicCriteriaIsMet:
                  'សូម​ជ្រើសរើស​សំណួរ​ទាំងអស់​ដើម្បី​បង្ហាញ​ប្រសិនបើសំណួរ​ទាំង​នោះ​គោរព​ទៅ​តាម​លក្ខខណ្ឌទាំងអស់​​',
                pleaseSelectLogicType: 'សូម​ជ្រើសរើស​ប្រភេទលក្ខន្តិកៈ',
              },
              newLogicBlock: {
                addLogic: 'បន្ថែមលក្ខខណ្ឌ',
              },
              deleteLogicModal: {
                deleteLogic: 'លុបលក្ខខណ្ឌ',
                areYouSure:
                  'តើអ្នកប្រាកដថាចង់លុបលក្ខខណ្ឌនេះទេ? សកម្មភាពនេះមិនអាចត្រឡប់វិញបានទេ',
                yes: 'បាទ/ចាស,​​​​ លុបលក្ខខណ្ឌ',
                no: 'ទេ,​​​​​ កុំលុប',
              },
              activeLogicBlock: {
                saveChange: 'រក្សាទុកការផ្លាស់ប្ដូរ',
              },
              inactiveLogicBlock: {
                if: 'ប្រសិនបើ',
                and: 'និង',
                thenShow: 'បន្ទាប់មកបង្ហាញ',
                thenDisable: 'បន្ទាប់មកបិទការបញ្ជូន',
                logicDelete:
                  'ចន្លោះនេះត្រូវបានលុប​​និងដកចេញពីលក្ខខណ្ឌនៃទម្រង់បែបបទរបស់អ្នក',
                allFieldsWereDeletedPleaseSelectAtLeastOneField:
                  'សូម​ជ្រើសរើស​សំណួរ​យ៉ាង​តិច​មួយ​ព្រោះ​សំណួរ​ទាំង​អស់​ត្រូវ​បាន​លុប​ចេញ',
              },
            },
          },
          endPageDrawer: {
            editThank: 'កែប្រែទំព័រ សូមអរគុណ',
            cancel: 'បោះបង់',
            save: 'រក្សាទំព័រទុក',
            buttonRedirect: 'ប៊ូតុងប្តូរតំណ (redirect link)',
            buttonText: 'ប៊ូតុងអត្ថបទ',
            followInstruct: 'ការណែនាំបន្ត',
            title: 'ចំណងជើង',
            defaultLink: 'តំណរបស់ទម្រង់បែបបទដើម',
            reSubmit: 'បញ្ជូនការឆ្លើយតបម្ដងទៀត',
            pleaseEnterAValidURLStartWithHttpsOrHttp:
              'សូម​បញ្ចូល​URLដែលត្រឹមត្រូវ​ (ផ្តើម​ដោយ​ https:// ឬ http://)',
          },
        },
        preview: {
          PreviewFormProvider: {
            anErrorOccurredWhilstProcessingSubmission:
              'ដំណើរការសំណើរបស់អ្នកមានបញ្ហា។ សូមធ្វើការព្យាយាមម្តងទៀត។',
          },
        },
        settings: {
          mutation: {
            formOpenCloseOff: 'បិទមុខងារកំណត់ថ្ងៃបើកនិងបិទទម្រង់បែបបទ​',
            formOpenCloseOn: 'បើកមុខងារកំណត់ថ្ងៃបើកនិងបិទទម្រង់បែបបទ​',
          },
          components: {
            generalTabHeader: {
              header: 'ការកំណត់​ទូទៅ',
            },
            formLimitToggle: {
              responseLimit: 'កំណត់ចំនួនឆ្លើយតប',
              maxRes: 'ចំនួនអតិបរមានៃការឆ្លើយតបដែលអនុញ្ញាត',
              autoClosed:
                'ទម្រង់បែបបទរបស់អ្នកនឹងបិទដោយស្វ័យប្រវត្តិ នៅពេលដែលគ្រប់ចំនួនកំណត់។ បើក reCAPTCHA ដើម្បីការពារពីការទទួលសារឥតបានការ (Spam)។',
              mustGreater:
                'ចំនួនអតិបរមានៃការឆ្លើយតបត្រូវធំជាងចំនួននៃការឆ្លើយតបបច្ចុប្បន្ន',
            },
            formDateToggle: {
              openCloseForm: 'កំណត់ថ្ងៃបើកនិងបិទទម្រង់បែបបទ',
              openCloseFormDescription:
                'ទម្រង់បែបបទនឹងបើកឬបិទដោយស្វ័យប្រវត្តិទៅតាមកាលបរិច្ឆេទដែលបានកំណត់ ក្នុងកំឡុងពេលជាក់លាក់ណាមួយ',
              save: 'រក្សាទុក',
              openDate: 'ថ្ងៃបើក',
              closeDate: 'ថ្ងៃបិទ',
              changeDate: 'ផ្លាស់ប្ដូរថ្ងៃ',
              chooseDate: 'ជ្រើសរើសកាលបរិច្ឆេទបើក/បិទ',
              openTime: 'ម៉ោងបើក',
              closeTime: 'ម៉ោងបិទ',
            },
            formCustomisationSection: {
              mesClosedForm: 'កំណត់សារសម្រាប់បិទទម្រង់បែបបទ',
              mistake:
                'ប្រសិនបើអ្នកគិតថានេះកើតឡើងដោយកំហុសឆ្គងណាមួយ​ សូមទំនាក់ទំនង​ភ្នាក់ងារដែលផ្ដល់ទម្រង់បែបបទនេះដល់អ្នក',
            },
            formCaptchaToggle: {
              enableCaptcha: 'បើក reCAPTCHA',
              captchaDescript:
                'សម្រាប់អ្នកមិននិយាយភាសាអង់គ្លេស អាចនឹងមានការលំបាកក្នុងការយល់ពីការណែនាំអំពីការជ្រើសរើស reCAPTCHA',
            },
            formDetailsSection: {
              formName: 'ឈ្មោះទម្រង់បែបបទ',
            },
            emailFormSection: {
              emailSent: 'អ៊ីម៉ែលដែលការឆ្លើយតបនឹងត្រូវបានផ្ញើ',
              addRecep:
                'បន្ថែមយ៉ាងតិច **អ្នកទទួល 2 នាក់** ដើម្បីការពារការបាត់បង់ការឆ្លើយតប។ ស្វែងយល់បន្ថែមអំពី [របៀបការពារការបាត់បង់អ៊ីមែល]',
              sepEmail: 'ញែកអ៊ីម៉ែលដោយសញ្ញាក្បៀស',
            },
            FormLanguageSection: {
              english: 'អង់គ្លេស',
              khmer: 'ខ្មែរ',
              setTheLanguageOfTheForm: 'កំណត់ភាសានៃទម្រង់បែបបទ',
            },
            formScheduleToggle: {
              selectDateRange: 'ជ្រើសរើសចន្លោះកាលបរិច្ឆេទ',
              openTime: 'ម៉ោងបើក',
              closeTime: 'ម៉ោងបិទ',
              save: 'រក្សាទុក',
            },
            formStatusToggle: {
              yourForm: 'ទម្រង់បែបបទរបស់អ្នកត្រូវបាន',
              newRes: 'សម្រាប់ការឆ្លើយតបថ្មី',
              open: 'បើក',
              closed: 'បិទ',
              inlineMessage:
                'សូមបិទមុខងារ "កំណត់ថ្ងៃបើកនិងបិទទម្រង់បែបបទ" ដើម្បីបើក/បិទទម្រង់បែបបទដោយផ្ទាល់',
              thisFormCannotBeActivatedUntilAValidEServiceIDIsEnteredEtc:
                'ទម្រង់បែបបទនេះមិនអាចបើកបានឡើយលុះត្រាតែ e-service ID ដែលត្រឹមត្រូវត្រូវបានបញ្ចូលទៅក្នុងផ្នែកSingpass',
            },
            WebhooksSection: {
              enableRetries: 'បើកការព្យាយាម',
              description:
                'មុននឹងអាចបើកដំណើរការព្យាយាមឲ្យប្រកបទៅដោយសុវត្ថិភាព ប្រព័ន្ធរបស់អ្នកត្រូវតែបំពេញគ្រប់លក្ខខណ្ឌជាក់លាក់មួយចំនួនសិន។ [សិក្សាបន្ថែម]({guideWebhooks})',
            },
            webhooksUnsupportedMsg: {
              unavail: 'Webhooks មិនមាននៅក្នុង Email mode ទេ',
              webhookDescript:
                'Webhooks មានសារៈប្រយោជន៍សម្រាប់ភ្នាក់ងារដែលមានបំណងចង់បានទិន្នន័យឆ្លើយតបពីទម្រង់បែបបទ​ ផ្ញើដោយផ្ទាល់ទៅប្រព័ន្ធ IT ដែលមានស្រាប់។ មុខងារនេះអាចប្រើបានតែក្នុង storage mode ប៉ុណ្ណោះ',
              readMore: 'អានបន្ថែមអំពី webhooks',
            },
            webhookURLInput: {
              endpoint: 'Endpoint URL',
              forDev:
                'សម្រាប់អ្នកអភិវឌ្ឍន៍កម្មវិធីនិងមន្ត្រីព័ត៌មានវិទ្យាប្រើប្រាស់។ យើងនឹងធ្វើការ​ POST ការឆ្លើយតបរបស់ទម្រង់បែបបទភ្លាមៗក្នុងទម្រង់ជាកូដនីយកម្មទៅកាន់ HTTPS endpoint ដែលបានកំណត់នៅទីនេះ។',

              pleaseEnterAValidURL:
                'សូមបញ្ចូល URL ដែលត្រឹមត្រូវ (ចាប់ផ្តើមដោយ https://)',
            },
          },
          mutations: {
            useMutateFormSettings: {
              mutateFormLanguage: {
                formLanguageHasBeenUpdatedTo:
                  'ភាសានៃទម្រង់បែបបទត្រូវបានប្តូរទៅជា ភាសា{ newFormLang }',
              },
              validationFailed: 'ការបញ្ជាក់ពីភាពត្រឹមត្រូវមិនជោគជ័យ',
            },
          },
          settingsPage: {
            settingGeneral: 'ទូទៅ',
            settingSingpass: 'Singpass',
            settingTwilio: 'Twilio credentials',
            settingWebhooks: ' Webhooks',
          },
        },
        responses: {
          feedBackPage: {
            emptyFeedback: {
              emptyFeedback: {
                h2: 'អ្នកមិនទាន់មានមតិកែលម្អនៅឡើយទេ',
                trySendingOutYourFormLink: 'សាកល្បងផ្ញើទម្រង់បែបបទរបស់អ្នក!',
              },
            },
            emptyResponses: {
              emptyResponses: {
                h2: 'អ្នកមិនទាន់មានការឆ្លើយតបនៅឡើយទេ',
                trySendingOutYourFormLink: 'សាកល្បងផ្ញើទម្រង់បែបបទរបស់អ្នក!',
              },
            },
            feedbackPage: {
              average: 'ពិន្ទុវាយតម្លៃមធ្យម',
              feedbackToDate: 'មតិកែលម្អ',
            },
          },
          FeedbackPage: {
            FeedbackTable: {
              date: 'កាលបរិច្ឆេទ',
              feedback: 'មតិកែលម្អ',
              rating: 'ការវាយតម្លែ',
            },
          },
          IndividualResponsePage: {
            downloadNumAttachmentsAsZip:
              'ទាញយកឯកសារភ្ជាប់ { attachmentDownloadUrlsSize } ជា .zip',
          },
          responsePage: {
            secretKeyVerification: {
              enterOrUploadSecretKey: 'បញ្ចូល​ ឬបង្ហោះសោសម្ងាត់​',
              secretKeyDownload:
                'សោសម្ងាត់របស់អ្នកត្រូវបានទាញយក​នៅពេលអ្នកបង្កើតទម្រង់បែបបទ',
              unlockResponse: 'ដោះសោការឆ្លើយតប',
              cantFind: 'រកមិនឃើញសោសម្ងាត់?',
              responseToDate: 'ការឆ្លើយតប',
            },
          },
          ResponsesPage: {
            storage: {
              UnlockedResponses: {
                ResponsesTable: {
                  responseId: 'លេខសម្គាល់ការឆ្លើយតប',
                  timestamp: 'ពេលវេលា',
                },
                SubmissionSearchbar: {
                  searchByResponseId: 'ស្វែងរកតាមលេខសម្គាល់ការឆ្លើយតប',
                },
                UnlockedResponses: {
                  numberResultsFound: 'លទ្ធផល',
                },
              },
            },
          },
          SummaryPage: {
            common: {
              EmptySummarizableResponses: {
                yourFormDoesntHaveAnyFieldThatCanBeSummarized:
                  'ទម្រង់បែបបទរបស់អ្នកពុំមានប្រភេទសំណួរដែលអាចសង្ខេបបានទេ',
                tryAddingNewFieldsThatCanBeSummarized:
                  'សាកល្បងបន្ថែមចន្លោះដែលអាចសង្ខេបបានដូចជា',
                summarizableFields:
                  'កថាខណ្ឌ, ជម្រើស, ប្រអប់ជម្រើស, ប្រអប់ធីក, បាទ/ចាស ទេ, ការវាយតម្លៃ',
              },
            },
            components: {
              common: {
                EmptyChartSummary: {
                  nonSummarizableMessage:
                    'សំណួរនេះមិនអាចបង្ហាញបានព្រោះជម្រើសត្រូវបានកែប្រែឬមិនត្រូវបានបញូល',
                },
              },
            },
          },
          components: {
            formResultsNavbar: {
              formResultsNavbar: {
                response: 'ការឆ្លើយតប',
                feedback: 'មតិកែលម្អ',
                summary: 'សង្ខេប',
              },
              summaryChartSidebar: {
                pieChart: 'ក្រាបផ្លិត',
                verticalChart: 'ក្រាបសរសរ​ បញ្ឈរ',
                horizontalChart: 'ក្រាបសរសរ​ ផ្តេក',
                lineChart: 'ក្រាបខ្សែ',
                areaChart: 'ក្រាបតាងតំបន់',
              },
            },
            individualResponseStatusSection: {
              status: 'ស្ថានភាព៖',
              pending: 'កំពុងរង់ចាំ',
              deprecated: 'ស្ទួន',
              rejected: 'បដិសេធ',
              approved: 'អនុម័ត',
              loading: 'កំពុងទាញយក...',
            },
          },
        },
        template: {
          UseTemplateModal: {
            UseTemplateWizardProvider: {
              title: '[គំរូ] {formTitle}',
              modalHeader: 'ចម្លងទម្រង់បែបបទ',
            },
          },
        },
      },
      login: {
        LoginPage: {
          slogan: 'បង្កើតទម្រង់បែបបទដែលមានសុវត្ថិភាពខ្ពស់ក្នុងរយៈពេលដ៏ខ្លី',
        },
        components: {
          LoginForm: {
            onlyAvailableForPublicOfficers:
              'ចូលទៅកាន់គណនី .gov.kh ឬអាសយដ្ឋានអ៊ីម៉ែលដែលត្រូវបានអនុញ្ញាត',
            emailEmptyErrorMsg: 'សូមដាក់អាសយដ្ឋានអ៊ីម៉ែល',
            login: 'ចូលគណនី',
            failedToSendLoginOTPEtc:
              'ការបញ្ចូន OTP មិនជោគជ័យ។ សូមព្យាយាមម្តងទៀតហើយទាក់ទងមកកាន់ក្រុមការងារប្រសិនបើបញ្ហានេះនៅតែកើតមានឡើង។',
            haveAQuestion: 'មានសំនួរ?',
            thereWasAProblemWithYourInternetConnectionEtc:
              'សាវាអ៊ីនធឺណិតរបស់អ្នកមានការរអាក់រអួល។ សូមពិនិត្យមើលសាវារបស់អ្នក រួចព្យាយាមម្តងទៀត។',
            thisIsNotAWhitelistedPublicServiceEmailDomainEtc:
              'អ៊ីម៉ែលនេះមិនមែនជាដែនអ៊ីម៉ែលរាជរដ្ឋាភិបាលទេ។ សូមចូលដោយប្រើអាសយដ្ឋានអ៊ីម៉ែលផ្លូវការរបស់រាជរដ្ឋាភិបាល។',
            validationFailed: 'ការបញ្ជាក់ពីភាពត្រឹមត្រូវមិនជោគជ័យ',
          },
          OtpForm: {
            otpSentTo: 'សូមបញ្ចូលពាក្យសម្ងាត់ផ្ញើទៅ',
            signIn: 'ចូលគណនី',
            otpIsRequired: 'ត្រូវមានពាក្យសម្ងាត់',
            onlyNumber: 'អនុញាតតែលេខប៉ុណ្ណោះ',
            otp: 'សូមបញ្ចូលពាក្យសម្ងាត់៦ខ្ទង់',
          },
        },
      },
      workspace: {
        mutation: {
          delete: 'ទម្រង់បែបបទត្រួវបានលុបដោយជោគជ័យ',
        },
        components: {
          DeleteFormModal: {
            deleteForm: 'លុប​ទម្រង់បែបបទ',
            loseResponseAndFeedback:
              'អ្នកនឹងបាត់បង់ការឆ្លើយតបនិងមតិកែលម្អទាំងអស់នៃទម្រង់បែបបទខាងក្រោម។ តើអ្នកប្រាកដថាចង់លុបទម្រង់បែបបទនេះ?',
            yesDelete: 'បាទ/ចាស​ លុបទម្រង់បែបបទ',
            cancel: 'បោះបង់',
          },
          AdminSwitchEnvMessage: {
            welcome: 'សូមស្វាគមន៍មកកាន់ FormKH ថ្មី! អ្នកនៅតែអាច',
            click: 'ចុចដើម្បីប្តូរទៅ FormKH ដើម',
            avail: 'ដែលអាចប្រើបានរហូតដល់',
            switch: 'ប្តូរទៅ FormKH ដើម',
          },
          DuplicateFormModal: {
            DupeFormWizardProvider: {
              duplicate: 'ចម្លងទម្រង់បែបបទ',
            },
          },
          CreateFormModal: {
            CreateFormModalContent: {
              CreateFormDetailsScreen: {
                formName: 'ឈ្មោះទម្រង់បែបបទ',
                itIsAdviseToUseShortSuccinctFormName:
                  'សូមដាក់ឈ្មោះទម្រង់បែបបទឱ្យខ្លីនិងងាយស្រួលយល់',
                howDoYouWantToReceiveFormResponse:
                  'តើអ្នកចង់បានលទ្ធផលឆ្លើយតបដោយរបៀបណា?',
                emailWhereResponsesWillBeSent: 'ផ្ញើលទ្ធផលឆ្លើយតបទៅអ៊ីម៉ែល',
                nextStep: 'ជំហាន​បន្ទាប់',
              },
              FormResponseOptions: {
                storageMode: {
                  recommended: 'ណែនាំសម្រាប់អ្នក',
                  name: 'Storage Mode',
                  viewOrDownloadResponsesInFormKH:
                    'មើល ឬទាញយកលទ្ធផលឆ្លើយតបនៅក្នុង FormKH',
                  attachmentsUpTo20MBPerForm:
                    'ឯកសារភ្ជាប់៖ រហូតដល់ 20MB ក្នុងមួយទម្រង់បែបបទ',
                  upToRestrictedAndSensitiveNormalData:
                    'បានទាំងទិន្នន័យមានដាក់កំហិត និងទិន្នន័យរសើប (ធម្មតា)',
                  supportsWebhooksForResponses:
                    'អាចឆ្លើយតបក្នុងទម្រង់បែបបទជា​ webhooks ',
                },
                emailMode: {
                  name: 'Email Mode',
                  receiveResponsesInYourBox:
                    'ទទួលលទ្ធផលឆ្លើយតបនៅក្នុងប្រអប់សាររបស់អ្នក។',
                  attachmentsUpTo7MBPerForm:
                    'ឯកសារភ្ជាប់៖ រហូតដល់ 7MB ក្នុងមួយទម្រង់បែបបទ',
                  upToRestrictedAndSensitiveHighData:
                    'បានទាំងទិន្នន័យមានដាក់កំហិត និងទិន្នន័យរសើប (ខ្ពស់)',
                  supportsMyInfoFields: 'គាំទ្រចន្លោះ MyInfo',
                },
              },
              SaveSecretKeyScreen: {
                description: {
                  downloadSecretKeyToProceed:
                    'ទាញយកសោសម្ងាត់​ ​(Secret Key) ដើម្បីបន្ត',
                  youllNeedItEveryTimeTillLoseItWithComma:
                    'អ្នកនឹងត្រូវការវារាល់ពេលដែលអ្នកចូលទៅកាន់លទ្ធផលឆ្លើយតបរបស់ទម្រង់បែបបទនេះ។ ប្រសិនបើអ្នកបាត់សោសម្ងាត់នេះ',
                  allResponsesWillBePermanentlyLost:
                    'លទ្ធផលឆ្លើយតបទាំងអស់នឹងបាត់បង់ជាអចិន្ត្រៃយ៍',
                  period: '។',
                  copy: 'ចម្លងសោសម្ងាត់',
                  copied: 'បានចម្លងរួចរាល់',
                },
                downloadKey: 'ទាញយកសោសម្ងាត់',
                ifILoseMySecretKeyCheckBox:
                  'ប្រសិនបើខ្ញុំបាត់បង់សោសម្ងាត់ (Secret Key) របស់ខ្ញុំ ខ្ញុំមិនអាចដំណើរការទម្រង់បែបបទ ឬចូលទៅកាន់លទ្ធផលឆ្លើយតបណាមួយបានទេ។',
                iHaveSavedMyScretKeySafely:
                  'ខ្ញុំបានរក្សាទុកសោសម្ងាត់ (Secret Key) របស់ខ្ញុំដោយសុវត្ថិភាព',
              },
            },
            CreateFormWizardProvider: {
              setupYourForm: 'រៀបចំទម្រង់បែបបទរបស់អ្នក',
            },
          },
          EmptyWorkspace: {
            youDontHaveNoFormsYet: 'អ្នកមិនទាន់មានទម្រង់បែបបទនៅឡើយទេ',
            getStartedByCreatingANewFormOrUseTemplate:
              'ចាប់ផ្ដើមដោយបង្កើតទម្រង់បែបបទថ្មី ឬ​ ប្រើប្រាស់ទម្រង់បែបបទគំរូដែលមានស្រាប់',
            createForm: 'បង្កើតទម្រង់បែបបទ',
          },
          WorkSpaceHeader: {
            searchByTitle: 'ស្វែងរកតាមចំណងជើង',
            createForm: 'បង្កើតទម្រង់បែបបទ',
            allForms: 'ទម្រង់បែបបទទាំងអស់',
            hasActiveSearchOrFilter:
              'កំពុងបង្ហាញ { displayedFormsCount } នៃ { totalFormsCount } ទម្រង់បែបបទ',
          },
          WorkSpaceFormRow: {
            FormStatusLabel: {
              open: 'បើក',
              closed: 'បិទ',
            },
            RowActions: {
              RowActionsDrawer: {
                edit: 'កែប្រែ',
                preview: 'មើលជាមុន',
                duplicate: 'ចម្លង',
                shareForm: 'ចែករំលែកទម្រង់បែបបទ',
                manageFormAdmins: 'គ្រប់គ្រងទម្រង់បែបបទរបស់ Admins',
                delete: 'លុប',
              },
            },
            WorkspaceFormRow: {
              edited: 'កែរួច',
              WorkspaceFormRowsFilterNone: {
                noFormFound: 'រកមិនឃើញទម្រង់បែបបទនេះទេ',
                tryAnotherSearchOrRemoveFilters:
                  'សាកល្បងស្វែងរកតម្រងឬពាក្យគន្លឹះផ្សេងទៀត ឬដកចេញតម្រងឬពាក្យគន្លឹះទាំងនោះ',
              },
            },
          },
          WorkSpaceSearchBar: {
            filter: 'តម្រង',
          },
        },
        AdminNavBar: {
          featureRequest: 'សំណើរលើមុខងារថ្មី',
          help: 'ជំនួយ',
          whatNew: 'មុខងារថ្មី',
        },
        types: {
          allForms: 'ទម្រង់បែបបទទាំងអស់',
          openForms: 'ទម្រង់បែបបទដែលបានបើក',
          closedForms: 'ទម្រង់បែបបទដែលបានបិទ',
        },
      },
      FormTemplates: {
        FormTemplatesHeader: {
          templates: 'ទម្រង់បែបបទគំរូ',
          allFormTemplates: 'ទម្រង់បែបបទគំរូទាំងអស់',
          dashboard: 'ផ្ទាំងគ្រប់គ្រង',
          searchByTitle: 'ស្វែងរកតាមចំណងជើង',
          hasActiveSearchOrFilter:
            'កំពុងបង្ហាញ { displayedFormTemplatesCount } នៃ { totalFormTemplatesCount } ទម្រង់បែបបទគំរូ',
        },
        EmptyFormTemplatesDashboard: {
          dontHaveNoTemplatesYet: 'មិនទាន់មានទម្រង់បែបបទគំរូនៅឡើយទេ',
          noTemplatesAvailableSubTittle:
            'ចាប់ផ្តើមបង្កើតទម្រង់បែបបទថ្មី​ ដោយចូលទៅកាន់ផ្ទាំងគ្រប់គ្រង',
          getStartedByCreatingANewForm: 'ចាប់ផ្ដើមដោយបង្កើតទម្រង់បែបបទថ្មី',
          createForm: 'បង្កើតទម្រង់បែបបទ',
          backToDashboard: 'ផ្ទាំងគ្រប់គ្រង',
        },
      },
    },
    components: {
      Checkbox: {
        other: 'ផ្សេងទៀត',
      },
      Field: {
        rating: {
          rating: {
            selected: 'ត្រូវបានជ្រើសរើស',
          },
        },
        attachment: {
          attachment: {
            extension: 'ប្រភេទឯកសារ *',
            notAllowed: 'មិនត្រូវបានអនុញាត',
            singleFile: 'អ្នកអាចបញ្ចូលឯកសារបានតែមួយប៉ុណ្ណោះក្នុងចន្លោះនេះ',
            followingFile: 'ប្រភេទឯកសារ',
            extensionZip: 'ដែលមានក្នុងឯកសារ zip របស់អ្នក',
            notValid: 'ដែលមិនត្រូវបានអនុញាតមានដូចតទៅ៖​',
            errorParsing: 'បញ្ហាបានកើតឡើងខណៈពេលកំពុងញែកឯកសារ zip របស់អ្នក',
            exceedLimit: 'ទំហំឯកសារលើសទំហំកំណត់ សូមបញ្ចូលឯកសារដែលមានទំហំតូចជាង',
          },
        },
      },
      FormControl: {
        FormLabel: {
          optional: '(ប្រសិនបើមាន)',
        },
      },
      Dropdown: {
        components: {
          SelectMenu: {
            TextSelectedValidation: {
              maximum: 'អតិបរមា',
              minimum: 'អប្បបរមា',
              exact: 'កំណត់ត្រឹម',
            },
            DateSelectedValidation: {
              noPast: 'មិនអនុញ្ញាតកាលបរិច្ឆេទពីអតីតកាល',
              noFuture: 'មិនអនុញ្ញាតកាលបរិច្ឆេទអនាគតកាល',
              custom: 'ចន្លោះកាលបរិច្ឆេទផ្ទាល់ខ្លួន',
            },
            RatingShape: {
              heart: 'បេះដូង',
              star: 'ផ្កាយ',
            },
            YesNo: {
              yes: 'បាទ/ចាស',
              no: 'ទេ',
            },
          },
        },
        MultiSelect: {
          clearSelection: 'បោះបង់ការជ្រើសរើស',
          noMatchingResults: 'ពុំមានលទ្ធផលដែលដូច',
        },
        SingleSelect: {
          clearSelection: 'បោះបង់ការជ្រើសរើស',
          noMatchingResults: 'ពុំមានលទ្ធផលដែលដូច',
        },
      },
    },
    shared: {
      types: {
        form: {
          formLogic: {
            LogicConditionState: {
              equal: 'ស្មើ​',
              lte: 'តិចជាងឬស្មើ​',
              gte: 'ច្រើនជាងឬស្មើ​',
              either: 'ទាំង',
            },
          },
        },
      },
    },
  },
}
