import { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { createDecimalValidationRules } from '~utils/fieldValidation'
import NumberInput from '~components/NumberInput'

import { usePublicLanguageStore } from '../../../features/public-form/usePublicLanguageStore'
import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { DecimalFieldSchema, SingleAnswerFieldInput } from '../types'

export interface DecimalFieldProps extends BaseFieldProps {
  schema: DecimalFieldSchema
}

/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
export const DecimalField = ({ schema }: DecimalFieldProps): JSX.Element => {
  const { publicI18n, isPublicView } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        publicI18n: state.publicI18n,
        isPublicView: state.isPublicView,
      }),
      [],
    ),
  )

  const validationRules = useMemo(
    () => createDecimalValidationRules(schema, isPublicView, publicI18n),
    [schema, isPublicView, publicI18n],
  )

  const { control } = useFormContext<SingleAnswerFieldInput>()

  return (
    <FieldContainer schema={schema}>
      <Controller
        control={control}
        rules={validationRules}
        name={schema._id}
        defaultValue=""
        render={({ field }) => (
          <NumberInput
            inputMode="decimal"
            aria-label={`${schema.questionNumber}. ${schema.title}`}
            allowMouseWheel
            preventDefaultOnEnter
            {...field}
          />
        )}
      />
    </FieldContainer>
  )
}
