import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormControl, FormLabel, Skeleton, Stack } from '@chakra-ui/react'
import { get } from 'lodash'

import { FormLanguage } from '~shared/types'

import { SingleSelect } from '~components/Dropdown/SingleSelect'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'

import { useMutateFormSettings } from '~features/admin-form/settings/mutations'
import { useAdminFormSettings } from '~features/admin-form/settings/queries'
import {
  setLangSelector,
  usePublicLanguageStore,
} from '~features/public-form/usePublicLanguageStore'

interface FormLanguageSelectProps {
  initialLang: FormLanguage
}

const FormLanguageSelect = ({ initialLang }: FormLanguageSelectProps) => {
  const { t } = useTranslation()

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lang: initialLang,
    },
  })

  const { setLang } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        setLang: setLangSelector(state),
      }),
      [],
    ),
  )

  const { mutateFormLanguage } = useMutateFormSettings()

  const handleLanguageChange = useCallback(
    (lang) => {
      if (lang === initialLang) return

      return mutateFormLanguage.mutate(lang, {
        onSuccess: () => {
          setLang(lang)
          reset({ lang })
        },
        onError: () => reset(),
      })
    },
    [initialLang, mutateFormLanguage, reset, setLang],
  )

  return (
    <FormControl>
      <FormLabel>
        {t(
          'features.adminForm.settings.components.FormLanguageSection.setTheLanguageOfTheForm',
        )}
      </FormLabel>
      <Controller
        control={control}
        name="lang"
        render={({ field }) => (
          <SingleSelect
            isClearable={false}
            isSearchable={false}
            items={Object.values(FormLanguage)}
            {...field}
            onChange={handleLanguageChange}
            shouldTranslate="formLanguage"
          />
        )}
      />
      <FormErrorMessage>{get(errors, 'lang.message')}</FormErrorMessage>
    </FormControl>
  )
}

export const FormLanguageSection = () => {
  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  return (
    <Skeleton isLoaded={!isLoadingSettings && !!settings}>
      <Stack spacing="2rem">
        {settings && <FormLanguageSelect initialLang={settings.lang} />}
      </Stack>
    </Skeleton>
  )
}
