export const BxsHorizontalChart = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#014F99"
        d="M2.55 21.375c-.33 0-.607-.114-.834-.342a1.141 1.141 0 01-.341-.837c0-.33.114-.608.34-.833a1.14 1.14 0 01.835-.338h18.9c.327 0 .609.114.845.342.237.228.355.507.355.837 0 .33-.118.609-.355.834a1.184 1.184 0 01-.845.337H2.55zm1.488-4.225c-.468 0-.863-.163-1.183-.488a1.63 1.63 0 01-.48-1.187V12.05c0-.472.162-.873.487-1.204.324-.33.718-.496 1.182-.496.464 0 .86.165 1.188.496.329.33.493.732.493 1.204v3.425c0 .465-.164.86-.492 1.187a1.633 1.633 0 01-1.195.488zm5.284 0a1.61 1.61 0 01-1.186-.488 1.619 1.619 0 01-.486-1.187V7.05c0-.472.163-.874.49-1.204.326-.33.722-.496 1.188-.496.466 0 .861.165 1.186.496.324.33.486.732.486 1.204v8.425c0 .465-.163.86-.49 1.187a1.62 1.62 0 01-1.188.488zm5.3 0a1.61 1.61 0 01-1.185-.488 1.619 1.619 0 01-.487-1.187V10.05c0-.472.164-.874.492-1.204a1.62 1.62 0 011.195-.496c.468 0 .867.165 1.195.496.329.33.493.732.493 1.204v5.425c0 .465-.166.86-.497 1.187a1.656 1.656 0 01-1.206.488zm5.325 0a1.61 1.61 0 01-1.185-.488 1.619 1.619 0 01-.487-1.187V4.05c0-.472.164-.874.492-1.204a1.62 1.62 0 011.195-.496c.468 0 .867.165 1.195.496.329.33.493.732.493 1.204v11.425c0 .465-.166.86-.497 1.187a1.656 1.656 0 01-1.206.488z"
      ></path>
    </svg>
  )
}
