import { PropsWithChildren } from 'react'
import { CellProps } from 'react-table'
import { Box, Flex } from '@chakra-ui/react'

import { StorageModeSubmissionMetadata, SubmissionStatus } from '~shared/types'

export const SubmissionStatusCell = (
  cellProps: PropsWithChildren<
    CellProps<StorageModeSubmissionMetadata, string>
  >,
) => {
  if (cellProps.cell.value === SubmissionStatus.Approved)
    return (
      <Flex>
        <Box
          display="flex"
          alignItems="center"
          justifyItems="right"
          color="primary.500"
          textAlign="center"
        >
          <Box p="5px 10px" borderRadius={'6px'} bg="primary.200">
            {cellProps.cell.value}
          </Box>
        </Box>
      </Flex>
    )
  else if (cellProps.cell.value === SubmissionStatus.Rejected)
    return (
      <Flex>
        <Box
          display="flex"
          alignItems="center"
          justifyItems="right"
          color="danger.500"
        >
          <Box p="5px 10px" borderRadius={'6px'} bg="danger.200">
            {cellProps.cell.value}
          </Box>
        </Box>
      </Flex>
    )

  return (
    <Flex>
      <Box
        display="flex"
        alignItems="center"
        justifyItems="right"
        color="secondary.500"
      >
        <Box p="5px 10px" borderRadius={'6px'} bg="secondary.200">
          {cellProps.cell.value}
        </Box>
      </Box>
    </Flex>
  )
}
