import { TFunction } from 'react-i18next'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

import { FormLanguage } from '~shared/types'

import i18n from '~/i18n/i18n'

export type PublicLanguageStore = {
  lang: FormLanguage
  setLang: (lang: FormLanguage) => void
  publicI18n: TFunction<'translation', undefined>
  isPublicView: boolean
  setIsPublicView: (isPublicView: boolean) => void
}

export const usePublicLanguageStore = create<PublicLanguageStore>()(
  devtools((set, get) => ({
    lang: FormLanguage.Khmer,
    setLang: (lang) => {
      set({ lang, publicI18n: i18n.getFixedT(lang) })
    },
    publicI18n: i18n.getFixedT(FormLanguage.Khmer),
    isPublicView: false,
    setIsPublicView: (isPublicView) => {
      set({ isPublicView })
    },
  })),
)

export const langSelector = (
  state: PublicLanguageStore,
): PublicLanguageStore['lang'] => state.lang

export const setLangSelector = (
  state: PublicLanguageStore,
): PublicLanguageStore['setLang'] => state.setLang

export const publicI18nSelector = (
  state: PublicLanguageStore,
): PublicLanguageStore['publicI18n'] => state.publicI18n

export const isPublicViewSelector = (
  state: PublicLanguageStore,
): PublicLanguageStore['isPublicView'] => state.isPublicView

export const setIsPublicViewSelector = (
  state: PublicLanguageStore,
): PublicLanguageStore['setIsPublicView'] => state.setIsPublicView
