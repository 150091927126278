import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/react'

import { SummaryResponseBase } from '~shared/types'

interface EmptyChartSummaryProps {
  data: SummaryResponseBase
}

export const EmptyChartSummary = ({ data }: EmptyChartSummaryProps) => {
  const { i18n } = useTranslation()

  return (
    <Box
      bg={'white'}
      w={{ base: '270px', sm: '390px', md: '655px', lg: '800px' }}
      h={{ base: '200px', sm: '175px', md: '150px', lg: '150px' }}
      mt="15px !important"
      px={{ base: '10px', sm: '20px', md: '30px', lg: '50px' }}
      py={{ base: '10px', sm: '20px', md: '30px', lg: '30px' }}
      boxShadow={'0px 0px 0px 1px rgba(0, 0, 0, 0.05)'}
      borderRadius={'8px'}
    >
      <Text textStyle="subhead-3">{data.question}</Text>
      <Text bgColor="warning.300" textAlign="center" rounded="md" p="1rem">
        {i18n.t(
          'features.adminForm.responses.SummaryPage.components.common.EmptyChartSummary.nonSummarizableMessage',
        )}
      </Text>
    </Box>
  )
}
