import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Divider,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react'

import {
  DEFAULT_FOOTER_ICON_LINK,
  DEFAULT_FOOTER_ICON_LINK2,
  DEFAULT_FOOTER_ICON_LINK3,
  DEFAULT_ICON_EMAIL,
  DEFAULT_ICON_LOCATION,
  DEFAULT_SOCIAL_MEDIA_LINKS,
} from './common/constants'
import { FooterContainerProps, FooterVariantProps } from './common/types'

export const FullFooter = ({
  appName,
  appLink,
  tagline,
  footerLinks,
  footerIconLink = DEFAULT_FOOTER_ICON_LINK,
  footerIconLink2 = DEFAULT_FOOTER_ICON_LINK2,
  footerIconLink3 = DEFAULT_FOOTER_ICON_LINK3,
  socialMediaLinks = DEFAULT_SOCIAL_MEDIA_LINKS,
  footerIconEmail = DEFAULT_ICON_EMAIL,
  footerIconLocation = DEFAULT_ICON_LOCATION,
  textColorScheme = 'secondary',
  containerProps,
}: FooterVariantProps): JSX.Element => {
  const currentYear = new Date().getFullYear()
  const { i18n } = useTranslation()
  return (
    <FullFooter.Container {...containerProps}>
      <FullFooter.Section>
        <SimpleGrid
          templateColumns={{
            sm: '1fr',
            md: '1fr 1fr',
            lg: '3fr 2fr 2fr 1fr',
            xl: '2fr 3fr 2fr 1fr',
          }}
          spacing={8}
        >
          <Stack spacing={6} alignItems={{ base: 'center', md: 'flex-start' }}>
            <Box mb={'1rem'}>
              <Link
                isExternal
                title={footerIconLink2.label}
                colorScheme={textColorScheme}
                href={footerIconLink3.href}
              >
                <footerIconLink3.Icon></footerIconLink3.Icon>
              </Link>
            </Box>

            <Stack direction={'row'} spacing={0}>
              <Flex gap={0}>
                <Box pt={2}>
                  <Text
                    textStyle="body-1-custom"
                    color={`${textColorScheme}.500`}
                    mb="0.5rem"
                    mr={'12px'}
                    width={'76px'}
                  >
                    {i18n.t<string>('footer.builtBy')}
                  </Text>
                </Box>
                <Box>
                  <Link
                    isExternal
                    title={footerIconLink2.label}
                    colorScheme={textColorScheme}
                    mb="2rem"
                    href={footerIconLink2.href}
                  >
                    <footerIconLink2.Icon></footerIconLink2.Icon>
                  </Link>
                </Box>
                <Box ml="0.5rem">
                  <Link
                    isExternal
                    title={footerIconLink.label}
                    colorScheme={textColorScheme}
                    mb="2rem"
                    href={footerIconLink.href}
                  >
                    <footerIconLink.Icon></footerIconLink.Icon>
                  </Link>
                </Box>
              </Flex>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <Text
              fontSize={'16px'}
              mb={'13px'}
              fontWeight={'700'}
              color={'white'}
            >
              {i18n.t<string>('footer.contact')}
            </Text>
            <Flex gap={3}>
              <Box>
                <footerIconEmail.Icon></footerIconEmail.Icon>
              </Box>
              <Box>
                <Text color={'white'}>info@form.gov.kh</Text>
              </Box>
            </Flex>

            <Flex gap={3}>
              <Box>
                <footerIconLocation.Icon></footerIconLocation.Icon>
              </Box>
              <Box>
                <Link
                  isExternal
                  colorScheme={textColorScheme}
                  href="https://goo.gl/maps/L2EFQnVsRxwstWTL9"
                  style={{ textDecoration: 'none' }}
                >
                  <Text color={'white'}>
                    {i18n.t<string>('footer.location')}
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Stack>
          <Stack align={'flex-start'}>
            <Text fontSize={'16px'} fontWeight={'700'} color={'white'}></Text>
            {footerLinks?.map(({ label, href }, index) => (
              <Link
                isExternal
                m="-0.25rem"
                key={index}
                colorScheme={textColorScheme}
                variant="standalone"
                w="fit-content"
                href={href}
              >
                {label}
              </Link>
            ))}
          </Stack>
          <Stack align={'flex-start'}>
            <Text
              fontSize={'16px'}
              mb={'13px'}
              fontWeight={'700'}
              color={'white'}
            ></Text>
            <Flex gap={3}>
              {socialMediaLinks?.map(({ label, href, Icon }, index) => (
                <Link
                  key={index}
                  isExternal
                  title={label}
                  w="24px"
                  href={href}
                  colorScheme={textColorScheme}
                >
                  <Icon />
                </Link>
              ))}
            </Flex>
          </Stack>
        </SimpleGrid>
      </FullFooter.Section>
      <Divider my="40px" />
      <FullFooter.Section>
        <Text fontSize={'sm'} textAlign={'center'} color={'white'}>
          © {currentYear} {i18n.t<string>('footer.dgc')}
        </Text>
      </FullFooter.Section>
    </FullFooter.Container>
  )
}

FullFooter.Container = ({
  children,
  ...props
}: FooterContainerProps): JSX.Element => {
  return (
    <Flex
      as="footer"
      flexDirection="column"
      py={{ base: '2rem', md: '2.5rem', lg: '3.5rem' }}
      px={{ base: '1.3125rem', md: '5rem', lg: '6.375rem' }}
      backgroundColor="primary.500"
      {...props}
    >
      {children}
    </Flex>
  )
}

FullFooter.Section = ({
  children,
  ...props
}: FooterContainerProps): JSX.Element => {
  return (
    <Flex
      align={{ base: 'normal', lg: 'center' }}
      flex={1}
      justify="space-between"
      flexDir={{ base: 'column', lg: 'row' }}
      {...props}
    >
      {children}
    </Flex>
  )
}
