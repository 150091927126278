import { SVGProps } from 'react'

export const BrandFooter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="183px"
      height="41px"
      fill="none"
      viewBox="0 0 801 182"
    >
      <g clipPath="url(#clip0_310_13151)">
        <path fill="#1C3D75" d="M0 181.061h612.173V.008H0V181.06z"></path>
        <path fill="#1C3D75" d="M0 181.061h611.947V.008H0V181.06z"></path>
        <path
          fill="#EB7D21"
          d="M524.4 178.275h273.347V2.941H524.4v175.334z"
        ></path>
        <path
          fill="#EB7D21"
          d="M524.4 178.275h273.347V2.941H524.4v175.334z"
        ></path>
        <mask
          id="mask0_310_13151"
          style={{ maskType: 'luminance' }}
          width="274"
          height="177"
          x="524"
          y="2"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M524.4 2.938h273.347V178.27H524.4V2.938z"></path>
        </mask>
        <g mask="url(#mask0_310_13151)">
          <path
            stroke="#1C3D75"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M524.4 178.271h273.347V2.938H524.4v175.333z"
          ></path>
        </g>
        <mask
          id="mask1_310_13151"
          style={{ maskType: 'luminance' }}
          width="280"
          height="183"
          x="521"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M521.333-.006H800.4v181.333H521.333V-.006z"
          ></path>
        </mask>
        <g mask="url(#mask1_310_13151)">
          <path
            stroke="#1C3D75"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M524.4 178.275h273.347V2.942H524.4v175.333z"
          ></path>
        </g>
        <path
          fill="#1C3D75"
          d="M192.92 53.322h-23.735c-2.541 0-4.719 1.11-6.416 2.708-1.579 1.73-2.542 3.943-2.542 6.537V114.8c0 2.464.964 4.808 2.542 6.407 1.697 1.724 3.874 2.708 6.416 2.708h34.146c2.421 0 4.729-.984 6.302-2.708 1.573-1.599 2.661-3.943 2.661-6.407V75.004L192.92 53.322zm-23.735-1.48h24.459l19.864 22.422.239.495V114.8c0 2.959-1.208 5.542-3.026 7.516-1.937 1.849-4.598 3.078-7.39 3.078h-34.146a10.431 10.431 0 01-7.385-3.078c-1.937-1.974-3.151-4.557-3.151-7.516V62.566c0-2.959 1.213-5.672 3.151-7.521 1.818-1.969 4.479-3.202 7.385-3.202z"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1.333"
          d="M192.92 53.322h-23.735c-2.541 0-4.719 1.11-6.416 2.709-1.579 1.729-2.542 3.943-2.542 6.536v52.235c0 2.463.964 4.807 2.542 6.406 1.697 1.724 3.874 2.708 6.416 2.708h34.146c2.421 0 4.729-.984 6.302-2.708 1.573-1.599 2.661-3.943 2.661-6.406V75.004L192.92 53.322zm-23.735-1.479h24.459l19.864 22.422.239.495V114.8c0 2.958-1.208 5.541-3.026 7.515-1.937 1.849-4.598 3.079-7.39 3.079h-34.146c-2.906 0-5.567-1.23-7.385-3.079-1.937-1.974-3.151-4.557-3.151-7.515V62.566c0-2.958 1.213-5.672 3.151-7.52 1.818-1.97 4.479-3.204 7.385-3.204v.001z"
        ></path>
        <path
          fill="#1C3D75"
          d="M195.587 72.421c-1.818-1.974-3.032-4.682-3.032-7.515V52.588c0-.13.125-.375.245-.5.365-.245.724-.245 1.089 0l19.62 22.057c.12.12.239.37.239.615 0 .37-.359.74-.724.74h-10.052c-2.786 0-5.448-1.235-7.265-3.079h-.12zm-1.579-7.515c0 2.463 1.094 4.802 2.667 6.406 1.693 1.724 3.875 2.708 6.297 2.708h8.354l-17.317-19.464"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1.333"
          d="M195.587 72.42c-1.818-1.974-3.032-4.682-3.032-7.516V52.587c0-.13.125-.376.245-.5.365-.245.724-.245 1.089 0l19.62 22.057c.12.12.239.37.239.614 0 .37-.359.74-.724.74h-10.052c-2.786 0-5.448-1.234-7.265-3.078h-.12zm-1.579-7.516c0 2.464 1.094 4.802 2.667 6.407 1.693 1.723 3.875 2.708 6.297 2.708h8.354l-17.317-19.464-.001 10.35z"
        ></path>
        <path
          fill="#1C3D75"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1.333"
          d="M159.373 112.338h53.646v-1.479h-53.646v1.479z"
        ></path>
        <path
          fill="#1C3D75"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1.6"
          d="M173.907 102.947h3.021v-2.239h-3.021v2.239zM173.907 97.072h3.021v-2.234h-3.021v2.234zM173.907 91.201h3.021v-2.24h-3.021v2.24zM173.907 85.328h3.021v-2.24h-3.021v2.24zM173.907 79.453h3.021v-2.24h-3.021v2.24zM180.6 102.947h17.057v-2.239H180.6v2.239zM180.6 97.072h17.057v-2.234H180.6v2.234zM180.6 91.201h17.057v-2.24H180.6v2.24zM180.6 85.328h17.057v-2.24H180.6v2.24zM180.6 79.453h17.057v-2.24H180.6v2.24z"
        ></path>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="5.333"
          d="M116.088 69.253H80.921v6.943H97.84v28.229H80.92v36.688H31.656V36.791h84.432v32.462z"
        ></path>
        <mask
          id="mask2_310_13151"
          style={{ maskType: 'luminance' }}
          width="258"
          height="183"
          x="57"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M57.333-.006h257.334v181.827H57.333V-.006z"
          ></path>
        </mask>
        <g mask="url(#mask2_310_13151)">
          <path
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M238.573 88.466c0 14.136-4.75 26.443-14.24 36.921-9.495 10.485-21.011 15.724-34.552 15.724V112.95l-8.338-.083.078 27.822c-13.542-1.177-24.995-6.182-34.198-16.208-9.203-10.026-13.802-22.005-13.802-35.926 0-14.511 5.088-26.896 15.276-37.162 10.187-10.265 22.578-15.396 37.161-15.396 14.469 0 26.855 5.12 37.161 15.37 10.297 10.245 15.454 22.609 15.454 37.098v.001z"
          ></path>
        </g>
        <mask
          id="mask3_310_13151"
          style={{ maskType: 'luminance' }}
          width="252"
          height="183"
          x="178"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M178.667-.006h250.666v181.827H178.667V-.006z"
          ></path>
        </mask>
        <g mask="url(#mask3_310_13151)">
          <path
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M353.36 141.113h-52.615v-38.156h-7.817v38.156H255.25V72.212h41.323v-9.365H255.25v-22.26h65.287c7.062 0 12.995 2.578 17.797 7.714 4.808 5.14 7.209 11.318 7.209 18.527 0 9.099-3.011 16.073-9.032 20.917-1.968 1.536-4.979 3.078-9.026 4.61v3.182l25.875 45.576z"
          ></path>
        </g>
        <mask
          id="mask4_310_13151"
          style={{ maskType: 'luminance' }}
          width="289"
          height="183"
          x="289"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M289.333-.006h288v181.827h-288V-.006z"></path>
        </mask>
        <g mask="url(#mask4_310_13151)">
          <path
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M500.6 141.115h-44.459v-34.448h-7.812v34.448h-31.427v-34.448h-7.646v34.448h-43.755V38.782l70.5 37.145L500.6 38.823v102.292z"
          ></path>
        </g>
        <mask
          id="mask5_310_13151"
          style={{ maskType: 'luminance' }}
          width="246"
          height="183"
          x="486"
          y="-1"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M486.667-.006H732v181.827H486.667V-.006z"></path>
        </mask>
        <g mask="url(#mask5_310_13151)">
          <path
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="5.333"
            d="M656.48 141.115h-51.573v-32.683l-8.506-.177v32.86h-33.688V39.531h33.688v35.334h8.506V39.53h46.021l-23.099 47.703 28.651 53.881z"
          ></path>
        </g>
        <path
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="5.333"
          d="M763 141.113h-41.5v-32.15h-8.51v32.15h-39.938V39.529h39.938v33.922h8.51V39.529H763v101.584z"
        ></path>
        <path
          fill="#1C3D75"
          fillRule="evenodd"
          d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.593.593 0 000 .844l13.879 13.88a.997.997 0 001.412 0l21.953-21.959a.593.593 0 000-.844l-3.031-3.036a.604.604 0 00-.844 0z"
          clipRule="evenodd"
        ></path>
        <mask
          id="mask6_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="5"
          x="701"
          y="95"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 95.994H736v4h-34.667v-4z"></path>
        </mask>
        <g mask="url(#mask6_310_13151)">
          <mask
            id="mask7_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask7_310_13151)"
          >
            <path d="M700.054 96.463l.015-.072 34.746 7.005-.011.073-34.75-7.005z"></path>
            <path d="M700.054 96.494l.068-.312 34.745 7.005-.063.312-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask8_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="6"
          x="701"
          y="94"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 94.66H736v5.334h-34.667V94.66z"></path>
        </mask>
        <g mask="url(#mask8_310_13151)">
          <mask
            id="mask9_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask9_310_13151)"
          >
            <path d="M700.094 96.284l.062-.307 34.75 7-.063.312-34.749-7.005z"></path>
            <path d="M700.134 96.08l.057-.312 34.755 7-.068.312-34.744-7z"></path>
            <path d="M700.174 95.873l.062-.313 34.746 7.001-.063.312-34.745-7z"></path>
            <path d="M700.214 95.664l.062-.312 34.746 7.005-.063.307-34.745-7z"></path>
            <path d="M700.267 95.457l.063-.313 34.749 7.006-.063.312-34.749-7.005z"></path>
            <path d="M700.307 95.246l.063-.312 34.749 7.005-.068.312-34.744-7.005z"></path>
            <path d="M700.347 95.04l.063-.313 34.749 7.005-.062.312-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask10_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="7"
          x="701"
          y="93"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M701.333 93.328H736v6.667h-34.667v-6.667z"
          ></path>
        </mask>
        <g mask="url(#mask10_310_13151)">
          <mask
            id="mask11_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask11_310_13151)"
          >
            <path d="M700.387 94.832l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
            <path d="M700.427 94.625l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
            <path d="M700.467 94.416l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
            <path d="M700.507 94.208l.062-.308 34.75 7-.063.313-34.749-7.005z"></path>
            <path d="M700.547 94.004l.062-.313 34.75 7-.063.313-34.749-7z"></path>
            <path d="M700.6 93.797l.063-.313 34.749 7-.063.313-34.749-7z"></path>
          </g>
        </g>
        <mask
          id="mask12_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="9"
          x="701"
          y="91"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 91.994H736v8h-34.667v-8z"></path>
        </mask>
        <g mask="url(#mask12_310_13151)">
          <mask
            id="mask13_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask13_310_13151)"
          >
            <path d="M700.64 93.588l.063-.313 34.749 7.006-.063.307-34.749-7z"></path>
            <path d="M700.68 93.379l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M700.72 93.172l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M700.76 92.963l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M700.8 92.754l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M700.84 92.545l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M700.88 92.338l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask14_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="10"
          x="701"
          y="90"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 90.66H736v9.334h-34.667V90.66z"></path>
        </mask>
        <g mask="url(#mask14_310_13151)">
          <mask
            id="mask15_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask15_310_13151)"
          >
            <path d="M700.934 92.13l.062-.308 34.749 7-.062.313-34.749-7.005z"></path>
            <path d="M700.974 91.926l.062-.313 34.75 7-.063.313-34.749-7z"></path>
            <path d="M701.014 91.717l.062-.313 34.75 7-.063.313-34.749-7z"></path>
          </g>
        </g>
        <mask
          id="mask16_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="9"
          x="701"
          y="90"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 90.66H736v8h-34.667v-8z"></path>
        </mask>
        <g mask="url(#mask16_310_13151)">
          <mask
            id="mask17_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask17_310_13151)"
          >
            <path d="M701.054 91.51l.068-.313 34.745 7.005-.063.308-34.75-7z"></path>
            <path d="M701.094 91.299l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M701.147 91.092l.057-.313 34.755 7.005-.068.313-34.744-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask18_310_13151"
          style={{ maskType: 'luminance' }}
          width="35"
          height="10"
          x="701"
          y="89"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M701.333 89.328H736v9.334h-34.667v-9.334z"
          ></path>
        </mask>
        <g mask="url(#mask18_310_13151)">
          <mask
            id="mask19_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g mask="url(#mask19_310_13151)">
            <path
              fill="#1C3D75"
              fillRule="evenodd"
              d="M701.187 90.885l.063-.313 34.745 7.005-.063.313-34.745-7.005z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <mask
          id="mask20_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="701"
          y="89"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 89.328h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask20_310_13151)">
          <mask
            id="mask21_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask21_310_13151)"
          >
            <path d="M701.213 90.678l.068-.313 34.745 7.005-.057.313-34.756-7.005z"></path>
            <path d="M701.267 90.469l.062-.313 34.749 7.005-.062.313-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask22_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="701"
          y="89"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 89.328h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask22_310_13151)">
          <mask
            id="mask23_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask23_310_13151)"
          >
            <path d="M701.307 90.26l.062-.313 34.75 7.005-.068.313-34.744-7.005z"></path>
            <path d="M701.347 90.053l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M701.387 89.844l.062-.307 34.75 7-.063.313-34.749-7.006z"></path>
            <path d="M701.427 89.64l.062-.312 34.75 7-.063.313-34.749-7z"></path>
          </g>
        </g>
        <mask
          id="mask24_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="11"
          x="701"
          y="87"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 87.994h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask24_310_13151)">
          <mask
            id="mask25_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask25_310_13151)"
          >
            <path d="M701.48 89.432l.063-.313 34.749 7.005-.063.308-34.749-7z"></path>
            <path d="M701.52 89.223l.063-.313 34.749 7.005-.063.313-34.749-7.005z"></path>
            <path d="M701.56 89.016l.063-.313 34.749 7.005-.063.313-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask26_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="701"
          y="87"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 87.994h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask26_310_13151)">
          <mask
            id="mask27_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask27_310_13151)"
          >
            <path d="M701.6 88.807l.063-.313 34.749 7.005-.063.313-34.749-7.005z"></path>
            <path d="M701.64 88.598l.063-.313 34.749 7.005-.063.313-34.749-7.005z"></path>
            <path d="M701.68 88.39l.063-.312 34.749 7.005-.062.313-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask28_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="701"
          y="86"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 86.66h36v9.334h-36V86.66z"></path>
        </mask>
        <g mask="url(#mask28_310_13151)">
          <mask
            id="mask29_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask29_310_13151)"
          >
            <path d="M701.72 88.182l.063-.313 34.749 7.005-.062.313-34.75-7.005z"></path>
            <path d="M701.76 87.973l.063-.313 34.749 7.005-.062.313-34.75-7.005z"></path>
            <path d="M701.813 87.764l.063-.307 34.749 7-.062.313-34.75-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask30_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="701"
          y="86"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 86.66h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask30_310_13151)">
          <mask
            id="mask31_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask31_310_13151)"
          >
            <path d="M701.854 87.56l.062-.312 34.749 7-.062.313-34.749-7z"></path>
            <path d="M701.894 87.352l.062-.313 34.749 7.005-.062.308-34.749-7z"></path>
            <path d="M701.934 87.143l.062-.313 34.749 7.005-.062.313-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask32_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="701"
          y="85"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 85.328h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask32_310_13151)">
          <mask
            id="mask33_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask33_310_13151)"
          >
            <path d="M701.974 86.938l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M702.014 86.728l.062-.312 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M702.067 86.522l.062-.313 34.746 7.005-.063.313-34.745-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask34_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="701"
          y="85"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 85.328h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask34_310_13151)">
          <mask
            id="mask35_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask35_310_13151)"
          >
            <path d="M702.094 86.313l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M702.147 86.103l.063-.312 34.749 7.005-.063.313-34.749-7.005z"></path>
            <path d="M702.187 85.897l.063-.313 34.745 7.005-.063.313-34.745-7.005z"></path>
            <path d="M702.227 85.688l.063-.307 34.745 7-.063.312-34.745-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask36_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="11"
          x="701"
          y="83"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 83.994h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask36_310_13151)">
          <mask
            id="mask37_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask37_310_13151)"
          >
            <path d="M702.267 85.484l.062-.312 34.749 7-.062.312-34.749-7z"></path>
            <path d="M702.307 85.275l.062-.312 34.75 7.005-.063.307-34.749-7z"></path>
            <path d="M702.36 85.068l.062-.312 34.746 7.005-.058.313-34.75-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask38_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="701"
          y="83"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 83.994h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask38_310_13151)">
          <mask
            id="mask39_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask39_310_13151)"
          >
            <path d="M702.4 84.86l.063-.313 34.749 7.005-.062.313-34.75-7.006z"></path>
            <path d="M702.44 84.65l.063-.312 34.749 7.005-.062.313-34.75-7.006z"></path>
            <path d="M702.48 84.443l.063-.312 34.749 7.005-.062.313-34.75-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask40_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="701"
          y="82"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 82.66h36v9.334h-36V82.66z"></path>
        </mask>
        <g mask="url(#mask40_310_13151)">
          <mask
            id="mask41_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g mask="url(#mask41_310_13151)">
            <path
              fill="#1C3D75"
              fillRule="evenodd"
              d="M702.52 84.232l.063-.312 34.749 7.005-.063.313-34.749-7.006z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <mask
          id="mask42_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="10"
          x="701"
          y="82"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M701.333 82.66h37.334v9.334h-37.334V82.66z"
          ></path>
        </mask>
        <g mask="url(#mask42_310_13151)">
          <mask
            id="mask43_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask43_310_13151)"
          >
            <path d="M702.56 84.023l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
            <path d="M702.6 83.816l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask44_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="9"
          x="701"
          y="82"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M701.333 82.66h37.334v8h-37.334v-8z"></path>
        </mask>
        <g mask="url(#mask44_310_13151)">
          <mask
            id="mask45_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g mask="url(#mask45_310_13151)">
            <path
              fill="#1C3D75"
              fillRule="evenodd"
              d="M702.64 83.608l.063-.307 34.749 7-.063.312-34.749-7.005z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <mask
          id="mask46_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="702"
          y="82"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 82.66h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask46_310_13151)">
          <mask
            id="mask47_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.186 92.41l-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask47_310_13151)"
          >
            <path d="M702.693 83.406l.062-.312 34.75 7-.063.312-34.749-7z"></path>
            <path d="M702.733 83.195l.062-.312 34.75 7.005-.063.307-34.749-7z"></path>
            <path d="M702.773 82.988l.062-.312 34.75 7.005-.063.313-34.749-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask48_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="702"
          y="81"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 81.328h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask48_310_13151)">
          <mask
            id="mask49_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask49_310_13151)"
          >
            <path d="M702.813 82.781l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
            <path d="M702.853 82.572l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
            <path d="M702.893 82.363l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask50_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="702"
          y="81"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 81.328h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask50_310_13151)">
          <mask
            id="mask51_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask51_310_13151)"
          >
            <path d="M702.933 82.156l.068-.312 34.745 7.005-.062.313-34.751-7.006z"></path>
            <path d="M702.973 81.947l.063-.312 34.749 7.005-.063.313-34.749-7.006z"></path>
            <path d="M703.026 81.74l.063-.312 34.749 7.005-.062.312-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask52_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="11"
          x="702"
          y="79"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 79.994h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask52_310_13151)">
          <mask
            id="mask53_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask53_310_13151)"
          >
            <path d="M703.066 81.53l.063-.307 34.745 7-.062.312-34.746-7.005z"></path>
            <path d="M703.106 81.326l.068-.312 34.746 7-.063.312-34.751-7z"></path>
            <path d="M703.146 81.12l.068-.313 34.746 7.005-.063.307-34.751-7z"></path>
          </g>
        </g>
        <mask
          id="mask54_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="702"
          y="79"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 79.994h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask54_310_13151)">
          <mask
            id="mask55_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask55_310_13151)"
          >
            <path d="M703.187 80.912l.062-.312 34.749 7.005-.067.312-34.744-7.005z"></path>
            <path d="M703.24 80.701l.062-.312 34.746 7.005-.063.312-34.745-7.005z"></path>
            <path d="M703.28 80.494l.062-.312 34.75 7.005-.063.312-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask56_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="702"
          y="78"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 78.66h36v9.334h-36V78.66z"></path>
        </mask>
        <g mask="url(#mask56_310_13151)">
          <mask
            id="mask57_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.186 92.41l-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask57_310_13151)"
          >
            <path d="M703.32 80.285l.062-.312 34.75 7.005-.063.312-34.749-7.005z"></path>
            <path d="M703.36 80.076l.062-.312 34.75 7.005-.068.312-34.744-7.005z"></path>
            <path d="M703.4 79.867l.062-.312 34.75 7.005-.063.312-34.749-7.005z"></path>
            <path d="M703.44 79.66l.062-.312 34.75 7.005-.063.312-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask58_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="702"
          y="78"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 78.66h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask58_310_13151)">
          <mask
            id="mask59_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.186 92.41l-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask59_310_13151)"
          >
            <path d="M703.479 79.452l.063-.308 34.749 7-.062.313-34.75-7.005z"></path>
            <path d="M703.52 79.246l.062-.312 34.749 7-.062.312-34.749-7z"></path>
            <path d="M703.573 79.04l.062-.313 34.75 7.005-.063.307-34.749-7z"></path>
          </g>
        </g>
        <mask
          id="mask60_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="10"
          x="702"
          y="77"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 77.328h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask60_310_13151)">
          <mask
            id="mask61_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask61_310_13151)"
          >
            <path d="M703.613 78.834l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M703.653 78.625l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
            <path d="M703.693 78.416l.063-.313 34.749 7.006-.062.312-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask62_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="9"
          x="702"
          y="77"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 77.328h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask62_310_13151)">
          <mask
            id="mask63_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask63_310_13151)"
          >
            <path d="M703.733 78.209l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
            <path d="M703.773 78l.062-.313 34.75 7.006-.063.312L703.773 78z"></path>
            <path d="M703.813 77.793l.063-.313 34.749 7.006-.063.312-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask64_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="11"
          x="702"
          y="75"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M702.667 75.994H740v9.334h-37.333v-9.334z"
          ></path>
        </mask>
        <g mask="url(#mask64_310_13151)">
          <mask
            id="mask65_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask65_310_13151)"
          >
            <path d="M703.853 77.582l.063-.313 34.749 7.006-.063.312-34.749-7.005z"></path>
            <path d="M703.906 77.374l.063-.308 34.749 7-.062.313-34.75-7.005z"></path>
            <path d="M703.946 77.172l.058-.313 34.749 7-.063.313-34.744-7z"></path>
          </g>
        </g>
        <mask
          id="mask66_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="9"
          x="702"
          y="75"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M702.667 75.994H740v8h-37.333v-8z"></path>
        </mask>
        <g mask="url(#mask66_310_13151)">
          <mask
            id="mask67_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.787 18.787-10.703-10.703a.593.593 0 00-.843 0l-3.037 3.031a.601.601 0 000 .85l13.88 13.88a1.006 1.006 0 001.411 0l21.959-21.965a.604.604 0 000-.844l-3.036-3.036a.6.6 0 00-.844 0z"
            ></path>
          </mask>
          <g mask="url(#mask67_310_13151)">
            <path
              fill="#1C3D75"
              fillRule="evenodd"
              d="M703.986 76.965l.063-.313 34.749 7.005-.062.308-34.75-7z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <mask
          id="mask68_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="9"
          x="704"
          y="75"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 75.994h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask68_310_13151)">
          <mask
            id="mask69_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask69_310_13151)"
          >
            <path d="M704.027 76.754l.062-.313 34.75 7.006-.063.312-34.749-7.005z"></path>
            <path d="M704.067 76.545l.062-.313 34.746 7.006-.063.312-34.745-7.005z"></path>
            <path d="M704.12 76.338l.063-.313 34.745 7.006-.063.312-34.745-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask70_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="10"
          x="704"
          y="74"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 74.66h36v9.334h-36V74.66z"></path>
        </mask>
        <g mask="url(#mask70_310_13151)">
          <mask
            id="mask71_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask71_310_13151)"
          >
            <path d="M704.16 76.129l.063-.313 34.749 7.006-.068.312-34.744-7.005z"></path>
            <path d="M704.2 75.918l.063-.313 34.749 7.006-.063.312-34.749-7.005z"></path>
            <path d="M704.24 75.71l.063-.312 34.749 7.006-.068.312-34.744-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask72_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="9"
          x="704"
          y="74"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 74.66h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask72_310_13151)">
          <mask
            id="mask73_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.623L717.187 92.41l-10.703-10.703a.595.595 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.593.593 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask73_310_13151)"
          >
            <path d="M704.28 75.504l.063-.313 34.749 7.006-.068.312-34.744-7.005z"></path>
            <path d="M704.32 75.296l.063-.308 34.749 7-.062.313-34.75-7.005z"></path>
            <path d="M704.36 75.092l.063-.313 34.749 7-.062.313-34.75-7z"></path>
          </g>
        </g>
        <mask
          id="mask74_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="10"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h36v9.334h-36v-9.334z"></path>
        </mask>
        <g mask="url(#mask74_310_13151)">
          <mask
            id="mask75_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask75_310_13151)"
          >
            <path d="M704.4 74.887l.063-.313 34.749 7.005-.062.308-34.75-7z"></path>
            <path d="M704.454 74.678l.062-.313 34.749 7.005-.062.313-34.749-7.005z"></path>
            <path d="M704.494 74.469l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask76_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="9"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h36v8h-36v-8z"></path>
        </mask>
        <g mask="url(#mask76_310_13151)">
          <mask
            id="mask77_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask77_310_13151)"
          >
            <path d="M704.534 74.26l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.574 74.05l.062-.312 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.614 73.844l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.654 73.635l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.694 73.426l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.733 73.217l.063-.307 34.749 7-.062.313-34.75-7.006z"></path>
            <path d="M704.787 73.016l.063-.313 34.749 7-.063.313-34.749-7z"></path>
          </g>
        </g>
        <mask
          id="mask78_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="7"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h36v6.667h-36v-6.667z"></path>
        </mask>
        <g mask="url(#mask78_310_13151)">
          <mask
            id="mask79_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask79_310_13151)"
          >
            <path d="M704.827 72.807l.062-.313 34.75 7.005-.063.308-34.749-7z"></path>
            <path d="M704.867 72.6l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.907 72.39l.062-.312 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M704.947 72.184l.057-.313 34.749 7.005-.062.313-34.744-7.005z"></path>
            <path d="M704.987 71.973l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M705.027 71.766l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask80_310_13151"
          style={{ maskType: 'luminance' }}
          width="36"
          height="6"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h36v5.334h-36v-5.334z"></path>
        </mask>
        <g mask="url(#mask80_310_13151)">
          <mask
            id="mask81_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask81_310_13151)"
          >
            <path d="M705.08 71.557l.063-.313 34.745 7.005-.063.313-34.745-7.005z"></path>
            <path d="M705.12 71.35l.063-.313 34.745 7.005-.063.313-34.745-7.005z"></path>
            <path d="M705.16 71.14l.058-.308 34.754 7-.062.313-34.75-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask82_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="6"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h37.333v5.334H704v-5.334z"></path>
        </mask>
        <g mask="url(#mask82_310_13151)">
          <mask
            id="mask83_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask83_310_13151)"
          >
            <path d="M705.2 70.938l.063-.313 34.749 7-.063.313-34.749-7z"></path>
            <path d="M705.24 70.728l.063-.312 34.745 7.005-.062.307-34.746-7z"></path>
            <path d="M705.28 70.522l.063-.313 34.749 7.005-.062.313-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask84_310_13151"
          style={{ maskType: 'luminance' }}
          width="38"
          height="5"
          x="704"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M704 73.328h37.333v4H704v-4z"></path>
        </mask>
        <g mask="url(#mask84_310_13151)">
          <mask
            id="mask85_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g mask="url(#mask85_310_13151)">
            <path
              fill="#1C3D75"
              fillRule="evenodd"
              d="M705.333 70.313l.063-.313 34.749 7.005-.062.313-34.75-7.005z"
              clipRule="evenodd"
            ></path>
          </g>
        </g>
        <mask
          id="mask86_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="5"
          x="705"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M705.333 73.328h36v4h-36v-4z"></path>
        </mask>
        <g mask="url(#mask86_310_13151)">
          <mask
            id="mask87_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask87_310_13151)"
          >
            <path d="M705.374 70.103l.062-.312 34.749 7.005-.062.313-34.749-7.005z"></path>
            <path d="M705.414 69.897l.062-.313 34.749 7.005-.062.313-34.749-7.005z"></path>
            <path d="M705.454 69.688l.062-.313 34.749 7.005-.062.313-34.749-7.005z"></path>
            <path d="M705.494 69.478l.062-.312 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M705.534 69.272l.062-.313 34.75 7.005-.063.313-34.749-7.005z"></path>
            <path d="M705.573 69.063l.063-.307 34.749 7-.062.312-34.75-7.005z"></path>
          </g>
        </g>
        <mask
          id="mask88_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="3"
          x="705"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M705.333 73.328h36v2.667h-36v-2.667z"></path>
        </mask>
        <g mask="url(#mask88_310_13151)">
          <mask
            id="mask89_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask89_310_13151)"
          >
            <path d="M705.613 68.857l.063-.312 34.749 7-.062.312-34.75-7z"></path>
            <path d="M705.667 68.65l.062-.312 34.749 7.005-.062.307-34.749-7z"></path>
            <path d="M705.707 68.441l.062-.312 34.749 7.005-.062.313-34.749-7.006z"></path>
            <path d="M705.747 68.234l.062-.312 34.749 7.005-.062.313-34.749-7.006z"></path>
            <path d="M705.787 68.023l.062-.312 34.749 7.005-.062.313-34.749-7.006z"></path>
            <path d="M705.827 67.816l.062-.312 34.75 7.005-.063.313-34.749-7.006z"></path>
          </g>
        </g>
        <mask
          id="mask90_310_13151"
          style={{ maskType: 'luminance' }}
          width="37"
          height="2"
          x="705"
          y="73"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M705.333 73.328h36v1.334h-36v-1.334z"></path>
        </mask>
        <g mask="url(#mask90_310_13151)">
          <mask
            id="mask91_310_13151"
            style={{ maskType: 'luminance' }}
            width="39"
            height="27"
            x="702"
            y="73"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M735.973 73.625l-18.786 18.787-10.703-10.703a.594.594 0 00-.844 0l-3.036 3.031a.6.6 0 000 .85l13.879 13.88a1.007 1.007 0 001.412 0l21.959-21.965a.604.604 0 000-.844l-3.037-3.036a.599.599 0 00-.422-.172.592.592 0 00-.422.172z"
            ></path>
          </mask>
          <g
            fill="#1C3D75"
            fillRule="evenodd"
            clipRule="evenodd"
            mask="url(#mask91_310_13151)"
          >
            <path d="M705.867 67.61l.062-.313 34.75 7.005-.063.313-34.749-7.006z"></path>
            <path d="M705.907 67.401l.041-.182 34.746 7.005-.037.182-34.75-7.005z"></path>
          </g>
        </g>
        <path fill="#1C3D75" d="M184.107 149.742h3v-36.75h-3v36.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_310_13151">
          <path fill="#fff" d="M0 0H800.4V181.053H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
