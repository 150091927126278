import { StatusCodes } from 'http-status-codes'

import { BasicField, FormFieldDto } from '~shared/types'

import i18n from '~/i18n/i18n'

import { HttpError } from '~services/ApiService'

export const getMutationToastDescriptionFieldName = (field: FormFieldDto) => {
  switch (field.fieldType) {
    case BasicField.Section:
      return i18n.t<string>('banner.header')
    case BasicField.Image:
      return i18n.t<string>('banner.image')
    case BasicField.Statement:
      return i18n.t<string>('banner.paragraph')
    default:
      return i18n.t<string>('banner.field') + field.title
  }
}

export const getMutationErrorMessage = (error: Error): string => {
  if (error instanceof HttpError) {
    switch (error.code) {
      case StatusCodes.FORBIDDEN:
        return 'You do not have permission to modify this form.'
      default:
        break
    }
  }
  return error.message
}
