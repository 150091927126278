import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPrimitiveDot } from 'react-icons/go'
import { Link as ReactLink } from 'react-router-dom'
import {
  Box,
  chakra,
  Flex,
  FlexProps,
  HStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react'

import { LANG_VALUE } from '~/i18n/lang-value'

import { ReactComponent as BrandMarkSvg } from '~assets/svgs/brand/brand-mark-colour.svg'
import {
  EMERGENCY_CONTACT_KEY_PREFIX,
  ROLLOUT_ANNOUNCEMENT_KEY_PREFIX,
} from '~constants/localStorage'
import { DASHBOARD_ROUTE } from '~constants/routes'
import { useLocalStorage } from '~hooks/useLocalStorage'
import { logout } from '~services/AuthService'
import { AvatarMenu } from '~templates/AvatarMenu/AvatarMenu'

import { useUserMutations } from '~features/user/mutations'
import { useUser } from '~features/user/queries'
import { FEATURE_UPDATE_LIST } from '~features/whats-new/FeatureUpdateList'
import { getShowLatestFeatureUpdateNotification } from '~features/whats-new/utils/utils'
import { WhatsNewDrawer } from '~features/whats-new/WhatsNewDrawer'

import Menu from '../../components/Menu'
import { LanguageDropdown } from '../../i18n/LanguageDropdown'

const BrandSmallLogo = chakra(BrandMarkSvg, {
  baseStyle: {
    width: { base: '150px' },
    height: { base: '44px' },
  },
})

interface WhatsNewNavBarTabProps {
  onClick: () => void
  shouldShowNotiifcation: boolean
}

const WhatsNewNavBarTab = ({
  onClick,
  shouldShowNotiifcation,
}: WhatsNewNavBarTabProps) => {
  return (
    <Box position="relative">
      {shouldShowNotiifcation && (
        <Icon
          as={GoPrimitiveDot}
          color="danger.500"
          position="absolute"
          ml="-5px"
        />
      )}
    </Box>
  )
}

export interface AdminNavBarProps {
  /* This prop is only for testing to show expanded menu state */
  isMenuOpen?: boolean
}

export const AdminNavBar = ({ isMenuOpen }: AdminNavBarProps): JSX.Element => {
  const { user, isLoading: isUserLoading, removeQuery } = useUser()
  const { updateLastSeenFeatureVersionMutation } = useUserMutations()
  const { i18n } = useTranslation()

  const whatsNewFeatureDrawerDisclosure = useDisclosure()

  const ROLLOUT_ANNOUNCEMENT_KEY = useMemo(
    () => ROLLOUT_ANNOUNCEMENT_KEY_PREFIX + user?._id,
    [user],
  )
  const [hasSeenAnnouncement] = useLocalStorage<boolean>(
    ROLLOUT_ANNOUNCEMENT_KEY,
    false,
  )

  // Only want to show the emergency contact modal if user id exists but user has no emergency contact
  const emergencyContactKey = useMemo(
    () =>
      user && user._id && !user.contact
        ? EMERGENCY_CONTACT_KEY_PREFIX + user._id
        : null,
    [user],
  )

  const [hasSeenContactModal, setHasSeenContactModal] =
    useLocalStorage<boolean>(emergencyContactKey, false)

  const {
    isOpen: isContactModalOpen,
    onClose: onContactModalClose,
    onOpen: onContactModalOpen,
  } = useDisclosure({
    onClose: () => {
      setHasSeenContactModal(true)
    },
  })

  const shouldShowFeatureUpdateNotification = useMemo(() => {
    if (isUserLoading || !user) return false
    return getShowLatestFeatureUpdateNotification(user)
  }, [isUserLoading, user])

  const onWhatsNewDrawerOpen = useCallback(() => {
    if (isUserLoading || !user) return
    // Update version if current user version is not set or is less than the latest version.
    if (
      user.flags?.lastSeenFeatureUpdateVersion === undefined ||
      user.flags?.lastSeenFeatureUpdateVersion < FEATURE_UPDATE_LIST.version
    ) {
      updateLastSeenFeatureVersionMutation.mutateAsync(
        FEATURE_UPDATE_LIST.version,
      )
    }
    whatsNewFeatureDrawerDisclosure.onOpen()
  }, [
    isUserLoading,
    updateLastSeenFeatureVersionMutation,
    user,
    whatsNewFeatureDrawerDisclosure,
  ])

  // Emergency contact modal appears after the rollout announcement modal
  useEffect(() => {
    if (
      hasSeenContactModal === false &&
      user &&
      !user.contact &&
      hasSeenAnnouncement === true
    ) {
      onContactModalOpen()
    }
  }, [hasSeenContactModal, onContactModalOpen, user, hasSeenAnnouncement])

  const handleLogout = useCallback(() => {
    logout()
    removeQuery()
    if (emergencyContactKey) {
      localStorage.removeItem(emergencyContactKey)
    }
  }, [emergencyContactKey, removeQuery])

  return (
    <>
      <AdminNavBar.Container>
        <ReactLink title="Form Logo" to={DASHBOARD_ROUTE}>
          <BrandSmallLogo />
        </ReactLink>
        <HStack
          textStyle="subhead-1"
          spacing={{ base: '0.5rem', md: '0.875rem' }}
        >
          <LanguageDropdown />
          {/* <WhatsNewNavBarTab
            onClick={onWhatsNewDrawerOpen}
            shouldShowNotiifcation={shouldShowFeatureUpdateNotification}
          /> */}
          <AvatarMenu
            name={user?.email}
            menuUsername={user?.email}
            defaultIsOpen={isMenuOpen}
            menuListProps={{ maxWidth: '19rem' }}
          >
            <Menu.Item onClick={handleLogout}>
              {i18n.t<string>('app.adminNavBar.adminNavBar.logout')}
            </Menu.Item>
          </AvatarMenu>
        </HStack>
      </AdminNavBar.Container>
      <WhatsNewDrawer
        isOpen={whatsNewFeatureDrawerDisclosure.isOpen}
        onClose={whatsNewFeatureDrawerDisclosure.onClose}
      />
    </>
  )
}

interface AdminNavBarContainerProps extends FlexProps {
  children: React.ReactNode
}

AdminNavBar.Container = ({
  children,
  ...props
}: AdminNavBarContainerProps): JSX.Element => {
  return (
    <Flex
      justify="space-between"
      align="center"
      px={{ base: '1.5rem', md: '1.8rem', xl: '2rem' }}
      py="0.75rem"
      bg="white"
      borderBottom="1px"
      borderBottomColor="neutral.300"
      {...props}
    >
      {children}
    </Flex>
  )
}
