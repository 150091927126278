/**
 * This utility file creates validation rules for `react-hook-form` according
 * to the field schema.
 */
import { RegisterOptions } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import { isValid, parse } from 'date-fns'
import { identity } from 'lodash'
import simplur from 'simplur'
import validator from 'validator'

import {
  AttachmentFieldBase,
  BasicField,
  CheckboxFieldBase,
  DateFieldBase,
  DateSelectedValidation,
  DecimalFieldBase,
  DropdownFieldBase,
  EmailFieldBase,
  FieldBase,
  HomenoFieldBase,
  LongTextFieldBase,
  MobileFieldBase,
  NricFieldBase,
  NumberFieldBase,
  NumberSelectedValidation,
  RadioFieldBase,
  RatingFieldBase,
  ShortTextFieldBase,
  TextSelectedValidation,
  UenFieldBase,
} from '~shared/types/field'
import { isMFinSeriesValid, isNricValid } from '~shared/utils/nric-validation'
import {
  isHomePhoneNumber,
  isMobilePhoneNumber,
} from '~shared/utils/phone-num-validation'
import { isUenValid } from '~shared/utils/uen-validation'

import i18n from '~/i18n/i18n'

import { DATE_PARSE_FORMAT } from '~templates/Field/Date/DateField'
import {
  CheckboxFieldValues,
  SingleAnswerValue,
  VerifiableFieldValues,
} from '~templates/Field/types'

import { VerifiableFieldBase } from '~features/verifiable-fields/types'

import {
  isDateAfterToday,
  isDateBeforeToday,
  isDateOutOfRange,
  loadDateFromNormalizedDate,
} from './date'
import { formatNumberToLocaleString } from './stringFormat'

// Omit unused props
type MinimumFieldValidationProps<T extends FieldBase> = Omit<
  T,
  'fieldType' | 'description' | 'disabled'
>

// fieldType is only used in email and mobile field verification, so we don't omit it
type MinimumFieldValidationPropsEmailAndMobile<T extends FieldBase> = Omit<
  T,
  'description' | 'disabled'
>

type ValidationRuleFn<T extends FieldBase = FieldBase> = (
  schema: MinimumFieldValidationProps<T>,
  isPublicView: boolean,
  publicI18n: TFunction<'translation', undefined>,
) => RegisterOptions

type ValidationRuleFnEmailAndMobile<T extends FieldBase = FieldBase> = (
  schema: MinimumFieldValidationPropsEmailAndMobile<T>,
  isPublicView: boolean,
  publicI18n: TFunction<'translation', undefined>,
) => RegisterOptions

const requiredSingleAnswerValidationFn =
  (
    schema: Pick<FieldBase, 'required'>,
    isPublicView: boolean,
    publicI18n: TFunction<'translation', undefined>,
  ) =>
  (value?: SingleAnswerValue) => {
    if (!schema.required) return true

    if (publicI18n) {
      return (
        !!value?.trim() ||
        (isPublicView
          ? publicI18n('validate.fieldRequired')
          : i18n.t<string>('validate.fieldRequired'))
      )
    }
  }

/**
 * Validation rules for verifiable fields.
 * @param schema verifiable field schema
 * @returns base verifiable fields' validation rules
 */
const createBaseVfnFieldValidationRules: ValidationRuleFnEmailAndMobile<
  VerifiableFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: {
      required: (value?: VerifiableFieldValues) => {
        return requiredSingleAnswerValidationFn(
          schema,
          isPublicView,
          publicI18n,
        )(value?.value)
      },
      hasSignature: (val?: VerifiableFieldValues) => {
        if (!schema.isVerifiable) return true
        // Either signature is filled, or both fields have no input.
        if (!!val?.signature || (!val?.value && !val?.signature)) {
          return true
        }
        if (schema.fieldType === BasicField.Mobile) {
          return isPublicView
            ? publicI18n('utils.fieldValidation.verifyMobile')
            : i18n.t<string>('utils.fieldValidation.verifyMobile')
        }

        if (schema.fieldType === BasicField.Email) {
          return isPublicView
            ? publicI18n('utils.fieldValidation.verifyEmail')
            : i18n.t<string>('utils.fieldValidation.verifyEmail')
        }
      },
    },
  }
}

export const createBaseValidationRules = (
  schema: Pick<FieldBase, 'required'>,
  isPublicView: boolean,
  publicI18n: TFunction<'translation', undefined>,
): RegisterOptions => {
  return {
    validate: requiredSingleAnswerValidationFn(
      schema,
      isPublicView,
      publicI18n,
    ),
  }
}

export const createDropdownValidationRules: ValidationRuleFn<
  DropdownFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  // TODO(#3360): Handle MyInfo dropdown validation
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validOptions: (value: string) => {
        if (!value) return

        return (
          schema.fieldOptions.includes(value) ||
          (isPublicView
            ? publicI18n('validate.invalidDropDown')
            : i18n.t<string>('validate.invalidDropDown'))
        )
      },
    },
  }
}

export const createRatingValidationRules: ValidationRuleFn<RatingFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return createBaseValidationRules(schema, isPublicView, publicI18n)
}

export const createAttachmentValidationRules: ValidationRuleFn<
  AttachmentFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: (value?: File) => {
      if (!schema.required) return true
      return (
        !!value ||
        (isPublicView
          ? publicI18n('validate.fieldRequired')
          : i18n.t<string>('validate.fieldRequired'))
      )
    },
  }
}

export const createHomeNoValidationRules: ValidationRuleFn<HomenoFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validHomeNo: (val?: string) => {
        if (!val) return true

        return (
          isHomePhoneNumber(val) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.validLandline')
            : i18n.t<string>('utils.fieldValidation.validLandline'))
        )
      },
    },
  }
}

export const createMobileValidationRules: ValidationRuleFnEmailAndMobile<
  MobileFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: {
      baseValidations: (val?: VerifiableFieldValues) => {
        return baseMobileValidationFn(
          schema,
          isPublicView,
          publicI18n,
        )(val?.value)
      },
      ...createBaseVfnFieldValidationRules(schema, isPublicView, publicI18n)
        .validate,
    },
  }
}

export const createNumberValidationRules: ValidationRuleFn<NumberFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  const { selectedValidation, customVal } = schema.ValidationOptions

  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validNumber: (val?: string) => {
        if (!val || !customVal) return true

        const currLen = val.trim().length

        if (isPublicView) {
          switch (selectedValidation) {
            case NumberSelectedValidation.Exact:
              return (
                currLen === customVal ||
                simplur`${i18n.t<string>(
                  'utils.fieldValidation.enter',
                )} ${customVal} ${i18n.t<string>(
                  'utils.fieldValidation.digit',
                )} (${currLen}/${customVal})`
              )
            case NumberSelectedValidation.Min:
              return (
                currLen >= customVal ||
                simplur`${i18n.t<string>(
                  'utils.fieldValidation.enterAtLeast',
                )} ${customVal} ${i18n.t<string>(
                  'utils.fieldValidation.digit',
                )} (${currLen}/${customVal})`
              )
            case NumberSelectedValidation.Max:
              return (
                currLen <= customVal ||
                simplur`${i18n.t<string>(
                  'utils.fieldValidation.enterAtMost',
                )} ${customVal} ${i18n.t<string>(
                  'utils.fieldValidation.digit',
                )} (${currLen}/${customVal})`
              )
          }
        }

        switch (selectedValidation) {
          case NumberSelectedValidation.Exact:
            return (
              currLen === customVal ||
              simplur`${publicI18n(
                'utils.fieldValidation.enter',
              )} ${customVal} ${publicI18n(
                'utils.fieldValidation.digit',
              )} (${currLen}/${customVal})`
            )
          case NumberSelectedValidation.Min:
            return (
              currLen >= customVal ||
              simplur`${publicI18n(
                'utils.fieldValidation.enterAtLeast',
              )} ${customVal} ${publicI18n(
                'utils.fieldValidation.digit',
              )} (${currLen}/${customVal})`
            )
          case NumberSelectedValidation.Max:
            return (
              currLen <= customVal ||
              simplur`${publicI18n(
                'utils.fieldValidation.enterAtMost',
              )} ${customVal} ${publicI18n(
                'utils.fieldValidation.digit',
              )} (${currLen}/${customVal})`
            )
        }
      },
    },
  }
}

export const createDecimalValidationRules: ValidationRuleFn<
  DecimalFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validDecimal: (val: string) => {
        const {
          ValidationOptions: { customMax, customMin },
          validateByValue,
        } = schema
        if (!val) return true

        const numVal = Number(val)
        if (isNaN(numVal)) {
          return isPublicView
            ? publicI18n('utils.fieldValidation.validDecimal')
            : i18n.t<string>('utils.fieldValidation.validDecimal')
        }

        // Validate leading zeros
        if (/^0[0-9]/.test(val)) {
          return isPublicView
            ? publicI18n('utils.fieldValidation.validDecimalNoZero')
            : i18n.t<string>('utils.fieldValidation.validDecimalNoZero')
        }

        if (!validateByValue) return true

        if (
          customMin !== null &&
          customMax !== null &&
          (numVal < customMin || numVal > customMax)
        ) {
          if (isPublicView)
            return `${publicI18n(
              'utils.fieldValidation.decimalBetween',
            )} ${formatNumberToLocaleString(customMin)} ${publicI18n(
              'utils.fieldValidation.and',
            )} ${formatNumberToLocaleString(customMax)} ${publicI18n(
              'utils.fieldValidation.inclusive',
            )}`

          return `${i18n.t<string>(
            'utils.fieldValidation.decimalBetween',
          )} ${formatNumberToLocaleString(customMin)} ${i18n.t<string>(
            'utils.fieldValidation.and',
          )} ${formatNumberToLocaleString(customMax)} ${i18n.t<string>(
            'utils.fieldValidation.inclusive',
          )}`
        }
        if (customMin !== null && numVal < customMin) {
          if (isPublicView)
            return `${publicI18n(
              'utils.fieldValidation.decimalGreaterOrEqual',
            )} ${formatNumberToLocaleString(customMin)}`

          return `${i18n.t<string>(
            'utils.fieldValidation.decimalGreaterOrEqual',
          )} ${formatNumberToLocaleString(customMin)}`
        }
        if (customMax !== null && numVal > customMax) {
          if (isPublicView)
            return `${publicI18n(
              'utils.fieldValidation.decimalLessOrEqual',
            )} ${formatNumberToLocaleString(customMax)}`

          return `${i18n.t<string>(
            'utils.fieldValidation.decimalLessOrEqual',
          )} ${formatNumberToLocaleString(customMax)}`
        }

        return true
      },
    },
  }
}

export const createTextValidationRules: ValidationRuleFn<
  ShortTextFieldBase | LongTextFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  const { selectedValidation, customVal } = schema.ValidationOptions
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validText: (val?: string) => {
        if (!val || !customVal) return true

        const currLen = val.trim().length

        if (isPublicView) {
          switch (selectedValidation) {
            case TextSelectedValidation.Exact:
              return (
                currLen === customVal ||
                simplur`${publicI18n(
                  'utils.fieldValidation.enter',
                )} ${customVal} ${publicI18n(
                  'utils.fieldValidation.character',
                )} (${currLen}/${customVal})`
              )
            case TextSelectedValidation.Minimum:
              return (
                currLen >= customVal ||
                simplur`${publicI18n(
                  'utils.fieldValidation.enterAtLeast',
                )} ${customVal} ${publicI18n(
                  'utils.fieldValidation.character',
                )} (${currLen}/${customVal})`
              )
            case TextSelectedValidation.Maximum:
              return (
                currLen <= customVal ||
                simplur`${publicI18n(
                  'utils.fieldValidation.enterAtMost',
                )} ${customVal} ${publicI18n(
                  'utils.fieldValidation.character',
                )} (${currLen}/${customVal})`
              )
          }
        }

        switch (selectedValidation) {
          case TextSelectedValidation.Exact:
            return (
              currLen === customVal ||
              simplur`${i18n.t<string>(
                'utils.fieldValidation.enter',
              )} ${customVal} ${i18n.t<string>(
                'utils.fieldValidation.character',
              )} (${currLen}/${customVal})`
            )
          case TextSelectedValidation.Minimum:
            return (
              currLen >= customVal ||
              simplur`${i18n.t<string>(
                'utils.fieldValidation.enterAtLeast',
              )} ${customVal} ${i18n.t<string>(
                'utils.fieldValidation.character',
              )} (${currLen}/${customVal})`
            )
          case TextSelectedValidation.Maximum:
            return (
              currLen <= customVal ||
              simplur`${i18n.t<string>(
                'utils.fieldValidation.enterAtMost',
              )} ${customVal} ${i18n.t<string>(
                'utils.fieldValidation.character',
              )} (${currLen}/${customVal})`
            )
        }
      },
    },
  }
}

export const createUenValidationRules: ValidationRuleFn<UenFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validUen: (val?: string) => {
        if (!val) return true

        return (
          isUenValid(val) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.validUEN')
            : i18n.t<string>('utils.fieldValidation.validUEN'))
        )
      },
    },
  }
}

export const createNricValidationRules: ValidationRuleFn<NricFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validNric: (val?: string) => {
        if (!val) return true

        return (
          isNricValid(val) ||
          isMFinSeriesValid(val) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.validNRIC')
            : i18n.t<string>('utils.fieldValidation.validNRIC'))
        )
      },
    },
  }
}

export const createCheckboxValidationRules: ValidationRuleFn<
  CheckboxFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: {
      required: (val?: CheckboxFieldValues['value']) => {
        if (!schema.required) return true
        if (!val) {
          if (isPublicView) return publicI18n('validate.fieldRequired')
          return i18n.t<string>('validate.fieldRequired')
        }
        // Trim strings before checking for emptiness
        return (
          val.map((v) => v.trim()).some(identity) ||
          (isPublicView
            ? publicI18n('validate.fieldRequired')
            : i18n.t<string>('validate.fieldRequired'))
        )
      },
      validOptions: (val?: CheckboxFieldValues['value']) => {
        const {
          ValidationOptions: { customMin, customMax },
          validateByValue,
        } = schema
        if (!val || val.length === 0 || !validateByValue) return true

        if (
          customMin &&
          customMax &&
          customMin === customMax &&
          val.length !== customMin
        ) {
          if (isPublicView)
            return simplur`${publicI18n(
              'utils.fieldValidation.selectExactly',
            )} ${customMin} ${publicI18n('utils.fieldValidation.options')} (${
              val.length
            }/${customMin})`

          return simplur`${i18n.t<string>(
            'utils.fieldValidation.selectExactly',
          )} ${customMin} ${i18n.t<string>('utils.fieldValidation.options')} (${
            val.length
          }/${customMin})`
        }

        if (customMin && val.length < customMin) {
          if (isPublicView)
            return simplur`${publicI18n(
              'utils.fieldValidation.selectAtLeast',
            )} ${customMin} ${publicI18n('utils.fieldValidation.options')} (${
              val.length
            }/${customMin})`

          return simplur`${i18n.t<string>(
            'utils.fieldValidation.selectAtLeast',
          )} ${customMin} ${i18n.t<string>('utils.fieldValidation.options')} (${
            val.length
          }/${customMin})`
        }

        if (customMax && val.length > customMax) {
          if (isPublicView)
            return simplur`${publicI18n(
              'utils.fieldValidation.selectAtMost',
            )} ${customMax} ${publicI18n('utils.fieldValidation.options')} (${
              val.length
            }/${customMax})`

          return simplur`${i18n.t<string>(
            'utils.fieldValidation.selectAtMost',
          )} ${customMax} ${i18n.t<string>('utils.fieldValidation.options')} (${
            val.length
          }/${customMax})`
        }

        return true
      },
    },
  }
}

const parseDate = (val: string) => {
  return parse(val, DATE_PARSE_FORMAT, new Date())
}

export const createDateValidationRules: ValidationRuleFn<DateFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return {
    validate: {
      required: requiredSingleAnswerValidationFn(
        schema,
        isPublicView,
        publicI18n,
      ),
      validDate: (val) => {
        if (!val) return true
        if (val === DATE_PARSE_FORMAT.toLowerCase()) {
          return isPublicView
            ? publicI18n('validate.fieldRequired')
            : i18n.t<string>('validate.fieldRequired')
        }

        return (
          isValid(parseDate(val)) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.enterValidDate')
            : i18n.t<string>('utils.fieldValidation.enterValidDate'))
        )
      },
      noFuture: (val) => {
        if (
          !val ||
          schema.dateValidation.selectedDateValidation !==
            DateSelectedValidation.NoFuture
        ) {
          return true
        }

        return (
          !isDateAfterToday(parseDate(val)) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.todayOrBefore')
            : i18n.t<string>('utils.fieldValidation.todayOrBefore'))
        )
      },
      noPast: (val) => {
        if (
          !val ||
          schema.dateValidation.selectedDateValidation !==
            DateSelectedValidation.NoPast
        ) {
          return true
        }

        return (
          !isDateBeforeToday(parseDate(val)) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.todayOrAfter')
            : i18n.t<string>('utils.fieldValidation.todayOrAfter'))
        )
      },
      range: (val) => {
        if (
          !val ||
          schema.dateValidation.selectedDateValidation !==
            DateSelectedValidation.Custom
        ) {
          return true
        }

        const { customMinDate, customMaxDate } = schema.dateValidation ?? {}

        return (
          !isDateOutOfRange(
            parseDate(val),
            loadDateFromNormalizedDate(customMinDate),
            loadDateFromNormalizedDate(customMaxDate),
          ) ||
          (isPublicView
            ? publicI18n('utils.fieldValidation.notWithinRange')
            : i18n.t<string>('utils.fieldValidation.notWithinRange'))
        )
      },
    },
  }
}

export const createRadioValidationRules: ValidationRuleFn<RadioFieldBase> = (
  schema,
  isPublicView,
  publicI18n,
): RegisterOptions => {
  return createBaseValidationRules(schema, isPublicView, publicI18n)
}

export const createEmailValidationRules: ValidationRuleFnEmailAndMobile<
  EmailFieldBase
> = (schema, isPublicView, publicI18n): RegisterOptions => {
  return {
    validate: {
      baseValidations: (val?: VerifiableFieldValues) => {
        return baseEmailValidationFn(
          schema,
          isPublicView,
          publicI18n,
        )(val?.value)
      },
      ...createBaseVfnFieldValidationRules(schema, isPublicView, publicI18n)
        .validate,
    },
  }
}

/**
 * To be shared between the verifiable and non-verifiable variant.
 * @returns error string if field is invalid, true otherwise.
 */
export const baseEmailValidationFn =
  (
    schema: MinimumFieldValidationProps<EmailFieldBase>,
    isPublicView: boolean,
    publicI18n: TFunction<'translation', undefined>,
  ) =>
  (inputValue?: string) => {
    if (!inputValue) return true

    const trimmedInputValue = inputValue.trim()

    // Valid email check
    if (!validator.isEmail(trimmedInputValue)) {
      return isPublicView
        ? publicI18n('validate.invalidEmail')
        : i18n.t<string>('validate.invalidEmail')
    }

    // Valid domain check
    const allowedDomains = schema.isVerifiable
      ? new Set(schema.allowedEmailDomains)
      : new Set()
    if (allowedDomains.size !== 0) {
      const domainInValue = trimmedInputValue.split('@')[1].toLowerCase()
      if (domainInValue && !allowedDomains.has(`@${domainInValue}`)) {
        return isPublicView
          ? publicI18n('validate.invalidDomain')
          : i18n.t<string>('validate.invalidDomain')
      }
    }

    // Passed all error validation.
    return true
  }

export const baseMobileValidationFn =
  (
    _schema: MinimumFieldValidationProps<MobileFieldBase>,
    isPublicView: boolean,
    publicI18n: TFunction<'translation', undefined>,
  ) =>
  (inputValue?: string) => {
    if (!inputValue) return true

    // Valid mobile check
    return (
      isMobilePhoneNumber(inputValue) ||
      (isPublicView
        ? publicI18n('validate.invalidNumber')
        : i18n.t<string>('validate.invalidNumber'))
    )
  }
