import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton, Wrap } from '@chakra-ui/react'

import { FormResponseMode } from '~shared/types/form'

import i18n from '~/i18n/i18n'

import Badge from '~components/Badge'

import { useAdminFormSettings } from '../queries'

import { CategoryHeader } from './CategoryHeader'

export const GeneralTabHeader = (): JSX.Element => {
  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  const readableFormResponseMode = useMemo(() => {
    switch (settings?.responseMode) {
      case FormResponseMode.Email:
        return 'Email mode'
      case FormResponseMode.Encrypt:
        return 'Storage mode'
    }
    return 'Loading...'
  }, [settings?.responseMode])
  return (
    <Wrap
      shouldWrapChildren
      spacing="0.5rem"
      justify="space-between"
      mb="2.5rem"
    >
      <CategoryHeader mb={0}>
        {i18n.t<string>(
          'features.adminForm.settings.components.generalTabHeader.header',
        )}
      </CategoryHeader>
      {/* <Skeleton isLoaded={!isLoadingSettings}>
        <Badge variant="subtle" colorScheme="primary" color="secondary.500">
          {readableFormResponseMode}
        </Badge>
      </Skeleton> */}
    </Wrap>
  )
}
