import IconButton from '~components/IconButton'
import Tooltip from '~components/Tooltip'

interface ChartIconProps {
  icon: React.ReactElement
  onClick: () => void
  label: string
  isActive: boolean
}

export const ChartIcon = ({
  icon,
  onClick,
  label,
  isActive,
}: ChartIconProps): JSX.Element => {
  return (
    <Tooltip label={label} placement="right" width="100%">
      <IconButton
        variant="reverse"
        aria-label={label}
        icon={icon}
        onClick={onClick}
        isActive={isActive}
      />
    </Tooltip>
  )
}
