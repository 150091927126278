import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react'

import { useIsMobile } from '~hooks/useIsMobile'
import Button from '~components/Button'

import { useMutateCollaborators } from '~features/admin-form/common/mutations'

import { useCollaboratorWizard } from '../CollaboratorWizardContext'

export const TransferOwnershipScreen = (): JSX.Element | null => {
  const { i18n } = useTranslation()

  const isMobile = useIsMobile()
  const { mutateTransferFormOwnership } = useMutateCollaborators()
  const { handleBackToList, emailToTransfer } = useCollaboratorWizard()

  const handleTransferOwnership = useCallback(() => {
    if (!emailToTransfer) return
    return mutateTransferFormOwnership.mutate(emailToTransfer, {
      onSuccess: () => handleBackToList(),
    })
  }, [emailToTransfer, handleBackToList, mutateTransferFormOwnership])

  if (!emailToTransfer) return null

  return (
    <>
      <ModalHeader color="secondary.700">
        {i18n.t(
          'features.adminForm.common.components.collaboratorModal.TransferOwnershipScreen.transferFormOwnership',
        )}
      </ModalHeader>
      <ModalBody whiteSpace="pre-wrap" color="secondary.500">
        <Text>
          {i18n.t(
            'features.adminForm.common.components.collaboratorModal.TransferOwnershipScreen.youAreTransferringThisFormTo',
          )}{' '}
          <Text color="danger.500" as="span" fontWeight={700}>
            {emailToTransfer}
          </Text>
          {i18n.t(
            'features.adminForm.common.components.collaboratorModal.TransferOwnershipScreen.youWillLoseFormOwnershipAndTheRightToDeleteEtc',
          )}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Stack
          flex={1}
          spacing="1rem"
          direction={{ base: 'column', md: 'row-reverse' }}
        >
          <Button
            isFullWidth={isMobile}
            isLoading={mutateTransferFormOwnership.isLoading}
            colorScheme="danger"
            onClick={handleTransferOwnership}
          >
            {i18n.t(
              'features.adminForm.common.components.collaboratorModal.TransferOwnershipScreen.yesTransferForm',
            )}
          </Button>
          <Button
            isFullWidth={isMobile}
            isDisabled={mutateTransferFormOwnership.isLoading}
            variant="clear"
            colorScheme="secondary"
            onClick={handleBackToList}
          >
            {i18n.t(
              'features.adminForm.common.components.collaboratorModal.TransferOwnershipScreen.cancel',
            )}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  )
}
