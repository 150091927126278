export const BxsAreaChart = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#014F99"
        d="M3.55 20.625c-.33 0-.607-.113-.834-.34a1.135 1.135 0 01-.341-.835V5.95c.1 0 .204.03.313.088.108.058.227.127.356.207L6.85 9.1l4.3-5.975c.2-.283.47-.45.813-.5.341-.05.637.033.887.25l4.375 3.475h3.225c.327 0 .609.118.845.355.237.236.355.518.355.845v11.9c0 .33-.118.607-.355.834-.236.228-.518.341-.845.341H3.55zm4.525-3.25l3.25-4.525c.2-.267.47-.42.813-.463.341-.041.637.038.887.238l6.25 4.875V8.725h-2.9l-4.025-3.25L7.4 12.45l-2.675-1.975v4.25l3.35 2.65z"
      ></path>
    </svg>
  )
}
