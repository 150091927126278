import { useCallback, useEffect, useMemo } from 'react'
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiTrash } from 'react-icons/bi'
import {
  Box,
  Flex,
  FormControl,
  Grid,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react'
import { Dictionary, get, pickBy, range } from 'lodash'

import { LOGIC_MAP } from '~shared/modules/logic'
import { BasicField } from '~shared/types/field'
import {
  LogicConditionState,
  LogicIfValue,
  LogicType,
} from '~shared/types/form'

import { useHasChanged } from '~hooks/useHasChanged'
import { useWatchDependency } from '~hooks/useWatchDependency'
import { convertToStringArray } from '~utils/stringFormat'
import { MultiSelect, SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import IconButton from '~components/IconButton'
import NumberInput from '~components/NumberInput'

import { BASICFIELD_TO_DRAWER_META } from '~features/admin-form/create/constants'
import { EditLogicInputs } from '~features/admin-form/create/logic/types'
import { FormFieldWithQuestionNo } from '~features/form/types'
import { getIfLogicType } from '~features/logic/utils'

import { BlockLabelText } from './BlockLabelText'

export interface EditConditionBlockProps {
  index: number
  isLoading: boolean
  handleRemoveCondition?: (index?: number | number[] | undefined) => void
  formMethods: UseFormReturn<EditLogicInputs>
  logicableFields: Dictionary<FormFieldWithQuestionNo> | null
  mapIdToField: Record<string, FormFieldWithQuestionNo> | null
}

export const EditConditionBlock = ({
  index,
  isLoading,
  handleRemoveCondition,
  formMethods,
  logicableFields,
  mapIdToField,
}: EditConditionBlockProps): JSX.Element => {
  const { i18n } = useTranslation()

  const name = useMemo(() => `conditions.${index}` as const, [index])
  const {
    watch,
    formState: { errors },
    resetField,
    register,
    setValue,
    control,
    setError,
  } = formMethods
  const ifFieldIdValue = watch(`${name}.field`)
  const hasFieldIdChanged = useHasChanged(
    ifFieldIdValue,
    /* isIgnoreUndefined= */ true,
  )
  const conditionStateValue = watch(`${name}.state`)
  const ifValueTypeValue = watch(`${name}.ifValueType`)
  const logicTypeValue = watch('logicType')
  const showValueWatch = useWatchDependency(watch, 'show')
  const currentSelectedField = useMemo(() => {
    if (!ifFieldIdValue || !mapIdToField) return
    return mapIdToField[ifFieldIdValue]
  }, [ifFieldIdValue, mapIdToField])

  /**
   * Effect to set value and error if the user conditions on a deleted field.
   */
  useEffect(() => {
    if (!ifFieldIdValue || !mapIdToField) return
    if (!(ifFieldIdValue in mapIdToField)) {
      resetField(`${name}.field`)
      setError(`${name}.field`, {
        type: 'manual',
        message: i18n.t<string>(
          'features.adminForm.create.logic.components.editConditionBlock.thisFieldWasDeletedPleaseSelectAnotherField',
        ),
      })
    }
  }, [ifFieldIdValue, mapIdToField, name, resetField, setError, i18n])

  /**
   * Effect to reset the field if the field to apply a condition on is changed.
   */
  useEffect(() => {
    if (hasFieldIdChanged) {
      resetField(`${name}.value`, { defaultValue: '' })
      resetField(`${name}.state`)
    }
  }, [hasFieldIdChanged, name, resetField])

  useEffect(() => {
    if (!currentSelectedField) {
      resetField(`${name}.ifValueType`)
      return
    }
    setValue(
      `${name}.ifValueType`,
      getIfLogicType({
        fieldType: currentSelectedField.fieldType,
        conditionState: conditionStateValue,
      }),
    )
  }, [conditionStateValue, currentSelectedField, name, resetField, setValue])

  const allowedIfConditionFieldsOptions = useMemo(() => {
    if (!logicableFields) return []

    // Get subset of logicableFields that have not already been set to show on
    // other logic conditions.
    let subsetLogicableFields = logicableFields
    if (logicTypeValue === LogicType.ShowFields) {
      const thenValuesSet = new Set(showValueWatch.value)
      subsetLogicableFields = pickBy(
        subsetLogicableFields,
        (f) => !thenValuesSet.has(f._id),
      )
    }

    return Object.entries(subsetLogicableFields).map(([key, value]) => ({
      label: `${value.questionNumber}. ${value.title}`,
      value: key,
      icon: BASICFIELD_TO_DRAWER_META[value.fieldType].icon,
    }))
  }, [logicableFields, logicTypeValue, showValueWatch])

  const conditionItems = useMemo(() => {
    if (!currentSelectedField) return []
    return (
      LOGIC_MAP.get(currentSelectedField.fieldType)?.map((v) => {
        switch (v) {
          case LogicConditionState.Equal:
            return {
              label: i18n
                .t('shared.types.form.formLogic.LogicConditionState.equal')
                .replace(/^is\s/i, ''),
              value: v,
            }
          case LogicConditionState.Lte:
            return {
              label: i18n
                .t('shared.types.form.formLogic.LogicConditionState.lte')
                .replace(/^is\s/i, ''),
              value: v,
            }
          case LogicConditionState.Gte:
            return {
              label: i18n
                .t('shared.types.form.formLogic.LogicConditionState.gte')
                .replace(/^is\s/i, ''),
              value: v,
            }
          case LogicConditionState.Either:
            return {
              label: i18n
                .t('shared.types.form.formLogic.LogicConditionState.either')
                .replace(/^is\s/i, ''),
              value: v,
            }
          default:
            return {
              label: v.replace(/^is\s/i, ''),
              value: v,
            }
        }
      }) ?? []
    )
  }, [currentSelectedField, i18n])

  const conditionValueItems = useMemo(() => {
    if (!ifFieldIdValue || !mapIdToField) return []
    const mappedField = mapIdToField[ifFieldIdValue]
    if (!mappedField) return []
    switch (mappedField.fieldType) {
      case BasicField.YesNo:
        return ['Yes', 'No']
      case BasicField.Radio:
        if (mappedField.othersRadioButton) {
          // 'Others' does not show up in fieldOptions
          return mappedField.fieldOptions.concat(
            i18n.t<string>(
              'features.adminForm.create.logic.components.editConditionBlock.others',
            ),
          )
        }
        return mappedField.fieldOptions
      case BasicField.Dropdown:
        return mappedField.fieldOptions
      case BasicField.Rating:
        return range(1, mappedField.ratingOptions.steps + 1).map(String)
      default:
        return []
    }
  }, [ifFieldIdValue, mapIdToField, i18n])

  const logicTypeWrapperWidth = useMemo(() => {
    if (!currentSelectedField) return '9rem'
    switch (currentSelectedField.fieldType) {
      case BasicField.Dropdown:
      case BasicField.Radio:
      case BasicField.YesNo:
        return '9rem'
      default:
        return '14rem'
    }
  }, [currentSelectedField])

  const validateValueInputComponent = useCallback(
    (val) => {
      switch (ifValueTypeValue) {
        case LogicIfValue.Number: {
          if (currentSelectedField?.fieldType === BasicField.Decimal)
            // Mimics behavior of actual decimal field in public forms
            return (
              !val ||
              !isNaN(Number(val)) ||
              i18n.t<string>(
                'features.adminForm.create.logic.components.editConditionBlock.pleaseEnterAValidDecimal',
              )
            )
          return true
        }
        default:
          return true
      }
    },
    [currentSelectedField?.fieldType, ifValueTypeValue, i18n],
  )

  const renderValueInputComponent = useCallback(
    (field: ControllerRenderProps<EditLogicInputs, `${typeof name}.value`>) => {
      const selectProps = {
        isDisabled: !conditionStateValue || isLoading,
        placeholder: null,
        isClearable: false,
        items: conditionValueItems,
      }
      const { value, ...rest } = field
      switch (ifValueTypeValue) {
        case LogicIfValue.SingleSelect:
          // check if mapIdToField is not null
          // check if current field exists and not is of type yes_no
          if (
            !mapIdToField ||
            (mapIdToField[ifFieldIdValue] &&
              mapIdToField[ifFieldIdValue].fieldType !== BasicField.YesNo)
          ) {
            return (
              <SingleSelect {...selectProps} value={String(value)} {...rest} />
            )
          }

          return (
            <SingleSelect
              {...selectProps}
              shouldTranslate="yesNoField"
              value={String(value)}
              {...rest}
            />
          )
        case LogicIfValue.MultiSelect:
          return (
            <MultiSelect
              {...selectProps}
              values={convertToStringArray(value)}
              {...rest}
            />
          )
        case LogicIfValue.Number: {
          if (currentSelectedField?.fieldType === BasicField.Number)
            return (
              <NumberInput
                inputMode="numeric"
                isDisabled={!conditionStateValue}
                value={String(value ?? '')}
                {...rest}
                onChange={(val) => {
                  // Only allow numeric inputs, mimics behavior of NumberField
                  rest.onChange(val.replace(/\D/g, ''))
                }}
                min={0}
              />
            )
          return (
            <NumberInput
              isDisabled={!conditionStateValue}
              value={String(value ?? '')}
              {...rest}
            />
          )
        }
        case undefined:
          return (
            <SingleSelect
              {...selectProps}
              value={String(value)}
              {...rest}
              isDisabled
            />
          )
      }
    },
    [
      conditionStateValue,
      conditionValueItems,
      currentSelectedField?.fieldType,
      ifValueTypeValue,
      isLoading,
      mapIdToField,
      ifFieldIdValue,
    ],
  )

  return (
    <Flex flexDir="column">
      <Stack direction="column" spacing="0.75rem">
        <Grid
          columnGap="0.5rem"
          gridTemplateColumns={{
            base: '1fr auto',
            md: handleRemoveCondition ? 'auto 1fr auto' : 'auto 1fr',
          }}
          gridTemplateAreas={{
            base: "'label delete' 'input input'",
            md: handleRemoveCondition
              ? "'label input delete'"
              : "'label input'",
          }}
        >
          <BlockLabelText id={`${name}.field-label`} htmlFor={`${name}.field`}>
            {i18n.t<string>(
              'features.adminForm.create.logic.components.editConditionBlock.if',
            )}
          </BlockLabelText>
          {handleRemoveCondition ? (
            <IconButton
              gridArea="delete"
              isDisabled={isLoading}
              variant="clear"
              colorScheme="danger"
              icon={<BiTrash />}
              onClick={() => handleRemoveCondition(index)}
              aria-label={i18n.t<string>(
                'features.adminForm.create.logic.components.editConditionBlock.removeLogicBlock',
              )}
            />
          ) : null}
          <FormControl
            gridArea="input"
            id={`${name}.field`}
            isRequired
            isReadOnly={isLoading}
            isInvalid={!!get(errors, `${name}.field`)}
          >
            <Controller
              control={control}
              name={`${name}.field`}
              rules={{
                required: i18n.t<string>(
                  'features.adminForm.create.logic.components.editConditionBlock.pleaseSelectAQuestion',
                ),
                validate: (value) =>
                  !logicableFields ||
                  Object.keys(logicableFields).includes(value) ||
                  i18n.t<string>(
                    'features.adminForm.create.logic.components.editConditionBlock.fieldIsInvalidOrUnableToAcceptLogic',
                  ),
              }}
              render={({ field }) => (
                <SingleSelect
                  isDisabled={isLoading}
                  isClearable={false}
                  placeholder={i18n.t<string>(
                    'features.adminForm.create.logic.components.editConditionBlock.selectAQuestion',
                  )}
                  items={allowedIfConditionFieldsOptions}
                  {...field}
                />
              )}
            />
            <FormErrorMessage>
              {get(errors, `${name}.field.message`)}
            </FormErrorMessage>
          </FormControl>
        </Grid>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 0, md: '0.5rem' }}
        >
          <BlockLabelText id={`${name}.state-label`} htmlFor={`${name}.state`}>
            {i18n.t<string>(
              'features.adminForm.create.logic.components.editConditionBlock.is',
            )}
          </BlockLabelText>
          <Flex flexDir="column" flex={1} as="fieldset" minW={0}>
            <VisuallyHidden as="legend">
              {i18n.t<string>(
                'features.adminForm.create.logic.components.editConditionBlock.logicCriteria',
              )}
            </VisuallyHidden>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              align="flex-start"
              flex={1}
            >
              <FormControl
                id={`${name}.state`}
                isReadOnly={isLoading}
                isRequired
                isInvalid={!!get(errors, `${name}.state`)}
                maxW={{ md: logicTypeWrapperWidth }}
              >
                <Controller
                  control={control}
                  name={`${name}.state`}
                  rules={{
                    required: i18n.t<string>(
                      'features.adminForm.create.logic.components.editConditionBlock.pleaseSelectACondition',
                    ),
                  }}
                  render={({ field }) => (
                    <SingleSelect
                      placeholder={null}
                      isDisabled={!ifFieldIdValue || isLoading}
                      isClearable={false}
                      items={conditionItems}
                      {...field}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                id={`${name}.value`}
                isRequired
                isReadOnly={isLoading}
                isInvalid={!!get(errors, `${name}.value`)}
              >
                <VisuallyHidden
                  as="label"
                  id={`${name}.value-label`}
                  htmlFor={`${name}.value`}
                >
                  Logic condition
                </VisuallyHidden>
                <Controller
                  control={control}
                  name={`${name}.value`}
                  rules={{
                    required: i18n.t<string>(
                      'features.adminForm.create.logic.components.editConditionBlock.pleaseEnterLogicCriteria',
                    ),
                    validate: validateValueInputComponent,
                  }}
                  render={({ field }) => renderValueInputComponent(field)}
                />
              </FormControl>
            </Stack>
            <FormControl
              isInvalid={
                !!(get(errors, `${name}.state`) ?? get(errors, `${name}.value`))
              }
            >
              <FormErrorMessage>
                {get(errors, `${name}.state.message`) ??
                  get(errors, `${name}.value.message`)}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          {handleRemoveCondition ? <Box aria-hidden w="2.75rem" /> : null}
        </Stack>
        {/* Virtual input for ifLogicType field */}
        <input type="hidden" {...register(`${name}.ifValueType`)} aria-hidden />
      </Stack>
    </Flex>
  )
}
