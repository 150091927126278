import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'

import { Languages } from './constants'
import { locales } from './locales'

// Hacky method of setting default language
// this avoid the use of lng option in init()
// otherwise LanguageDetector will reset the language and user need to change it again and again
if (localStorage.getItem('formkh-language') === null) {
  localStorage.setItem('formkh-language', Languages.Khmer)
}

i18n
  .use(ICU)
  .use(new LanguageDetector(null, { lookupLocalStorage: 'formkh-language' }))
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: locales,
    fallbackLng: Languages.Khmer,
    supportedLngs: [Languages.Khmer, Languages.English],
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18n
