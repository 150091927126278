import cuid from 'cuid'

import { BasicField, ColumnDto, ShortTextColumnBase } from '~shared/types'

import { Languages } from '~/i18n/constants'

const TEMP_COLUMN_ID_PREFIX = 'tempColumnId_'

export const createTemporaryColumnId = (): string => {
  return TEMP_COLUMN_ID_PREFIX + cuid()
}

export const isTemporaryColumnId = (columnId: string): boolean => {
  return columnId.startsWith(TEMP_COLUMN_ID_PREFIX)
}

const DEFAULT_TITLE_EN = 'Text Field'
const DEFAULT_TITLE_KH = 'កន្លែងបំពេញអក្សរ'

export const createShortTextColumn = (
  lang?: string,
): ColumnDto<ShortTextColumnBase> => {
  return {
    columnType: BasicField.ShortText,
    required: true,
    title: lang === Languages.English ? DEFAULT_TITLE_EN : DEFAULT_TITLE_KH,
    _id: createTemporaryColumnId(),
    ValidationOptions: {
      customVal: null,
      selectedValidation: null,
    },
  }
}
