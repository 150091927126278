import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { SummaryTab } from './SummaryTab'

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels,
)

ChartJS.defaults.font.family = 'Kantumruy Pro Variable'

export const SummaryPage = (): JSX.Element => {
  return <SummaryTab />
}
