const myInfoOccupations = [
  'ACCOUNTANT',
  'ACCOUNTING ASSOCIATE PROFESSIONAL (EG ASSISTANT ACCOUNTANT, AUDIT (ACCOUNTING) EXECUTIVE)',
  'ACROBAT',
  'ACTOR',
  'ACTUARY',
  'ADMINISTRATION MANAGER',
  'ADVERTISING COPYWRITER',
  'ADVERTISING SALESMAN',
  'ADVERTISING/PUBLIC RELATIONS MANAGER',
  'ADVOCATE/SOLICITOR',
  'AERIALIST',
  'AERONAUTICAL ENGINEER',
  'AERONAUTICAL ENGINEERING TECHNICIAN',
  'AFTER SALES ADVISER/CLIENT ACCOUNT SERVICE EXECUTIVE',
  'AGED CARE SERVICES MANAGER',
  'AGRICULTURAL AND FISHERY LABOURER AND RELATED WORKER NEC (EG FARM LABOURER)',
  'AGRICULTURAL WORKER NEC',
  'AGRONOMIST',
  'AIR CARGO OFFICER',
  'AIR TRAFFIC CONTROLLER',
  'AIR TRANSPORT SERVICE SUPERVISOR',
  'AIR-CONDITIONING /REFRIGERATION EQUIPMENT MECHANICS',
  'AIR-CONDITIONING/REFRIGERATION ENGINEER',
  'AIR-CONDITIONING/REFRIGERATION ENGINEERING TECHNICIAN',
  'AIR-CONDITIONING/REFRIGERATION PLANT INSTALLER',
  'AIRCRAFT CLEANER',
  'AIRCRAFT ENGINE FITTER',
  'AIRCRAFT ENGINE MECHANIC',
  'AIRCRAFT LOADER',
  'AIRCRAFT PILOT (EXCEPT COMMERCIAL AIRLINE AND AIR FORCE)',
  'AIRCRAFT RIGGER',
  'AIRPORT MOBILE EQUIPMENT DRIVER',
  'AIRPORT RECEPTIONIST/CLERK',
  'AIRWORTHINESS SURVEYOR',
  'AMBULANCE DRIVER',
  'AMUSEMENT/THEME PARK MANAGER',
  'ANAESTHESIOLOGIST',
  'ANATOMIST',
  'ANIMAL SCIENTIST',
  'ANIMATOR',
  'ANODISER',
  'ANTHROPOLOGIST',
  'APPLICATIONS/SYSTEMS PROGRAMMER',
  'APPRAISER/VALUER',
  'AQUACULTURIST',
  'AQUARIUM FISH FARM WORKER',
  'ARCHITECTURAL DRAUGHTSMAN',
  'ARCHIVIST',
  'ART AND CRAFT INSTRUCTOR (EXTRACURRICULUM)',
  'ARTISTIC DIRECTOR (STAGE, FILM, TELEVISION AND RADIO)',
  'ARTS AND CULTURAL CENTRE MANAGER',
  'ASBESTOS CEMENT PRODUCT MAKING MACHINE OPERATOR',
  'ASSESSOR',
  'ASSISTANT CHEMICAL ENGINEER',
  'ASSISTANT CIVIL AND STRUCTURAL ENGINEER',
  'ASSISTANT ELECTRICAL ENGINEER',
  'ASSISTANT ELECTRONICS ENGINEER',
  'ASSISTANT MANUFACTURING ENGINEER',
  'ASSISTANT MECHANICAL ENGINEER',
  'ASTROLOGER/FORTUNE TELLER AND RELATED WORKER',
  'AUCTIONEER',
  'AUDIO AND VIDEO EQUIPMENT ENGINEER',
  'AUDIO AND VIDEO EQUIPMENT TECHNICIAN',
  'AUDIOLOGIST',
  'AUDIT CLERK',
  'AUDITOR (ACCOUNTING)',
  'AUTHOR',
  'AUTOMATED ASSEMBLY LINE/INDUSTRIAL ROBOT OPERATOR',
  'AUTOMATION DESIGNER',
  'AUTOMATION ENGINEER',
  'AUTOMATION TECHNICIAN',
  'AUTOMOTIVE ENGINEER',
  'AUTOMOTIVE ENGINEERING TECHNICIAN',
  'AUXILIARY POLICE OFFICER',
  'BABYSITTER (HOUSEHOLD)',
  'BAKER (GENERAL)',
  'BANK OPERATIONS CLERK',
  'BANK TELLER',
  'BAR/LOUNGE HOSTESS',
  'BARBER',
  'BARTENDER',
  'BATTERY ASSEMBLER',
  'BEAN CURD MAKING MACHINE OPERATOR',
  'BEAUTICIAN',
  'BELL CAPTAIN',
  'BELLBOY/HOTEL PORTER',
  'BILL COLLECTOR',
  'BILLING CLERK',
  'BIOCHEMIST',
  'BIOLOGICAL TECHNICIAN',
  'BIOLOGIST (GENERAL)',
  'BIOMEDICAL ENGINEER',
  'BISCUIT MAKING MACHINE OPERATOR',
  'BLACKSMITH (GENERAL)',
  'BOILER FIREMAN',
  'BOILER/PIPE INSULATOR',
  'BOILERMAKER',
  'BOOK EDITOR',
  'BOOK EMBOSSING MACHINE OPERATOR',
  'BOOKBINDING MACHINE OPERATOR',
  'BOOKKEEPER',
  'BOOKMAKER/CROUPIER/CASINO DEALER AND RELATED GAMING WORKER',
  'BORING/DRILLING MACHINE SETTER-OPERATOR',
  'BOTANIST',
  'BREAD BAKER',
  'BREWER',
  'BRICK AND TILE MOULDER',
  'BRICKLAYER/BLOCKLAYER',
  'BROADCASTING OPERATIONS TECHNICIAN',
  'BUDGETING AND FINANCIAL ACCOUNTING MANAGER (INCLUDING FINANCIAL CONTROLLER)',
  'BUFFING/POLISHING MACHINE OPERATOR',
  'BUILDING AND CONSTRUCTION PROJECT MANAGER',
  'BUILDING ARCHITECT',
  'BUILDING CARETAKER/WATCHMAN',
  'BUILDING CONSTRUCTION ENGINEER',
  'BUILDING FINISHER AND RELATED TRADES WORKER NEC',
  'BUILDING INSULATOR',
  'BUILDING MAINTENANCE WORKER',
  'BUILDING PAINTER',
  'BUILDING STRUCTURE CLEANER AND RELATED WORKER',
  'BUILDING TECHNICIAN',
  'BULLDOZER OPERATOR',
  'BUS DRIVER',
  'BUSINESS CONSULTANT',
  'BUSINESS DEVELOPMENT EXECUTIVE',
  'BUSINESS DEVELOPMENT MANAGER',
  'BUTTER MAKING MACHINE OPERATOR',
  'BUYER',
  'CABIN ATTENDANT/STEWARD',
  'CABINET AND FURNITURE MAKER',
  'CAGE/COUNT SUPERVISOR',
  'CALL CENTRE MANAGER',
  'CAPTAIN WAITER/WAITER SUPERVISOR',
  'CAR PARK ATTENDANT',
  'CARDIOLOGIST',
  'CARDIOTHORACIC SURGEON',
  'CARPENTER',
  'CARTOGRAPHER',
  'CARTON/PAPER BOX MAKING MACHINE OPERATOR',
  'CARTOONIST',
  'CASHIER (GENERAL)',
  'CASINO OPERATIONS MANAGER',
  'CATERING SERVICES MANAGER',
  'CEMENT FINISHER/SCREEDER',
  'CEMENTER (PETROLEUM AND GAS WELLS)',
  'CENTRIFUGAL CASTING MACHINE OPERATOR',
  'CERAMICS/GLASS TECHNOLOGIST',
  'CHAUFFEUR',
  'CHEF',
  'CHEMICAL ENGINEER (GENERAL)',
  'CHEMICAL ENGINEER (PETROCHEMICALS)',
  'CHEMICAL ENGINEER (PETROLEUM)',
  'CHEMICAL ENGINEERING TECHNICIAN (GENERAL)',
  'CHEMICAL ENGINEERING TECHNICIAN (PETROCHEMICALS)',
  'CHEMICAL ENGINEERING TECHNICIAN (PETROLEUM AND NATURAL GAS)',
  'CHEMICAL FILTERING AND SEPARATING EQUIPMENT OPERATOR',
  'CHEMICAL HEAT TREATING PLANT OPERATOR',
  'CHEMICAL PROCESSING, CHEMICAL AND PHOTOGRAPHIC PRODUCTS PLANT AND MACHINE OPERATOR NEC',
  'CHEMICAL STILL/REACTOR OPERATOR',
  'CHEMIST',
  'CHEMISTRY TECHNICIAN',
  'CHIEF ENGINEER OFFICER (SHIP)',
  'CHIEF INFORMATION OFFICER/CHIEF TECHNOLOGY OFFICER/CHIEF SECURITY OFFICER',
  'CHIEF OPERATING OFFICER/GENERAL MANAGER',
  'CHIEF STEWARD (SHIP)',
  'CHILD /AFTER SCHOOL CARE CENTRE WORKER',
  'CHILD CARE SERVICES MANAGER',
  'CHILD/YOUTH RESIDENTIAL CARE ASSISTANT',
  'CHIROPRACTOR',
  'CHOCOLATE MAKING MACHINE OPERATOR',
  'CHOREOGRAPHER',
  'CIGARETTE MAKING MACHINE OPERATOR',
  'CINEMA MANAGER',
  'CINEMA PROJECTIONIST',
  'CIVIL ENGINEER (GENERAL)',
  'CIVIL ENGINEERING TECHNICIAN',
  'CIVIL ENGINEERING/BUILDING CONSTRUCTION LABOURER',
  'CIVIL/STRUCTURAL ENGINEERING DRAUGHTSMAN',
  'CLADDING/CURTAIN WALL ERECTOR',
  'CLEANER (INDUSTRIAL ESTABLISHMENT)',
  'CLEANER IN OFFICES AND OTHER ESTABLISHMENTS NEC',
  'CLEANING SUPERVISOR',
  'CLEARING AND FORWARDING AGENT',
  'CLERICAL SUPERVISOR',
  'CLIENT INFORMATION CLERK NEC',
  'CLOWN',
  'COASTAL WATERS/DEEP SEA FISHERY WORKER',
  'COBBLER',
  'COFFEE AND TEA TASTER',
  'COFFEE/COCOA BEAN ROASTING AND GRINDING MACHINE OPERATOR',
  'COFFEE/TEA BLENDING MACHINE OPERATOR',
  'COMMERCIAL AIRLINE PILOT',
  'COMMERCIAL ARTIST',
  'COMMODITIES DERIVATIVES BROKER',
  'COMMUNITY HEALTH WORKER',
  'COMPANY DIRECTOR',
  'COMPOSER/ORCHESTRATOR',
  'COMPOSITOR/TYPESETTER',
  'COMPRESSOR OPERATOR',
  'COMPUTER AND RELATED ELECTRONIC EQUIPMENT MECHANIC',
  'COMPUTER ENGINEER',
  'COMPUTER SYSTEMS OPERATOR',
  'COMPUTER TECHNICIAN (INCLUDING IT USER HELPDESK TECHNICIAN)',
  'CONCRETE FORM WORKER',
  'CONCRETE MIXING PLANT OPERATOR (INCLUDING CONCRETE MIXER OPERATOR)',
  'CONCRETE PUMP OPERATOR',
  'CONDUCTOR/DIRECTOR OF ORCHESTRA/BAND/CHOIR',
  'CONFECTIONERY MAKING MACHINE OPERATOR',
  'CONFINEMENT NANNY',
  'CONSERVATOR (ART WORKS)',
  'CONSUMER AUDIO AND VIDEO EQUIPMENT MECHANIC',
  'CONTACT CENTRE INFORMATION CLERK',
  'CONTINUITY AND SCRIPT EDITOR',
  'CONTINUOUS ROD CASTING MACHINE OPERATOR (NON-FERROUS METAL)',
  'COOK',
  'COREMAKER',
  'COUNSELLOR (DRUGS AND ALCOHOL)',
  'COUNSELLOR (FAMILY)',
  'COUNSELLOR (REHABILITATION)',
  'CRANE OPERATOR (PORT)',
  'CRANE/HOIST OPERATOR (GENERAL)',
  'CREATIVE DIRECTOR (ADVERTISING)',
  'CREDIT AND LOANS OFFICER',
  'CREWING EXECUTIVE (SHIP)',
  'CROCODILE FARM WORKER',
  'CRUSHING/GRINDING/CHEMICAL MIXING EQUIPMENT OPERATOR',
  'CURATOR (ART GALLERY AND MUSEUM)',
  'CUSTOMER SERVICE CLERK',
  'CUSTOMER SERVICE MANAGER',
  'CUSTOMS/IMMIGRATION OFFICER',
  'CYLINDER FILLER/TESTER (COMPRESSED AND LIQUEFIED GASES)',
  'DAIRY PRODUCT PASTEURISING MACHINE OPERATOR',
  'DAIRY PRODUCT PROCESSING MACHINE OPERATOR (GENERAL)',
  'DANCE INSTRUCTOR (EXTRACURRICULUM)',
  'DANCER',
  'DATA ENTRY CLERK',
  'DATA PROCESSING CONTROL CLERK',
  'DATABASE ADMINISTRATOR',
  'DATABASE AND NETWORK PROFESSIONAL NEC',
  'DATABASE ARCHITECT',
  'DECKHAND (INCLUDING LIGHTERMAN)',
  'DECORATOR AND RELATED WORKER (EG WINDOW DRESSER AND SET DESIGNER)',
  'DEEP-BORING MACHINE OPERATOR',
  'DEMOLITION WORKER',
  'DENTAL NURSE',
  'DENTAL TECHNICIAN',
  'DENTAL/ORAL HEALTH THERAPIST',
  'DENTIST (GENERAL)',
  'DERMATOLOGIST',
  'DESPATCH WORKER',
  'DIAGNOSTIC RADIOLOGIST',
  'DIE CASTING MACHINE OPERATOR',
  'DIETITIAN',
  'DIRECTOR (STAGE, FILM, TELEVISION AND RADIO)',
  'DISC JOCKEY',
  'DISCOTHEQUE/KARAOKE/NIGHTCLUB MANAGER',
  'DISH WASHER/PLATE COLLECTOR',
  'DISPLAY ARTIST',
  'DOG TRAINER',
  'DOMESTIC HELPER (GENERAL)',
  'DOORMAN',
  'DRAUGHTSMAN (GENERAL)',
  'DREDGE OPERATOR',
  'DRIVING INSTRUCTOR/TESTER',
  'DUBBING ARTISTE',
  'DYEING MACHINE OPERATOR',
  'ECONOMIST',
  'EDITOR (NEWS AND PERIODICALS)',
  'EDITOR (RADIO, TELEVISION AND VIDEO)',
  'EDUCATION METHODS ADVISER',
  'ELECTRIC CABLE JOINTER',
  'ELECTRICAL ENGINEER (GENERAL)',
  'ELECTRICAL ENGINEERING TECHNICIAN (GENERAL)',
  'ELECTRICAL ENGINEERING TECHNICIAN (HIGH VOLTAGE)',
  'ELECTRICAL EQUIPMENT/COMPONENT ASSEMBLER',
  'ELECTRICAL FITTER (GENERAL)',
  'ELECTRICAL HOUSEHOLD APPLIANCE REPAIRER',
  'ELECTRICAL LIFT, ESCALATOR AND RELATED EQUIPMENT FITTER',
  'ELECTRICAL LINE INSTALLER',
  'ELECTRICAL/ELECTRONIC PRODUCTS QUALITY CHECKER AND TESTER',
  'ELECTRICAL/ELECTRONICS DRAUGHTSMAN',
  'ELECTRICIAN',
  'ELECTRO-PLATER',
  'ELECTRONIC EQUIPMENT/COMPONENT ASSEMBLER',
  'ELECTRONIC PRE-PRESS TECHNICIAN',
  'ELECTRONICS ENGINEER (GENERAL)',
  'ELECTRONICS ENGINEERING TECHNICIAN (GENERAL)',
  'ELECTRONICS FITTER (GENERAL)',
  'ELECTROTYPER',
  'EMBALMER',
  'EMBEDDED SYSTEMS ENGINEER',
  'EMERGENCY PHYSICIAN',
  'EMPLOYMENT AGENT/LABOUR CONTRACTOR',
  'ENERGY MANAGER',
  'ENROLLED/ASSISTANT NURSE',
  'ENUMERATOR/MARKET RESEARCH INTERVIEWER',
  'ENVIRONMENT RESEARCH SCIENTIST (EG ECOLOGIST)',
  'ENVIRONMENTAL ENGINEER',
  'ENVIRONMENTAL INSPECTOR (ENVIRONMENTAL PUBLIC HEALTH)',
  'ENVIRONMENTAL OFFICER (ENVIRONMENTAL PROTECTION)',
  'ENVIRONMENTAL OFFICER (PUBLIC HEALTH)',
  'EXCAVATING/TRENCH DIGGING MACHINE OPERATOR',
  'EXECUTIVE SEARCH CONSULTANT',
  'EXHIBITION/CONFERENCE/EVENT PLANNER',
  'EXTRUDER OPERATOR (METAL)',
  'FARMING TECHNICIAN (ANIMAL PRODUCTION)',
  'FASHION MODEL',
  'FASHION/GARMENT DESIGNER',
  'FAST FOOD PREPARER',
  'FEED MIXING MACHINE OPERATOR',
  'FERMENTING-ROOM MAN',
  'FIBREGLASS MAKER',
  'FILING AND COPYING CLERK',
  'FINANCIAL ANALYST (EG EQUITIES ANALYST, CREDIT ANALYST)',
  'FINANCIAL DERIVATIVES DEALER/BROKER',
  'FINANCIAL/INSURANCE SERVICES MANAGER (EG FINANCIAL INSTITUTION BRANCH MANAGER)',
  'FINANCIAL/INVESTMENT ADVISER',
  'FIRE AND SAFETY INSPECTOR',
  'FIRE-ENGINE DRIVER',
  'FIRE-FIGHTING AND RESCUE OFFICER',
  'FISH FEED MAKING MACHINE OPERATOR',
  'FISH/PRAWN FARM WORKER',
  'FLAME CUTTER',
  'FLIGHT ENGINEER',
  'FLIGHT NAVIGATOR',
  'FLIGHT OPERATIONS OFFICER',
  'FLIGHT RADIO OPERATOR',
  'FLOOR/WALL TILER',
  'FLORICULTURIST/ORCHIDOLOGIST',
  'FLOWER/ORCHID FARM WORKER',
  'FLYING INSTRUCTOR (EXCEPT AIR FORCE)',
  'FOOD AND DRINK TECHNOLOGIST',
  'FOOD PROCESSING AND RELATED TRADES WORKER NEC (EG FRUIT PICKLER)',
  'FOOD SCIENCE TECHNICIAN',
  'FOOD SERVICE COUNTER ATTENDANT',
  'FOOD/DRINK STALL ASSISTANT',
  'FOREIGN EXCHANGE DEALER/BROKER',
  'FORGING-PRESS OPERATOR',
  'FORK LIFT TRUCK OPERATOR',
  'FRUIT-PRESS OPERATOR',
  'FUMIGATORS/PEST AND WEED CONTROLLERS',
  'FUND MANAGER',
  'FURNITURE UPHOLSTERER',
  'GALLERY, MUSEUM AND RELATED TECHNICIAN',
  'GALVANISER',
  'GAMING SUPERVISOR',
  'GARBAGE DISPOSAL WORKER',
  'GARDEN LABOURER',
  'GARMENT AND RELATED TRADES WORKER NEC (EG FELLMONGER, TENT SEWER, UMBRELLA MAKER)',
  'GARMENT CUTTER',
  'GARMENT MARKER',
  'GARMENT PATTERN-MAKER',
  'GASTROENTEROLOGIST',
  'GEM SETTER/CUTTER AND POLISHER',
  'GENERAL PRACTITIONER/PHYSICIAN',
  'GENERAL SURGEON',
  'GEOLOGIST',
  'GEOPHYSICIST',
  'GERMINATION WORKER (MALTING)',
  'GLASS AND CERAMIC PAINTER/DECORATOR',
  'GLASS BLOWER',
  'GLASS ENGRAVER/ETCHER',
  'GLASS LENS MOULDER',
  'GLASS/CERAMICS KILN MACHINE OPERATOR',
  'GLAZIER',
  'GODOWN LABOURER',
  'GOLDSMITH/SILVERSMITH',
  'GOVERNMENT LICENSING OFFICIAL',
  'GOVERNMENT SOCIAL BENEFITS OFFICIAL',
  'GOVERNMENT TAX AND EXCISE OFFICIAL',
  'GRAIN/SPICE MILLING MACHINE OPERATOR',
  'GRAPHIC DESIGNER',
  'GRASS CUTTER',
  'HAIR STYLIST/HAIRDRESSER',
  'HAND LAUNDERER/PRESSER (NON-HOUSEHOLD)',
  'HAND PACKER',
  'HAND SURGEON',
  'HAND/PEDAL VEHICLE DRIVER',
  'HAWKER/STALL HOLDER (EXCLUDING PREPARED FOOD OR DRINKS)',
  'HAWKER/STALL HOLDER (PREPARED FOOD OR DRINKS)',
  'HEALTH SERVICES MANAGER',
  'HEALTHCARE ASSISTANT',
  'HELMSMAN/STEERSMAN',
  'HISTORIAN',
  'HOISTING EQUIPMENT RIGGER (CONSTRUCTION)',
  'HOISTING EQUIPMENT RIGGER (GENERAL)',
  'HORSE TRAINER',
  'HORTICULTURAL TECHNICIAN',
  'HORTICULTURIST/ARBORIST',
  'HOSPITAL/CLINIC ATTENDANT',
  'HOT-DIP PLATER',
  'HOTEL CLEANER',
  'HOTEL OPERATIONS/LODGING SERVICES MANAGER',
  'HOTEL RECEPTIONIST',
  'HOUSE STEWARD',
  'HOUSEKEEPER (HOTELS AND OTHER ESTABLISHMENTS)',
  'HOUSEKEEPING MATRON',
  'HUMAN RESOURCE CONSULTANT (EXCLUDING EXECUTIVE SEARCH CONSULTANT)',
  'HYDROGENATION OPERATOR (OILS AND FATS)',
  'HYDROGRAPHIC SURVEYOR',
  'HYDROPONICS FARM WORKER',
  'ICE MAKING MACHINE OPERATOR',
  'ICE-CREAM MAKING MACHINE OPERATOR',
  'INDUSTRIAL INVESTIGATOR',
  'INDUSTRIAL MACHINERY AND TOOLS ENGINEER',
  'INDUSTRIAL RELATIONS MANAGER',
  'INDUSTRIAL RELATIONS OFFICER',
  'INDUSTRIAL SAFETY ENGINEER',
  'INDUSTRIAL/OFFICE MACHINERY MECHANIC',
  'INFORMATION TECHNOLOGY AUDITOR',
  'INFORMATION TECHNOLOGY PROJECT MANAGER',
  'INFORMATION TECHNOLOGY SECURITY SPECIALIST',
  'INFORMATION TECHNOLOGY TESTING/QUALITY ASSURANCE SPECIALIST',
  'INFORMATION TECHNOLOGY TRAINER (EXTRACURRICULUM)',
  'INSTRUMENTALIST',
  'INSTRUMENTATION ENGINEER',
  'INSTRUMENTATION TECHNICIAN',
  'INSURANCE INVESTIGATOR',
  'INSURANCE SALES AGENT/BROKER (INCLUDING INDEPENDENT FINANCIAL PLANNER)',
  'INSURANCE UNDERWRITER',
  'INSURANCE/UNDERWRITING CLERK',
  'INTERIOR DESIGNER',
  'INTERNAL MEDICINE PHYSICIAN',
  'INTERPRETER',
  'IT BUSINESS PROCESS CONSULTANT/BUSINESS ANALYST',
  'IT SERVICE MANAGER',
  'JEWELLERY ENGRAVER',
  'JEWELLERY WORKER (GENERAL)',
  'JIG AND GAUGE MAKER',
  'JOINER',
  'JOURNALIST',
  'JUDGE',
  'KEEPER/TRAINER IN CROCODILE FARM',
  'KEEPER/TRAINER IN ZOOLOGICAL, BIRD AND AQUATIC PARKS',
  'KILN DRIER',
  'KILNMAN (MALTING)',
  'KITCHEN ASSISTANT',
  'KNITTING MACHINE OPERATOR',
  'LABORATORY ATTENDANT',
  'LAND SURVEYING TECHNICIAN',
  'LAND SURVEYOR',
  'LANDSCAPE ARCHITECT',
  'LANDSCAPE DESIGNER',
  'LANDSCAPE OPERATIONS MANAGER',
  'LANDSCAPE OPERATIONS OFFICER',
  'LANGUAGE INSTRUCTOR (EXTRACURRICULUM)',
  'LATHE SETTER-OPERATOR',
  'LAUNDERER (HOUSEHOLD)',
  'LAUNDRY AND DRY CLEANING WORKER (MACHINE, NON-HOUSEHOLD)',
  'LAWYER (EXCLUDING ADVOCATE AND SOLICITOR)',
  'LEAFLET AND NEWSPAPER DISTRIBUTOR/DELIVERER',
  'LEATHER GOODS MAKER/ASSEMBLER',
  'LEDGER AND ACCOUNTS CLERK',
  'LEGAL ASSOCIATE PROFESSIONAL (EG PARALEGAL)',
  'LEGAL CLERK',
  'LEGAL OFFICER',
  'LEGISLATOR',
  'LENS GRINDING MACHINE OPERATOR',
  'LENS POLISHING MACHINE OPERATOR',
  'LIBRARIAN',
  'LIBRARY CLERK',
  'LIBRARY OFFICER',
  'LIFEGUARD',
  'LIFT ENGINEER',
  'LIVESTOCK/DAIRY FARM WORKER',
  'LOAD-SHEET OFFICER (AIRCRAFT)',
  'LOCKSMITH',
  'LORRY ATTENDANT',
  'LORRY DRIVER',
  'MACARONI/NOODLE/VERMICELLI MAKING MACHINE OPERATOR',
  'MACHINE EMBROIDERER',
  'MACHINERY ERECTOR/INSTALLER',
  'MACHINERY FITTER (GENERAL)',
  'MACHINERY MECHANIC (GENERAL)',
  'MACHINING/TOOLING TECHNICIAN',
  'MAGICIAN',
  'MAKE-UP ARTIST (STAGE, FILM AND STUDIO)',
  'MANAGEMENT CONSULTANT',
  'MANAGEMENT EXECUTIVE',
  'MANAGING DIRECTOR/CHIEF EXECUTIVE OFFICER',
  'MANICURIST',
  'MANUFACTURING ENGINEER (GENERAL)',
  'MANUFACTURING ENGINEERING TECHNICIAN (GENERAL)',
  'MANUFACTURING LABOURER AND RELATED WORKER NEC (EG RUBBER SHEETS CLIPPER AND SORTER)',
  'MANUFACTURING PLANT/PRODUCTION MANAGER',
  'MARINE DRAUGHTSMAN',
  'MARINE ENGINE FITTER',
  'MARINE ENGINEER (SHORE-BASED)',
  'MARINE ENGINEER OFFICER',
  'MARINE SUPERINTENDENT (DECK)',
  'MARINE SUPERINTENDENT ENGINEER',
  'MARINE SURVEYOR (SHIP AND NAUTICAL)',
  'MARKET RESEARCH ANALYST',
  'MARKETING AND SALES REPRESENTATIVE (ICT)',
  'MARKETING AND SALES REPRESENTATIVE (INSTITUTIONAL SALES OF FINANCIAL PRODUCTS)',
  'MARKETING AND SALES REPRESENTATIVE (MEDICAL AND PHARMACEUTICAL PRODUCTS)',
  'MARKETING AND SALES REPRESENTATIVE (TECHNICAL)',
  'MARTIAL ARTS INSTRUCTOR',
  'MASSAGE THERAPIST',
  'MASSEUR (NON-MEDICAL) (INCLUDING FOOT REFLEXOLOGIST)',
  'MATERIAL PLANNING CLERK',
  'MATERIALS ENGINEER',
  'MATHEMATICIAN',
  'MATTRESS MAKER',
  'MECHANICAL DRAUGHTSMAN',
  'MECHANICAL ENGINEER (GENERAL)',
  'MECHANICAL ENGINEERING TECHNICIAN (GENERAL)',
  'MECHANICAL PRODUCTS QUALITY CHECKER AND TESTER',
  'MECHATRONICS TECHNICIAN',
  'MEDICAL AND PATHOLOGY LABORATORY TECHNICIAN',
  'MEDICAL DIAGNOSTIC RADIOGRAPHER',
  'MEDICAL ONCOLOGIST',
  'MEDICAL SCIENTIST',
  'MEDICAL SOCIAL WORKER',
  'MEDICAL X-RAY TECHNICIAN',
  'MEDICAL/DENTAL RECEPTIONIST',
  'METAL CLEANER',
  'METAL HEAT TREATING PLANT OPERATOR',
  'METAL MOULDER',
  'METAL PATTERN MAKER',
  'METAL ROLLING MILL WORKER',
  'METAL SHIPWRIGHT',
  'METAL SPRAYER',
  'METALLURGIST',
  'METALWORKING MACHINE SETTER-OPERATOR (GENERAL)',
  'METEOROLOGIST',
  'METER READER/VENDING-MACHINE COLLECTOR',
  'MILLING MACHINE SETTER-OPERATOR',
  'MINISTER OF RELIGION',
  'MOBILE MACHINERY OPERATOR NEC (EG MOBILE FARM PLANT OPERATOR)',
  'MOBILE MACHINERY SUPERVISOR AND GENERAL FOREMAN',
  'MONEY CHANGER',
  'MONEYLENDER',
  'MOTOR VEHICLE BODY BUILDER',
  'MOTOR VEHICLE CLEANER/POLISHER',
  'MOTOR VEHICLE MECHANIC',
  'MOTOR VEHICLE SPRAY PAINTER',
  'MOTORCYCLE DELIVERY MAN',
  'MOULD LOFTER',
  'MOVER',
  'MRT OPERATIONS OFFICER',
  'MRT SERVICE SUPERVISOR',
  'MRT TRAIN OPERATOR',
  'MULTIMEDIA (INCLUDING COMPUTER GAMES) DESIGNER',
  'MULTIMEDIA (INCLUDING COMPUTER GAMES) DEVELOPER',
  'MULTIMEDIA ARTIST',
  'MUSHROOM FARM WORKER',
  'MUSIC INSTRUCTOR (EXTRACURRICULUM)',
  'MUSICAL INSTRUMENT MAKER/REPAIRER/TUNER',
  'NARCOTICS OFFICER',
  'NATURE GUIDE (INCLUDING ZOO, BIRDPARK AND AQUARIUM)',
  'NAVAL ARCHITECT',
  'NETWORK AND COMMUNICATIONS MANAGER',
  'NETWORK/COMPUTER SYSTEMS ADMINISTRATOR',
  'NETWORK/INFRASTRUCTURE ARCHITECT AND ENGINEER',
  'NEUROSURGEON',
  'NEWSVENDOR',
  'NON-ORDAINED RELIGIOUS ASSOCIATE PROFESSIONAL',
  'NUMERICAL CONTROL MACHINE SETTER-OPERATOR',
  'NURSERY WORKER',
  'NUTRITIONIST',
  'OBSTETRICIAN/GYNAECOLOGIST',
  'OCCUPATIONAL HEALTH INSPECTOR',
  'OCCUPATIONAL HEALTH PROFESSIONAL',
  'OCCUPATIONAL THERAPIST',
  'ODD JOB PERSON',
  'OFFICE CASHIER',
  'OFFICE CLEANER',
  'OFFICE CLERK (GENERAL)',
  'OFFICE/LIBRARY ATTENDANT',
  'OIL AND GAS WELL DRILLING RIGGER',
  'OIL PRESSMAN (EDIBLE OILS)',
  'OPERATIONS OFFICER (EXCEPT TRANSPORT OPERATIONS)',
  'OPERATIONS RESEARCH ANALYST',
  'OPHTHALMOLOGIST',
  'OPTICAL AND ELECTRONIC EQUIPMENT OPERATOR NEC',
  'OPTICAL GLASS CUTTER',
  'OPTICAL INSTRUMENT MAKER/REPAIRER',
  'OPTICIAN',
  'OPTOMETRIST',
  'ORE AND METAL FURNACE OPERATOR (INCLUDING FURNACEMAN)',
  'ORTHOPAEDIC APPLIANCE MAKER AND REPAIRER',
  'ORTHOPAEDIC FOOTWEAR MAKER',
  'ORTHOPAEDIC SURGEON',
  'ORTHOPAEDIC TECHNICIAN',
  'OSTEOPATH',
  'OTHER ACCOUNTING CLERKS (EG COST CLERK)',
  'OTHER ADMINISTRATIVE AND RELATED ASSOCIATE PROFESSIONALS NEC',
  'OTHER ADMINISTRATIVE CLERKS (EG PUBLIC RELATIONS CLERK)',
  'OTHER AGRONOMY, HORTICULTURAL AND FARMING RELATED TECHNICIANS',
  'OTHER AIRCRAFT PILOTS AND RELATED ASSOCIATE PROFESSIONALS',
  'OTHER ANIMAL/BIRD KEEPERS AND ANIMAL CARE WORKERS',
  'OTHER AQUATIC LIFE CULTIVATION WORKERS (EG OYSTER FARM WORKER)',
  'OTHER ARTISTIC AND CULTURAL ASSOCIATE PROFESSIONALS NEC',
  'OTHER ASSEMBLERS NEC (EG PLASTIC PRODUCTS ASSEMBLER, RUBBER PRODUCTS ASSEMBLER)',
  'OTHER ASSISTANT ENGINEERS',
  'OTHER ASSOCIATE PROFESSIONALS NEC',
  'OTHER ATTENDANTS (EG SWIMMING POOL ATTENDANT)',
  'OTHER AUTHORS AND RELATED WRITERS',
  'OTHER BAKED AND CEREAL PRODUCTS MACHINE OPERATORS',
  'OTHER BILL COLLECTORS AND RELATED WORKERS',
  'OTHER BIOLOGISTS, BOTANISTS, ZOOLOGISTS AND RELATED PROFESSIONALS',
  'OTHER BLACKSMITHS, HAMMERSMITHS AND FORGING-PRESS WORKERS',
  'OTHER BLEACHING, DYEING AND FABRIC CLEANING MACHINE OPERATORS',
  'OTHER BREWERS AND WINE AND OTHER BEVERAGE MACHINE OPERATORS',
  'OTHER BROADCASTING TECHNICIANS AND AUDIO-VISUAL OPERATORS',
  'OTHER BUILDING AND FIRE INSPECTORS',
  'OTHER BUILDING FRAME AND RELATED TRADES WORKERS NEC (EG STEEPLEJACK)',
  'OTHER BUSINESS SERVICES AGENTS NEC (EG SPORTS AGENT, ARTISTE AGENT)',
  'OTHER BUSINESS SERVICES AND ADMINISTRATION MANAGERS NEC',
  'OTHER CABINET MAKERS AND WOOD RELATED TRADES WORKERS',
  'OTHER CAR AND LIGHT GOODS VEHICLE DRIVERS NEC',
  'OTHER CASHIERS, TICKET AND RELATED CLERKS',
  'OTHER CHEMICAL AND PHYSICAL SCIENCE TECHNICIANS',
  'OTHER CHEMICAL ENGINEERING TECHNICIANS',
  'OTHER CHEMICAL ENGINEERS',
  'OTHER CHEMICAL PROCESSING AND CHEMICAL PRODUCTS PLANT AND MACHINE OPERATORS',
  'OTHER CIVIL ENGINEERING AND RELATED TECHNICIANS',
  'OTHER CIVIL ENGINEERS',
  'OTHER CLEANERS AND HELPERS IN HOTELS AND RELATED ESTABLISHMENTS',
  'OTHER CLEANING WORKERS NEC',
  'OTHER CLERICAL SUPPORT WORKERS NEC',
  'OTHER CLOWNS, MAGICIANS AND RELATED ASSOCIATE PROFESSIONALS',
  'OTHER COMMERCIAL AND MARKETING SALES REPRESENTATIVES',
  'OTHER COMPUTER OPERATIONS CLERKS',
  'OTHER CONCRETE PLACERS, CONCRETE FINISHERS AND RELATED WORKERS',
  'OTHER CRAFT AND RELATED WORKERS NEC',
  'OTHER CRANE, HOIST AND RELATED EQUIPMENT OPERATORS',
  'OTHER DAIRY AND CONFECTIONERY PRODUCTS MACHINE OPERATORS',
  'OTHER DOMESTIC HELPERS AND CLEANERS',
  'OTHER DRAUGHTSMEN (EG CARTOGRAPHICAL DRAUGHTSMAN)',
  'OTHER EARTH MOVING AND RELATED MACHINERY OPERATORS',
  'OTHER EDUCATION MANAGERS (INCLUDING REGISTRARS AND DEANS OF EDUCATION INSTITUTIONS)',
  'OTHER EDUCATION METHODS SPECIALISTS',
  'OTHER ELECTRICAL ENGINEERING TECHNICIANS',
  'OTHER ELECTRICAL ENGINEERS',
  'OTHER ELECTRICAL LINE INSTALLERS AND REPAIRERS',
  'OTHER ELECTRICAL MECHANICS AND FITTERS',
  'OTHER ELECTRONICS ENGINEERING TECHNICIANS',
  'OTHER ELECTRONICS ENGINEERS',
  'OTHER ELECTRONICS FITTERS',
  'OTHER ELEMENTARY WORKERS NEC',
  'OTHER ENGINEERING PROFESSIONALS NEC',
  'OTHER ENGRAVERS AND RELATED DECORATIVE PAINTERS',
  'OTHER ENVIRONMENTAL AND OCCUPATIONAL HEALTH AND HYGIENE PROFESSIONALS',
  'OTHER ENVIRONMENTAL PROTECTION AND RELATED PROFESSIONALS',
  'OTHER EXTRACURRICULUM INSTRUCTORS',
  'OTHER FARMING, FORESTRY AND FISHERY ADVISERS',
  'OTHER FILM, STAGE AND RELATED DIRECTORS AND PRODUCERS',
  'OTHER FINANCE AND INSURANCE CLERKS (EG CREDIT CLERK)',
  'OTHER FINANCE DEALERS AND BROKERS',
  'OTHER FINANCIAL ANALYSTS AND RELATED PROFESSIONALS (EG FINANCIAL PRODUCT STRUCTURER)',
  'OTHER FOOD AND BEVERAGE TASTERS AND GRADERS',
  'OTHER FOOD AND RELATED PRODUCTS MACHINE OPERATORS NEC',
  'OTHER FRUIT, VEGETABLE AND NUT PROCESSING MACHINE OPERATORS',
  'OTHER FURNITURE MAKERS AND RELATED TRADES WORKERS NEC',
  'OTHER GARBAGE AND RECYCLING COLLECTORS',
  'OTHER GARMENT AND RELATED PATTERN-MAKERS AND CUTTERS',
  'OTHER GLASS AND CERAMICS PLANT OPERATORS',
  'OTHER GLASS MAKERS, CUTTERS, GRINDERS AND FINISHERS',
  'OTHER GOVERNMENT ASSOCIATE PROFESSIONALS NEC',
  'OTHER GRAPHIC AND MULTIMEDIA DESIGNERS AND ARTISTS',
  'OTHER GUIDES (EG MUSEUM, HISTORICAL SITES)',
  'OTHER HAIR CARE WORKERS (EG HAIR THERAPIST/CONSULTANT)',
  'OTHER HEALTH ASSOCIATE PROFESSIONALS NEC',
  'OTHER HEALTH PROFESSIONALS NEC',
  'OTHER HEAVY TRUCK AND LORRY DRIVERS',
  'OTHER HORTICULTURAL AND NURSERY FARM WORKERS',
  'OTHER HOUSEKEEPERS AND RELATED WORKERS (EG PERSONAL BUTLER)',
  'OTHER INDUSTRIAL AND PRODUCTION ENGINEERS',
  'OTHER INFORMATION PROFESSIONALS',
  'OTHER INSULATION WORKERS',
  'OTHER INSURANCE REPRESENTATIVES',
  'OTHER JEWELLERY AND PRECIOUS METAL WORKERS',
  'OTHER LIFE SCIENCE TECHNICIANS',
  'OTHER LIFTING TRUCK OPERATORS',
  'OTHER MACHINE-TOOL SETTER-OPERATORS',
  'OTHER MACHINERY MECHANICS AND REPAIRERS (EG AGRICULTURAL MACHINERY MECHANIC, BICYCLE REPAIRER)',
  'OTHER MAIL DISTRIBUTION WORKERS (EG MAIL SORTER)',
  'OTHER MANUFACTURING ENGINEERING TECHNICIANS',
  'OTHER MATERIAL AND FREIGHT HANDLING WORKERS',
  'OTHER MEAT AND FISH PREPARERS',
  'OTHER MEAT AND FISH PROCESSING MACHINE OPERATORS',
  'OTHER MECHANICAL ENGINEERING TECHNICIANS',
  'OTHER MECHANICAL ENGINEERS',
  'OTHER MEDICAL IMAGING, THERAPEUTIC EQUIPMENT TECHNICIANS AND RELATED ASSOCIATE PROFESSIONALS',
  'OTHER METAL DRAWERS AND EXTRUDERS',
  'OTHER METAL FINISHING, PLATING AND COATING MACHINE OPERATORS',
  'OTHER METAL MELTERS, CASTERS AND ROLLING MILL OPERATORS',
  'OTHER METAL POLISHERS, GRINDERS AND TOOL SHARPENERS',
  'OTHER MINERAL PRODUCTS AND PROCESSING MACHINE OPERATORS',
  'OTHER MINING ENGINEERS, METALLURGISTS AND RELATED PROFESSIONALS',
  "OTHER MODELS (EG ARTIST'S MODEL)",
  'OTHER MUSICIANS, COMPOSERS AND SINGERS',
  'OTHER PAINTERS AND RELATED WORKERS',
  'OTHER PAPER AND PAPERBOARD PRODUCTS MACHINE OPERATORS',
  'OTHER PARK AND GARDEN MAINTENANCE WORKERS (INCLUDING LANDSCAPE WORKER)',
  'OTHER PERSONAL CARE WORKER (EG OLD FOLKS HOME)',
  'OTHER PERSONAL SERVICE WORKERS NEC',
  'OTHER PHARMACISTS',
  'OTHER PHARMACOLOGISTS AND RELATED PROFESSIONALS',
  'OTHER PHYSICAL AND ENGINEERING SCIENCE TECHNICIANS NEC',
  'OTHER PHYSICAL SCIENCE PROFESSIONALS',
  'OTHER PHYSICAL THERAPISTS AND RELATED ASSOCIATE PROFESSIONALS',
  'OTHER POTTERS AND RELATED WORKERS',
  'OTHER POULTRY FARM WORKERS (EG POULTRY VACCINATOR)',
  'OTHER PRE-PRESS TRADES WORKERS',
  'OTHER PRECISION INSTRUMENT MAKERS AND REPAIRERS',
  'OTHER PRINTERS',
  'OTHER PRINTING, BINDING AND RELATED MACHINE OPERATORS',
  'OTHER PRODUCTION AND OPERATIONS MANAGERS NEC (EG MINING MANAGER)',
  'OTHER PRODUCTION CLERKS',
  'OTHER PROFESSIONAL, FINANCIAL, COMMUNITY AND SOCIAL SERVICES MANAGERS NEC)',
  'OTHER PROFESSIONALS NEC',
  'OTHER PROTECTIVE SERVICES AND RELATED WORKERS NEC',
  'OTHER PULP AND PAPERMAKING PLANT OPERATORS',
  'OTHER QUALITY CHECKERS AND TESTERS',
  'OTHER RADIO AND TELEVISION PRESENTERS AND RELATED ASSOCIATE PROFESSIONALS',
  'OTHER RAILWAY BRAKERS AND RELATED WORKERS',
  'OTHER RECEPTIONISTS AND INFORMATION CLERKS',
  'OTHER RECREATION CENTRE MANAGERS',
  'OTHER RELATED INSTRUCTORS AND ASSOCIATE PROFESSIONALS (EG ADVENTURE TRAINING INSTRUCTOR AND SCUBA DIVING INSTRUCTOR)',
  'OTHER RELATED PLANNERS (EG TRAFFIC PLANNER)',
  'OTHER RELIGIOUS PROFESSIONALS',
  'OTHER RIGGERS AND CABLE SPLICERS',
  'OTHER RUBBER PRODUCTS MACHINE OPERATORS',
  'OTHER SERVICE WORKERS NEC (EG MICROPHONE OPERATOR)',
  'OTHER SERVICES MANAGERS NEC',
  'OTHER SHEET METAL WORKERS',
  "OTHER SHIPS' DECK CREW AND RELATED WORKERS",
  "OTHER SHIPS' DECK OFFICERS AND PILOTS",
  'OTHER SHOEMAKERS AND RELATED WORKERS',
  'OTHER SOCIAL SCIENCE PROFESSIONALS (EG PHILOSOPHER)',
  'OTHER SOCIAL WORK AND COUNSELLING PROFESSIONALS',
  'OTHER SOCIAL WORK ASSOCIATE PROFESSIONALS',
  'OTHER SPECIAL EDUCATION TEACHERS',
  'OTHER SPECIALIST MEDICAL PRACTITIONERS (MEDICAL) (EG ENDOCRINOLOGIST, GERIATRICIAN, HAEMATOLOGIST, INFECTIOUS DISEASES PHYSICIAN, NEUROLOGIST, NUCLEAR MEDICINE PHYSICIAN, REHABILITATION PHYSICIAN, RENAL PHYSICIAN, RHEUMATOLOGIST)',
  'OTHER SPECIALIST MEDICAL PRACTITIONERS (OTHER SPECIALISATIONS) (EG OCCUPATIONAL PHYSICIAN, RADIATION ONCOLOGIST)',
  'OTHER SPECIALIST MEDICAL PRACTITIONERS (SURGICAL)',
  'OTHER STALL SALES WORKERS',
  'OTHER STATISTICAL CLERKS',
  'OTHER STATISTICAL, MATHEMATICAL AND ACTUARIAL ASSOCIATE PROFESSIONALS',
  'OTHER STEAM TURBINE, BOILER AND ENGINE OPERATORS',
  'OTHER STOCK CLERKS',
  'OTHER STRUCTURAL METAL PREPARERS AND ERECTORS',
  'OTHER SURVEYORS AND CARTOGRAPHERS',
  'OTHER TEACHING ASSOCIATE PROFESSIONALS NEC',
  'OTHER TEACHING PROFESSIONALS NEC',
  'OTHER TELECOMMUNICATIONS EQUIPMENT OPERATORS',
  'OTHER TELLERS AND COUNTER CLERKS',
  'OTHER TEXTILE, FUR AND LEATHER PRODUCTS MACHINE OPERATORS NEC (EG SHOEMAKING AND RELATED MACHINE OPERATORS)',
  'OTHER TOBACCO PRODUCTS MACHINE OPERATORS',
  'OTHER TOILETRY PRODUCTS MACHINE OPERATORS',
  'OTHER TOOLMAKERS AND RELATED WORKERS',
  'OTHER TRANSPORT CLERKS (EG LAND AND AIR TRANSPORT)',
  'OTHER TRANSPORT CONTROLLERS AND RELATED WORKERS NEC',
  'OTHER TRANSPORT OPERATIONS SUPERVISORS',
  'OTHER TRANSPORT SERVICE INSPECTORS AND RELATED WORKERS (EG BUS CONDUCTOR)',
  'OTHER UNIVERSITY, POLYTECHNIC AND HIGHER EDUCATION TEACHERS',
  'OTHER UPHOLSTERERS AND RELATED WORKERS',
  'OTHER VEGETABLE FARM WORKERS',
  'OTHER VISUAL ARTISTS',
  'OTHER WATCHMEN AND RELATED WORKERS (EG LIGHTHOUSE KEEPER)',
  'OTHER WATER TREATMENT AND RELATED MACHINE OPERATORS',
  'OTHER WELL DRILLERS AND BORERS AND OTHER MINING RELATED WORKERS',
  'OTHER WELLNESS RELATED WORKERS NEC',
  'OTHER WOOD PROCESSING PLANT OPERATORS',
  'OTHER WOOD PRODUCTS MACHINE OPERATORS',
  'OTHER WOOD TREATERS',
  'OTHER WORD PROCESSORS AND RELATED OPERATORS',
  'OTORHINOLARYNGOLOGIST/EAR, NOSE, THROAT (ENT) SURGEON',
  'PACKING/BOTTLING/LABELLING MACHINE OPERATOR',
  'PAEDIATRIC SURGEON',
  'PAEDIATRICIAN',
  'PAINTER',
  'PANEL BEATER',
  'PAPER PRODUCT MAKING MACHINE OPERATOR',
  'PAPER PULP PLANT OPERATOR',
  'PAPERMAKING MACHINE OPERATOR',
  'PARAMEDIC',
  'PARK RANGER',
  'PARK/GARDEN/NATURE RESERVE MANAGER',
  'PARKS OFFICER',
  'PASTEURISER ATTENDANT (MALT LIQUOR)',
  'PASTRY AND CONFECTIONERY MAKER',
  'PATHOLOGIST',
  'PAWNBROKER',
  'PAYROLL/WAGES CLERK',
  'PERSONNEL/HUMAN RESOURCE CLERK',
  'PERSONNEL/HUMAN RESOURCE MANAGER',
  'PERSONNEL/HUMAN RESOURCE OFFICER',
  'PET GROOMER',
  'PETROL STATION ATTENDANT',
  'PETROLEUM AND NATURAL GAS EXTRACTION TECHNICIAN',
  'PETROLEUM AND NATURAL GAS REFINING PLANT OPERATOR',
  'PETROLEUM/NATURAL GAS ENGINEER',
  'PHARMACEUTICAL PRODUCTS MACHINE OPERATOR',
  'PHARMACEUTICAL TECHNICIAN',
  'PHARMACIST (DISPENSING)',
  'PHARMACOLOGIST',
  'PHOTO-ENGRAVER',
  'PHOTOGRAPHER',
  'PHOTOGRAPHIC PRODUCTS MACHINE OPERATOR',
  'PHOTOGRAVURE CAMERAMAN',
  'PHOTOGRAVURE ETCHER',
  'PHOTOGRAVURE RETOUCHER',
  'PHYSICAL FITNESS INSTRUCTOR (INCLUDING YOGA INSTRUCTOR AND AEROBICS INSTRUCTOR)',
  'PHYSICAL METALLURGY TECHNICIAN',
  'PHYSICIST/ASTRONOMER',
  'PHYSICS TECHNICIAN',
  'PHYSIOLOGIST',
  'PHYSIOTHERAPIST',
  'PICTURE FRAME MAKER',
  'PILE DRIVER/DRILLING MACHINE OPERATOR',
  'PIPE FITTER',
  'PIPE/DRAIN LAYER',
  'PLANING MACHINE SETTER-OPERATOR',
  'PLANT PATHOLOGIST',
  'PLANT TISSUE CULTURE TECHNICIAN',
  'PLANT TISSUE CULTURIST',
  'PLASTERER',
  'PLASTIC PRODUCTS MACHINE OPERATOR',
  'PLASTIC SURGEON',
  'PLUMBER',
  'PLYWOOD CORE LAYER',
  'PLYWOOD PRESS OPERATOR',
  'PODIATRIST',
  'POLICE INSPECTOR',
  'POLICE OFFICER',
  'POLICY ADMINISTRATION PROFESSIONAL (EG POLICY ANALYST)',
  'POLICY AND PLANNING MANAGER',
  'POLITICAL SCIENTIST',
  'POLYTECHNIC LECTURER',
  'PORT CAPTAIN',
  'PORT MASTER/DOCKMASTER',
  'PORT/SHIPPING OPERATIONS SUPERVISOR',
  'PORTER (EXCEPT HOTEL)',
  'POSTAL SERVICE COUNTER CLERK',
  'POSTAL SERVICE MANAGER',
  'POSTAL/COURIER SERVICE SUPERVISOR',
  'POSTMAN',
  'POTTERY WORKER',
  'POULTRY FARM WORKER (GENERAL)',
  'POULTRY HATCHERY WORKER',
  'POULTRY INSEMINATOR',
  'POWER GENERATING PLANT OPERATOR',
  'POWER GENERATION AND DISTRIBUTION ENGINEER',
  'PRE-PRIMARY EDUCATION TEACHER',
  'PRE-UNIVERSITY (INCLUDING JUNIOR COLLEGE) AND SECONDARY SCHOOL TEACHER',
  'PRECAST CONCRETE PRODUCT MAKING MACHINE OPERATOR',
  'PRECISION GRINDING MACHINE SETTER-OPERATOR',
  'PRECISION OPTICS TECHNICIAN',
  'PRECISION WOOD SAWYER',
  'PREMISES AND FACILITIES MAINTENANCE MANAGER',
  'PREMISES AND FACILITIES MAINTENANCE OFFICER',
  'PRIMARY SCHOOL TEACHER',
  'PRINTING MACHINE OPERATOR',
  'PRISON OFFICER',
  'PRIVATE INVESTIGATOR',
  'PRIVATE SECURITY OFFICER',
  'PRIVATE TUTOR (ACADEMIC)',
  'PROCESS SERVER',
  'PROCUREMENT/PURCHASING MANAGER',
  'PRODUCER (STAGE, FILM, TELEVISION, COMPUTER GAMES, VIDEO AND RADIO)',
  'PRODUCER/DIRECTOR OF COMMERCIALS',
  'PRODUCT DESIGNER',
  'PRODUCTION ENGINEER',
  'PRODUCTION ENGINEERING TECHNICIAN',
  'PRODUCTION MANAGER IN AGRICULTURE AND FISHERIES',
  'PRODUCTION PLANNING CLERK',
  'PROFESSIONAL SPORTSMAN',
  'PROOFREADING CLERK',
  'PSYCHIATRIST',
  'PSYCHOLOGIST',
  'PUBLIC HEALTH PHYSICIAN',
  'PUBLIC RELATIONS CONSULTANT AND RELATED PROFESSIONAL',
  'PUBLIC RELATIONS/CORPORATE COMMUNICATIONS OFFICER',
  'PUMPING STATION OPERATOR',
  'PURCHASING AGENT',
  'PURCHASING CLERK',
  'QUALITY ASSURANCE MANAGER',
  'QUALITY ASSURANCE TECHNICIAN',
  'QUALITY CONTROL/ASSURANCE ENGINEER',
  'QUANTITY SURVEYING TECHNICIAN',
  'QUANTITY SURVEYOR',
  'RADIATION THERAPIST',
  'RADIO AND TELEVISION STUDIO EQUIPMENT OPERATOR',
  'RADIO PRESENTER',
  'RADIO, TELEVISION AND RADAR BROADCASTING/TRANSMITTING EQUIPMENT FITTER',
  'RADIO-TELEPHONE OPERATOR (LAND-BASED)',
  'RAIL STATION SERVICE ASSISTANT',
  'RAILWAY BRAKEMAN/SIGNALMAN/SHUNTER',
  'RAILWAY CARRIAGE CLEANER (INCLUDE MRT, LRT AND MONORAIL)',
  'RAILWAY/ROAD VEHICLE LOADER',
  'RATTAN FURNITURE MAKER',
  'REAL ESTATE AGENT',
  'RECEPTIONIST (GENERAL)',
  'REFINING MACHINE OPERATOR (OILS AND FATS)',
  'REFINING/SMELTING TECHNICIAN',
  'REFRACTORY WORKER',
  'REFRIGERATION SYSTEM OPERATOR',
  'REFRIGERATION/AIR-CONDITIONING EQUIPMENT INSULATOR',
  'REFUSE SORTER',
  'REGISTERED NURSE',
  'REINFORCED CONCRETER (GENERAL)',
  'REINFORCING IRON WORKER/STEEL REINFORCEMENT WORKER',
  'RELIEF TEACHER',
  'RESEARCH AND DEVELOPMENT MANAGER',
  'RESEARCH OFFICER (NON-STATISTICAL)',
  'RESEARCH OFFICER (STATISTICAL)',
  'RESIDENT TECHNICAL OFFICER',
  'RESPIRATORY PHYSICIAN',
  'RESTAURANT MANAGER',
  'RETAIL/SHOP SALES MANAGER',
  'RISK ANALYST (FINANCIAL)',
  'ROAD MAKING MACHINE OPERATOR',
  'ROAD TRANSPORT SUPERVISOR',
  'ROOFER',
  'ROOM STEWARD/CHAMBERMAID',
  'ROPE AND CABLE SPLICER (GENERAL)',
  'RUBBER LAMINATING MACHINE OPERATOR',
  'RUBBER MILLMAN',
  'SAFETY INSPECTOR (VEHICLES, PROCESSES AND PRODUCTS)',
  'SALES AND MARKETING MANAGER',
  'SALES AND RELATED ASSOCIATE PROFESSIONAL NEC',
  'SALES DEMONSTRATOR',
  'SALES SUPERVISOR',
  'SALES WORKER NEC (EG SHIP CHANDLER)',
  'SALESPERSON (DOOR-TO-DOOR)',
  'SANDBLASTER/SHOTBLASTER (METAL)',
  'SAUCE MAKING MACHINE OPERATOR',
  'SAUSAGE MAKING MACHINE OPERATOR',
  'SAWYER',
  'SCAFFOLDER',
  'SCHOOL COUNSELLOR',
  'SCHOOL PRINCIPAL',
  'SCRIPT WRITER',
  'SCULPTOR',
  'SEA TRAFFIC CONTROLLER',
  'SEAMLESS PIPE AND TUBE DRAWER',
  'SECRETARY',
  'SECURITIES AND FINANCE DEALER/BROKER',
  'SECURITIES CLERK',
  'SEMI-CONDUCTOR ENGINEER',
  'SEMI-CONDUCTOR TECHNICIAN',
  'SENIOR GOVERNMENT OFFICIAL',
  "SENIOR OFFICIAL OF EMPLOYERS', WORKERS' AND OTHER ECONOMIC-INTEREST ORGANISATION",
  'SENIOR OFFICIAL OF HUMANITARIAN AND OTHER SPECIAL-INTEREST ORGANISATION',
  'SENIOR OFFICIAL OF POLITICAL PARTY ORGANISATION',
  'SENIOR STATUTORY BOARD OFFICIAL',
  'SEWERAGE/SANITARY ENGINEER',
  'SEWING MACHINE OPERATOR',
  'SHAPING AND HONING MACHINE SETTER-OPERATOR',
  'SHEET METAL WORKER (GENERAL)',
  'SHIP AGENT',
  'SHIP BROKER',
  'SHIP CAPTAIN',
  'SHIP CHARTERER',
  'SHIP PILOT',
  'SHIP PLATER',
  'SHIP RIGGER',
  "SHIP'S FIREMAN",
  "SHIP'S NAVIGATING OFFICER",
  "SHIP'S OILER/GREASER",
  "SHIP'S RADIO OFFICER",
  'SHIP/SHIP TANK CLEANER',
  'SHIPPING CLERK',
  'SHOEMAKER',
  'SHOP SALES ASSISTANT',
  'SIGN PAINTER',
  'SILK SCREEN/BLOCK/TEXTILE PRINTER',
  'SINGER',
  'SLAUGHTERER',
  'SLIMMING CONSULTANT',
  'SOAP-MAKING MACHINE OPERATOR',
  'SOCIAL ESCORT',
  'SOCIAL RESEARCH ANALYST',
  'SOCIAL WELFARE MANAGER',
  'SOCIAL WORKER (DELINQUENCY)',
  'SOCIAL WORKER (GENERAL)',
  'SOCIOLOGIST',
  'SOFTWARE AND APPLICATIONS DEVELOPER AND ANALYST NEC',
  'SOFTWARE AND APPLICATIONS MANAGER',
  'SOFTWARE DEVELOPER',
  'SOIL MECHANIC/PILING ENGINEER',
  'SOIL SCIENTIST',
  'SONOGRAPHER',
  'SOUND RECORDING EQUIPMENT OPERATOR',
  'SPECIALISED DENTIST (EG ORTHODONTIST)',
  'SPEECH AND DRAMA INSTRUCTOR (EXTRACURRICULUM)',
  'SPEECH THERAPIST',
  'SPINNER (THREAD AND YARN)',
  'SPORTS CENTRE MANAGER',
  'SPORTS COACH',
  'SPORTS OFFICIAL',
  'SPRAY PAINTER (EXCEPT SHIPS, MOTOR VEHICLES AND SIGNS)',
  'STATIONARY ENGINE OPERATOR (GENERAL)',
  'STATIONARY PLANT AND MACHINE OPERATOR NEC',
  'STATIONARY PLANT AND MACHINE SUPERVISOR/GENERAL FOREMAN',
  'STATISTICAL CLERK (GENERAL)',
  'STATISTICAL OFFICER',
  'STATISTICIAN',
  'STENOGRAPHER',
  'STEREOTYPER',
  'STEVEDORE',
  'STOCK RECORDS CLERK',
  'STONE CUTTER/CARVER',
  'STONEMASON',
  'STOREKEEPER',
  'STRUCTURAL ENGINEER (GENERAL)',
  'STRUCTURAL ENGINEERING TECHNICIAN',
  'STRUCTURAL STEEL ERECTOR',
  'STRUCTURAL STEEL WORKER (WORKSHOP)/FABRICATOR',
  'STRUCTURAL STEEL/SHIP PAINTER',
  'SUGAR PROCESSING AND REFINING MACHINE OPERATOR',
  'SUPERVISOR/GENERAL FOREMAN (BUILDING AND RELATED TRADES)',
  'SUPERVISOR/GENERAL FOREMAN (ELECTRICAL AND ELECTRONIC TRADES)',
  'SUPERVISOR/GENERAL FOREMAN (FOOD PROCESSING, WOODWORKING, GARMENT, LEATHER AND RELATED TRADES)',
  'SUPERVISOR/GENERAL FOREMAN (METAL, MACHINERY AND RELATED TRADES)',
  'SUPERVISOR/GENERAL FOREMAN (PRECISION, HANDICRAFT, PRINTING AND RELATED TRADES)',
  'SUPERVISOR/GENERAL FOREMAN OF ASSEMBLERS AND QUALITY CHECKERS',
  'SUPPLY AND DISTRIBUTION/LOGISTICS/WAREHOUSING MANAGER',
  'SURVEYOR (GENERAL)',
  'SWEEPER AND RELATED WORKER',
  'SYNTHETIC FIBRE MAKING MACHINE OPERATOR',
  'SYRUP MAKING MACHINE OPERATOR',
  'SYSTEMS DESIGNER/ANALYST',
  'TAILOR/DRESSMAKER',
  'TATTOOIST',
  'TAXI DRIVER',
  'TEACHER OF THE BLIND',
  'TEACHER OF THE DEAF',
  'TEACHER OF THE MENTALLY HANDICAPPED',
  "TEACHERS' AIDE",
  'TEACHING AID SPECIALIST (INCLUDING AUDIO-VISUAL AID SPECIALIST)',
  'TECHNICAL WRITER',
  'TECHNICAL/ENGINEERING SERVICES MANAGER (EG SHIPYARD MANAGER)',
  'TECHNICAL/VOCATIONAL/COMMERCIAL EDUCATION INSTITUTE TEACHER',
  'TELECOMMUNICATIONS ENGINEER',
  'TELECOMMUNICATIONS INSTALLER/SERVICER',
  'TELECOMMUNICATIONS LINEMAN',
  'TELECOMMUNICATIONS SERVICE SUPERVISOR',
  'TELECOMMUNICATIONS TECHNICIAN',
  'TELEMARKETER',
  'TELEPHONE OPERATOR',
  'TELEVISION PRESENTER',
  'TELEVISION/MOTION PICTURE CAMERA OPERATOR',
  'TERRAZZO TILE MAKING MACHINE OPERATOR',
  'TEXTILE BLEACHING MACHINE OPERATOR',
  'TEXTILE WASHING/SHRINKING MACHINE OPERATOR',
  'THEME PARK GUIDE',
  'TICKET INSPECTOR',
  'TOOL AND DIE MAKER',
  'TOOL GRINDER (MACHINE TOOLS)',
  'TOOL/MOULD DESIGNER',
  'TOUR GUIDE',
  'TOWN PLANNER',
  'TOY ASSEMBLER',
  'TRADE BROKER (INCLUDING OIL AND BUNKER TRADER)',
  'TRADITIONAL AND COMPLEMENTARY MEDICINE ASSOCIATE PROFESSIONALS (EG HOMEOPATH AND NATUROPATH)',
  'TRADITIONAL CHINESE MEDICINE ACUPUNCTURIST',
  'TRADITIONAL CHINESE MEDICINE PHYSICIAN',
  'TRAILER-TRUCK DRIVER',
  'TRAINING MANAGER',
  'TRAINING OFFICER',
  'TRAM DRIVER',
  'TRANSLATOR',
  'TRANSPORT OPERATIONS MANAGER',
  'TRAVEL AGENCY/SERVICE CLERK',
  'TREASURY MANAGER',
  'TREE CUTTER',
  'TUNNELLING MACHINE OPERATOR (INCLUDING PIPE JACKING MACHINE OPERATOR)',
  'TYPIST',
  'UNDERTAKER',
  'UNDERWATER DIVER',
  'UNIVERSITY LECTURER',
  'UROLOGIST',
  'VAN DRIVER',
  'VEHICLE UPHOLSTERER',
  'VENEER CUTTER',
  'VERBATIM REPORTER',
  'VETERINARIAN',
  'VETERINARY TECHNICIAN/ASSISTANT',
  'VIDEO CAMERA OPERATOR',
  'WAITER',
  'WAITER AND BARTENDER NEC (EG FOOD CHECKER (CATERING SERVICES))',
  'WALL/CEILING PAPERHANGER',
  'WATCH AND CLOCK ASSEMBLER',
  'WATCH AND CLOCK REPAIRER',
  'WATER TREATMENT MACHINE OPERATOR (WATERWORKS)',
  'WEAVING MACHINE OPERATOR',
  'WEB DEVELOPER',
  'WEBSITE ADMINISTRATOR/WEBMASTER',
  'WELDER',
  'WELL DRILLER (PETROLEUM AND GAS WELLS)',
  'WELL DRILLER/BORER (EXCEPT OIL AND GAS WELLS)',
  'WHOLESALE TRADE MANAGER',
  'WINDER (THREAD AND YARN)',
  'WINDOW CLEANER',
  'WIRE DRAWER',
  'WIRE-COATING MACHINE OPERATOR',
  'WOOD FURNITURE FINISHER',
  'WOOD GRADER',
  'WOOD-TREATING WORKER',
  'WOODWORKING MACHINE SETTER-OPERATOR',
  'WORKING PROPRIETOR (AGRICULTURE AND FISHERIES)',
  'WORKING PROPRIETOR (BUSINESS SERVICES AND ADMINISTRATIVE SERVICES)',
  'WORKING PROPRIETOR (CONSTRUCTION)',
  'WORKING PROPRIETOR (INFORMATION AND COMMUNICATIONS TECHNOLOGY SERVICE)',
  'WORKING PROPRIETOR (LODGING SERVICES)',
  'WORKING PROPRIETOR (MANUFACTURING)',
  'WORKING PROPRIETOR (PROFESSIONAL, FINANCIAL, COMMUNITY AND SOCIAL SERVICES)',
  'WORKING PROPRIETOR (RESTAURANT AND OTHER CATERING SERVICES)',
  'WORKING PROPRIETOR (RETAIL TRADE)',
  'WORKING PROPRIETOR (SPORTS, RECREATION, ARTS AND OTHER SERVICES)',
  'WORKING PROPRIETOR (TRANSPORT, STORAGE AND COURIER)',
  'WORKING PROPRIETOR (WHOLESALE TRADE)',
  'YOUTH WORKER',
  'ZOOLOGIST',
]

export default myInfoOccupations
