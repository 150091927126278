import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Center, Skeleton, Stack, Text } from '@chakra-ui/react'
import axios from 'axios'

import { SubmissionId } from '~shared/types'

import ConfirmationButton from '~components/ConfirmationButton'
import Textarea from '~components/Textarea'

import { useUnlockedResponses } from '../../ResponsesPage/storage/UnlockedResponses/UnlockedResponsesProvider'
import { AugmentedDecryptedResponse } from '../../ResponsesPage/storage/utils/augmentDecryptedResponses'

interface IndividualResponseStatusSectionProps {
  data:
    | {
        responses: AugmentedDecryptedResponse[]
        refNo: SubmissionId
        submissionTime: string
        submissionStatus: string
        submissionStatusReason?: string | undefined
      }
    | undefined
  isLoading: boolean
  isError: boolean
}

export const IndividualResponseStatusSection = ({
  data,
  isLoading,
  isError,
}: IndividualResponseStatusSectionProps): JSX.Element => {
  const { i18n } = useTranslation()
  const { formId, submissionId } = useParams()
  const textArea = useRef<HTMLTextAreaElement>(null)
  const [status, setStatus] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { updateSubmssionStatus } = useUnlockedResponses()

  useEffect(() => {
    setStatus(data?.submissionStatus ?? 'pending')
  }, [data])

  const updatedSatus = (payload: unknown) => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    axios
      .patch(
        `/api/v3/admin/forms/${formId}/submissions/${submissionId}`,
        payload,
      )
      .then((res) => {
        updateSubmssionStatus(submissionId, res.data.submissionStatus)
        setStatus(res.data.submissionStatus)
        setIsSubmitting(false)
      })
      .catch((err) => {
        setIsSubmitting(false)
        console.log(err)
      })
  }

  const statusKey = status ? status : 'loading'

  return (
    <Skeleton isLoaded={!isLoading && !!data}>
      <Stack
        spacing={{ base: '0', md: '0.5rem' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Text as="span" textStyle="subhead-1" mb={'0.625rem'}>
          {i18n.t<string>(
            'features.adminForm.responses.components.individualResponseStatusSection.status',
          )}
        </Text>
        <Skeleton isLoaded={!isLoading && !isError}>
          <div>
            {i18n.t<string>(
              'features.adminForm.responses.components.individualResponseStatusSection.' +
                statusKey,
            )}
          </div>
        </Skeleton>
      </Stack>
      {status === 'pending' ? (
        <div>
          <Textarea ref={textArea} />
          <Center>
            <ConfirmationButton
              mr="1rem"
              mt="1rem"
              disabled={isSubmitting}
              colorScheme="danger"
              text={i18n.t<string>(
                'features.adminForm.responses.components.individualResponseStatusSection.rejected',
              )}
              body={i18n.t<string>('dialog.areYouSureRejectBody')}
              onAccept={() => {
                if (!textArea.current) return
                const statusReason = textArea.current.value
                if (statusReason) {
                  updatedSatus({
                    submissionStatus: 'rejected',
                    submissionStatusReason: statusReason,
                  })
                }
              }}
            />

            <ConfirmationButton
              mt="1rem"
              bg="primary.500"
              color="white"
              disabled={isSubmitting}
              text={i18n.t<string>(
                'features.adminForm.responses.components.individualResponseStatusSection.approved',
              )}
              body={i18n.t<string>('dialog.areYouSureApproveBody')}
              onAccept={() => {
                updatedSatus({
                  submissionStatus: 'approved',
                  submissionStatusReason: 'Ok',
                })
              }}
            />
          </Center>
        </div>
      ) : (
        <></>
      )}
    </Skeleton>
  )
}
