import { Box } from '@chakra-ui/react'

import { SummaryResponseBase } from '~shared/types'

import { EmptyChartSummary } from '../common/EmptyChartSummary'

import { HorizontalBarChart } from './BarChart/HorizontalBarChart'
import { VerticalBarChart } from './BarChart/VerticalBarChart'
import { AreaChart } from './AreaChart'
import { LineChart } from './LineChart'
import { PieChart } from './PieChart'

interface BaseChartProps {
  type: string
  data: SummaryResponseBase
}

export const BaseChart = ({ type, data }: BaseChartProps) => {
  // Handle when user changed all options so all data are 0
  const isAllDataAreZero = data.summary.data.every((number) => number === 0)

  if (isAllDataAreZero) {
    return <EmptyChartSummary data={data} />
  }
  return (
    <>
      <Box paddingTop={'15px'}>
        {type === 'vbar' && <VerticalBarChart data={data} />}
        {type === 'hbar' && <HorizontalBarChart data={data} />}
        {type === 'pie' && <PieChart data={data} />}
        {type === 'line' && <LineChart data={data} />}
        {type === 'area' && <AreaChart data={data} />}
      </Box>
    </>
  )
}
