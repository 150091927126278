import { ChangeEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { GUIDE_WEBHOOKS } from '~constants/links'
import Toggle from '~components/Toggle'

import { useMutateFormSettings } from '../../mutations'
import { useAdminFormSettings } from '../../queries'

export const RetryToggle = (): JSX.Element | null => {
  const { i18n } = useTranslation()

  const { data: settings } = useAdminFormSettings()
  const { mutateWebhookRetries } = useMutateFormSettings()

  const handleToggleRetry: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!settings) return
      return mutateWebhookRetries.mutate(e.target.checked)
    },
    [mutateWebhookRetries, settings],
  )

  if (!settings?.webhook.url) return null

  return (
    <Toggle
      isLoading={mutateWebhookRetries.isLoading}
      isChecked={settings.webhook.isRetryEnabled}
      label={i18n.t(
        'features.adminForm.settings.components.WebhooksSection.enableRetries',
      )}
      description={i18n.t(
        'features.adminForm.settings.components.WebhooksSection.description',
        {
          guideWebhooks: GUIDE_WEBHOOKS,
        },
      )}
      onChange={handleToggleRetry}
    />
  )
}
