import { SVGProps } from 'react'
import { Img } from '@chakra-ui/react'

export const OgpLogoFull = (props: SVGProps<SVGSVGElement>) => {
  return <Img boxSize="44px" float="left" src="dgc.png" alt="DGC Logo"></Img>
}
export const OgpLogoFull2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <Img
      boxSize="44px"
      float="left"
      marginLeft="10px"
      src="https://public-mptc.s3.ap-southeast-1.amazonaws.com/mptc-logo-500.png"
      alt="MPTC Logo"
    ></Img>
  )
}
