import { DropzoneInputProps, DropzoneState } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { chakra, Icon, Text, useStyles, VisuallyHidden } from '@chakra-ui/react'

import { BxsCloudUpload } from '~assets/icons/BxsCloudUpload'
import Link from '~components/Link'

interface AttachmentDropzoneProps {
  inputProps: DropzoneInputProps
  isDragActive: DropzoneState['isDragActive']
  readableMaxSize?: string
  question?: string
}

export const AttachmentDropzone = ({
  inputProps,
  isDragActive,
  readableMaxSize,
  question,
}: AttachmentDropzoneProps): JSX.Element => {
  const styles = useStyles()
  const { i18n } = useTranslation()
  return (
    <>
      <VisuallyHidden>
        {question} Click to upload file, maximum file size of {readableMaxSize}
      </VisuallyHidden>
      <chakra.input {...inputProps} data-testid={inputProps.name} />
      <Icon aria-hidden as={BxsCloudUpload} __css={styles.icon} />

      {isDragActive ? (
        <Text aria-hidden>
          {i18n.t<string>(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.dropFileHere',
          )}
        </Text>
      ) : (
        <Text aria-hidden>
          <Link isDisabled={inputProps.disabled}>
            {i18n.t<string>(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.chooseFile',
            )}
          </Link>{' '}
          {i18n.t<string>(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.dragDrop',
          )}
        </Text>
      )}
    </>
  )
}
