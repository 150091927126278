import { useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react'
import { FocusableElement } from '@chakra-ui/utils'
import i18next from 'i18next'

interface ConfirmationButtonProps extends ChakraButtonProps {
  text: string
  title?: string
  body?: string
  declineText?: string | null
  onDecline?: () => void
  acceptText?: string
  onAccept?: () => void
  disabled: boolean
  isOpen: boolean
  modalRef: React.RefObject<FocusableElement>
  onClose: () => void
}

const mapText = (text: string): JSX.Element[] => {
  const textSplit = text.split('<br />')
  return textSplit.map((e: string) => {
    return (
      <span key={e}>
        {e}
        <br />
      </span>
    )
  })
}

export const ConfirmationButton = ({
  text,
  title = i18next.t('dialog.areYouSureTitle'),
  body = i18next.t('dialog.areYouSureBody'),
  declineText = i18next.t('dialog.no'),
  acceptText = i18next.t('dialog.yes'),
  disabled = false,
  onDecline,
  onAccept,
  modalRef,
  ...props
}: ConfirmationButtonProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const bodyTxt = mapText(body || '')
  const toggleDailog = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Button disabled={disabled} onClick={toggleDailog} {...props}>
        {text}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={modalRef}
        onClose={() => toggleDailog()}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{bodyTxt}</AlertDialogBody>

            <AlertDialogFooter>
              {declineText ? (
                <Button
                  ref={
                    modalRef as React.LegacyRef<HTMLButtonElement> | undefined
                  }
                  mt="1rem"
                  mr="1rem"
                  bg="neutral.400"
                  onClick={() => {
                    if (onDecline) onDecline()
                    toggleDailog()
                  }}
                >
                  {declineText}
                </Button>
              ) : null}
              <Button
                {...props}
                ref={modalRef as React.LegacyRef<HTMLButtonElement> | undefined}
                onClick={() => {
                  if (onAccept) onAccept()
                  toggleDailog()
                }}
              >
                {acceptText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
