import { BasicField, FieldBase } from './base'

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export interface YesNoFieldBase extends FieldBase {
  fieldType: BasicField.YesNo
}
