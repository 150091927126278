export const BxLocation = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_335_13153)">
        <path
          fill="#fff"
          d="M11.136 21.525C9.233 19.143 5 13.368 5 10.125 5 6.19 8.134 3 12 3c3.865 0 7 3.19 7 7.125 0 3.243-4.266 9.018-6.136 11.4a1.09 1.09 0 01-1.728 0zM12 12.5c1.287 0 2.333-1.065 2.333-2.375S13.287 7.75 12 7.75c-1.287 0-2.333 1.065-2.333 2.375S10.713 12.5 12 12.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_335_13153">
          <path fill="#fff" d="M0 0H14V19H0z" transform="translate(5 3)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
