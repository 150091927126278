import { PRIVACY_POLICY_ROUTE, TOU_ROUTE } from './routes'

export const CONTACT_US =
  'https://form.gov.kh/coming-soon-feature/formkh-support'
export const FEATURE_REQUEST =
  'https://form.gov.kh/coming-soon-feature/form-featurerequest'
export const REPORT_VULNERABILITY =
  'https://form.gov.kh/coming-soon-feature/report-vulnerability'
export const OSS_README =
  'https://s3-ap-southeast-1.amazonaws.com/misc.form.gov.kh/OSS-Legal.pdf'

export const SINGPASS_FAQ = 'https://www.singpass.gov.kh/main/html/faq.html'

// FormKH guide links
export const FORM_GUIDE = 'https://guide.form.gov.kh'
export const GUIDE_WEBHOOKS = 'https://guide.form.gov.kh'
export const GUIDE_EMAIL_MODE = 'https://guide.form.gov.kh'
export const GUIDE_STORAGE_MODE =
  'https://guide.form.gov.kh/introduction/what-data-can-formkh-handle'
export const GUIDE_FORM_LOGIC =
  'https://guide.form.gov.kh/user-guide/advance-guide/logic'
export const GUIDE_SPCP_ESRVCID = 'https://guide.form.gov.kh'
export const GUIDE_ENABLE_SPCP = 'https://guide.form.gov.kh'
export const GUIDE_TWILIO = 'https://guide.form.gov.kh'
export const GUIDE_ATTACHMENT_SIZE_LIMIT = 'https://guide.form.gov.kh'
export const GUIDE_E2EE = 'https://guide.form.gov.kh'
export const GUIDE_TRANSFER_OWNERSHIP =
  'https://guide.form.gov.kh/user-guide/basic-guide/add-or-remove-collaborators'
export const GUIDE_SECRET_KEY_LOSS = 'https://guide.form.gov.kh'
export const GUIDE_PREVENT_EMAIL_BOUNCE = 'https://guide.form.gov.kh'
export const GUIDE_EMAIL_RELIABILITY = 'https://guide.form.gov.kh'
export const GUIDE_PREFILL = 'https://guide.form.gov.kh'

export const ACCEPTED_FILETYPES_SPREADSHEET = 'https://guide.form.gov.kh'

export const USER_GUIDE = 'https://guide.form.gov.kh'

export const APP_FOOTER_LINKS = [
  { label: 'Privacy', href: PRIVACY_POLICY_ROUTE },
  { label: 'Terms of use', href: TOU_ROUTE },
]

export const LANDING_PAGE_EXAMPLE_FORMS = [
  {
    href: 'https://form.gov.kh/600c490b7c026600138d4ca9',
    label: 'Register for COVID-19 Vaccination',
  },
  {
    href: 'https://form.gov.kh/5eb38e989bd7d80011066a02',
    label: 'Daily Reporting Health Symptoms',
  },
  {
    href: 'https://form.gov.kh/6057667b248bbc0012ceda2f',
    label: 'gov.kh WhatsApp Subscription',
  },
  {
    href: 'https://form.gov.kh/6041e9f8bd47260012395250',
    label: 'Post-ICT Survey',
  },
  {
    href: 'https://form.gov.kh/5f085b4b3583420013af9a55',
    label: 'Health and Travel Declaration Form',
  },
  {
    href: 'https://form.gov.kh/60b81af0f7c4df001210f2b3',
    label: 'MOM ART Self Swab',
  },
]

export const OGP_ALL_PRODUCTS = 'https://www.open.gov.kh/products/overview'
export const OGP_POSTMAN = 'https://postman.gov.kh'
export const OGP_FORMSG_COLLATE = 'https://collate.form.gov.kh'
export const OGP_SGID = 'https://form.gov.kh/coming-soon-feature/sgid-formkh'

export const OGP_FORMSG_REPO = 'https://git.anakotlab.com/formkh/formkh'
