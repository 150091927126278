import { useCallback, useEffect, useMemo } from 'react'
import { Controller, RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from '@chakra-ui/react'
import { extend, isEmpty, pick } from 'lodash'

import { ShortTextFieldBase, TextSelectedValidation } from '~shared/types/field'

import { GUIDE_PREFILL } from '~constants/links'
import { createBaseValidationRules } from '~utils/fieldValidation'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import NumberInput from '~components/NumberInput'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'
import { CopyButton } from '~templates/CopyButton'

import { validateNumberInput } from '~features/admin-form/create/builder-and-design/utils/validateNumberInput'

import {
  isPublicViewSelector,
  publicI18nSelector,
  usePublicLanguageStore,
} from '../../../../../../../public-form/usePublicLanguageStore'
import { CreatePageDrawerContentContainer } from '../../../../../common'
import { FormFieldDrawerActions } from '../common/FormFieldDrawerActions'
import { EditFieldProps } from '../common/types'
import { useEditFieldForm } from '../common/useEditFieldForm'

export type EditShortTextProps = EditFieldProps<ShortTextFieldBase>

const EDIT_SHORTTEXT_FIELD_KEYS = [
  'title',
  'description',
  'required',
  'allowPrefill',
] as const

type EditShortTextInputs = Pick<
  ShortTextFieldBase,
  typeof EDIT_SHORTTEXT_FIELD_KEYS[number]
> & {
  ValidationOptions: {
    selectedValidation: TextSelectedValidation | ''
    customVal: number | ''
  }
}

const transformShortTextFieldToEditForm = (
  field: ShortTextFieldBase,
): EditShortTextInputs => {
  const nextValidationOptions = {
    selectedValidation:
      field.ValidationOptions.selectedValidation || ('' as const),
    customVal:
      (!!field.ValidationOptions.selectedValidation &&
        field.ValidationOptions.customVal) ||
      ('' as const),
  }
  return {
    ...pick(field, EDIT_SHORTTEXT_FIELD_KEYS),
    ValidationOptions: nextValidationOptions,
  }
}

const transformShortTextEditFormToField = (
  inputs: EditShortTextInputs,
  originalField: ShortTextFieldBase,
): ShortTextFieldBase => {
  const nextValidationOptions =
    inputs.ValidationOptions.selectedValidation === ''
      ? {
          selectedValidation: null,
          customVal: null,
        }
      : inputs.ValidationOptions
  return extend({}, originalField, inputs, {
    ValidationOptions: nextValidationOptions,
  })
}

export const EditShortText = ({ field }: EditShortTextProps): JSX.Element => {
  const { t } = useTranslation()

  const { publicI18n, isPublicView } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        // publicI18n: state.publicI18n,
        // isPublicView: state.isPublicView,
        publicI18n: publicI18nSelector(state),
        isPublicView: isPublicViewSelector(state),
      }),
      [],
    ),
  )

  const {
    register,
    formState: { errors },
    getValues,
    buttonText,
    handleUpdateField,
    watch,
    control,
    clearErrors,
    isLoading,
    handleCancel,
    setValue,
  } = useEditFieldForm<EditShortTextInputs, ShortTextFieldBase>({
    field,
    transform: {
      input: transformShortTextFieldToEditForm,
      output: transformShortTextEditFormToField,
    },
  })

  const requiredValidationRule = useMemo(
    () =>
      createBaseValidationRules({ required: true }, isPublicView, publicI18n),
    [isPublicView, publicI18n],
  )

  const watchedSelectedValidation = watch(
    'ValidationOptions.selectedValidation',
  )

  const watchAllowPrefill = watch('allowPrefill')

  const customValValidationOptions: RegisterOptions<
    EditShortTextInputs,
    'ValidationOptions.customVal'
  > = useMemo(
    () => ({
      // customVal is required if there is selected validation.
      validate: {
        hasValidation: (val) => {
          if (isPublicView)
            return (
              !!val ||
              !getValues('ValidationOptions.selectedValidation') ||
              publicI18n('validate.pleaseEnterNumberOfCharacters')
            )

          return (
            !!val ||
            !getValues('ValidationOptions.selectedValidation') ||
            t('validate.pleaseEnterNumberOfCharacters')
          )
        },
        validNumber: (val) => {
          // Check whether input is a valid number, avoid e
          if (isPublicView)
            return (
              !isNaN(Number(val)) ||
              publicI18n(
                'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.pleaseEnterAValidNumber',
              )
            )

          return (
            !isNaN(Number(val)) ||
            t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.pleaseEnterAValidNumber',
            )
          )
        },
      },
      min: {
        value: 1,
        message: isPublicView
          ? publicI18n(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.cannotBeLessThan1',
            )
          : t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.cannotBeLessThan1',
            ),
      },
      max: {
        value: 10000,
        message: isPublicView
          ? publicI18n(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.cannotBeMoreThan10000',
            )
          : t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.cannotBeMoreThan10000',
            ),
      },
    }),
    [getValues, t, isPublicView, publicI18n],
  )

  // Effect to clear validation option errors when selection limit is toggled off.
  useEffect(() => {
    if (!watchedSelectedValidation) {
      clearErrors('ValidationOptions')
      setValue('ValidationOptions.customVal', '')
    }
  }, [clearErrors, setValue, watchedSelectedValidation])

  return (
    <CreatePageDrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.question',
          )}
        </FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.description}
      >
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.description',
          )}
        </FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('required')}
          label={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.required',
          )}
        />
      </FormControl>
      <FormControl
        isReadOnly={isLoading}
        isInvalid={!isEmpty(errors.ValidationOptions)}
      >
        <FormLabel isRequired>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.numberOfCharactersAllowed',
          )}
        </FormLabel>
        <SimpleGrid
          mt="0.5rem"
          columns={{ base: 2, md: 1, lg: 2 }}
          spacing="0.5rem"
        >
          <Controller
            name="ValidationOptions.selectedValidation"
            control={control}
            render={({ field }) => (
              <SingleSelect
                items={Object.values(TextSelectedValidation)}
                shouldTranslate="numCharLimitation"
                {...field}
              />
            )}
          />
          <Controller
            name="ValidationOptions.customVal"
            control={control}
            rules={customValValidationOptions}
            render={({ field: { onChange, ...rest } }) => (
              <NumberInput
                flex={1}
                inputMode="numeric"
                showSteppers={false}
                placeholder={t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.numberOfCharacters',
                )}
                isDisabled={!watchedSelectedValidation}
                onChange={validateNumberInput(onChange)}
                {...rest}
              />
            )}
          />
        </SimpleGrid>
        <FormErrorMessage>
          {errors?.ValidationOptions?.customVal?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('allowPrefill')}
          label={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.enablePrefill',
          )}
          description={
            t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.enablePrefillDescription',
            ) + `(${GUIDE_PREFILL})`
          }
        />
        {watchAllowPrefill ? (
          <InputGroup mt="0.5rem">
            <Input
              isReadOnly
              isDisabled={!field._id}
              value={
                field._id ??
                t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.fieldIDWillBeGeneratedAfterThisFieldIsSaved',
                )
              }
            />
            {field._id ? (
              <InputRightElement>
                <CopyButton
                  stringToCopy={field._id}
                  aria-label={t(
                    'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditShortText.copyFieldIDValue',
                  )}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>
        ) : null}
      </FormControl>
      <FormFieldDrawerActions
        isLoading={isLoading}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </CreatePageDrawerContentContainer>
  )
}
