import { Center, Image } from '@chakra-ui/react'

import ComingSoonImage from '~/assets/svgs/Under_Constrution.svg'

export const ComingSoonPage = (): JSX.Element => {
  return (
    <Center>
      <Image src={ComingSoonImage} alt="Coming Soon" height="75%" />
    </Center>
  )
}
